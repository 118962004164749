<h2 mat-dialog-title>Create new client</h2>
<form [formGroup]="details" (ngSubmit)="createClient()">
  <div mat-dialog-content>
    <mat-form-field appearance="outline" class="form-field">
      <mat-label>Company name</mat-label>
      <input type="text" matInput formControlName="companyName" required />
    </mat-form-field>
    <mat-form-field appearance="outline" class="form-field">
      <mat-label>Username</mat-label>
      <input type="text" matInput formControlName="username" required />
    </mat-form-field>
    <mat-form-field appearance="outline" class="form-field">
      <mat-label>Email</mat-label>
      <input type="email" matInput formControlName="email" required />
    </mat-form-field>
    <mat-form-field appearance="outline" class="form-field">
      <mat-label>Password</mat-label>
      <input type="password" matInput formControlName="password" required />
    </mat-form-field>
    <div>
      <input type="file" #logo hidden (change)="onLogoChange($event)" />
      <div class="file-upload">
        <button
          type="button"
          color="primary"
          mat-flat-button
          (click)="logo.click()"
        >
          Add logo
        </button>
        <p>
          <strong>{{ logo.files[0]?.name }}</strong>
        </p>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="actions">
    <button
      color="warn"
      type="button"
      mat-flat-button
      mat-dialog-close
      [disabled]="creating"
      class="action-negative"
    >
      cancel
    </button>
    <button
      color="primary"
      cdkFocusInitial
      mat-flat-button
      *ngIf="!creating"
      class="action-positive"
      (click)="createClient()"
      [disabled]="!details.valid"
    >
      create
    </button>
    <div *ngIf="creating" class="loader">
      <mat-spinner diameter="20"></mat-spinner>
    </div>
  </div>
</form>
