<div class="content">
  <span class="icon">
    <mat-icon aria-hidden="false" aria-label="Caution">warning</mat-icon>
  </span>
  <p class="warning-p" style="text-align: center;">
    &nbsp; Are you sure you want to
  </p>
  <h5 class="warning-h">{{ data.action }}?</h5>
  <div class="action-buttons">
    <button (click)="onOkClick()" class="action-buttons__ok-btn" mat-button>Ok</button>
    <button (click)="onCancelClick()" class="action-buttons__cancel-btn" mat-button>Cancel</button>
  </div>
</div>
