<div class="con-flu">
  <div class="bg-whole">
    <div class="image-bg">
      <img style="width: 100%; height: -webkit-fill-available" src="/assets/img/axa.jpg" />
    </div>

    <div class="rec2">
      <div class="card-bg">
        <p class="log-title">Kindly change your password</p>

        <div class="flex-cen">
          <form (ngSubmit)="passwordChange()" class="" [formGroup]="changePasswordForm" #f="ngForm">

            <div class="Reset_container">
              <!--old password input filed-->
              <mat-form-field appearance="outline">
                <mat-label>Old Password</mat-label>
                <input matInput [type]="hideOldPassword ? 'password' : 'text'" type="password"
                  formControlName="oldPassword" name="oldPassword" [errorStateMatcher]="matcher" required />

                <button type="button" mat-icon-button matSuffix (click)="hideOldPassword = !hideOldPassword"
                  [attr.aria-label]="'Hide Old password'" [attr.aria-pressed]="hideOldPassword">
                  <mat-icon>{{
                    hideOldPassword ? "visibility_off" : "visibility"
                    }}</mat-icon>
                </button>
                <mat-error *ngIf="
                    changePasswordForm.controls['oldPassword'].hasError(
                      'required'
                    )
                  ">Old password is required</mat-error>
              </mat-form-field>
            </div>

            <div class="Reset_container">
              <!--password input filed-->
              <mat-form-field appearance="outline" style="width: 100%; margin-bottom: 1.5rem">
                <mat-label>New Password</mat-label>
                <mat-pass-toggle-visibility #toggle matSuffix></mat-pass-toggle-visibility>

                <input matInput #passwordWithValidation [type]="toggle.type" required [errorStateMatcher]="matcher" formControlName="password"
                  placeholder="New Password" maxlength="15" />

                <!--password hint-->
                <mat-hint align="end" aria-live="polite">
                  {{ passwordWithValidation.value.length }} / 15
                </mat-hint>

                <!--password Mat-error-->
                <mat-error *ngIf="
                    changePasswordForm.controls['password'].hasError('required')
                  ">New Password is required</mat-error>
                <mat-error *ngIf="
                    changePasswordForm.controls['password'].hasError(
                      'invalidPassword'
                    )
                  ">
                  New Password is not valid
                </mat-error>
              </mat-form-field>

              <!--password-strength's main component-->
              <mat-password-strength #passwordComponentWithValidation [password]="passwordWithValidation.value"
                class="green" [min]="8" [max]="15" [enableLowerCaseLetterRule]="false">
              </mat-password-strength>

              <!--Password's strength info-->
              <mat-password-strength-info [passwordComponent]="passwordComponentWithValidation">
              </mat-password-strength-info>
            </div>

            <div class="Reset_container">
              <!--old password input filed-->
              <mat-form-field appearance="outline">
                <mat-label>Confirm Password</mat-label>
                <input matInput [type]="hideConfirmPassword ? 'password' : 'text'" type="password"
                  formControlName="confirmPassword" name="confirmPassword" [errorStateMatcher]="matcher" required />

                <button type="button" mat-icon-button matSuffix (click)="hideConfirmPassword = !hideConfirmPassword"
                  [attr.aria-label]="'Hide confirm password'" [attr.aria-pressed]="hideConfirmPassword">
                  <mat-icon>{{
                    hideConfirmPassword ? "visibility_off" : "visibility"
                    }}</mat-icon>
                </button>
                <mat-error *ngIf="changePasswordForm.get('confirmPassword').invalid">{{getErrorMessage()}}</mat-error>
                <!-- <mat-error *ngIf="changePasswordForm.hasError('confirmPasswordValidator')"> Password Mismatch</mat-error> -->
              </mat-form-field>
            </div>

            <div class="Reset_container">
              <button type="submit" [disabled]="changePasswordForm.invalid" [ngClass]="{'btn_resetInvalid': changePasswordForm.invalid}" class="btn__Reset">
                Change Password &rarr; <mat-spinner *ngIf="isLoading" class="custom-spinner" diameter="20"></mat-spinner>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
