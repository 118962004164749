<!-- verify-otp.component.html -->
<div class="con-flu">
  <div class="bg-whole">
    <div class="image-bg">
      <img style="width: 100%; height: 100vh" src="/assets/img/axa.jpg" />
    </div>

    <div class="rec2">
      <div class="card-bg">
        <p class="log-title">Enter OTP</p>

        <div class="flex-cen">
          <form
            class="form-horizontal"
            [formGroup]="otpForm"
            (ngSubmit)="onSubmit()"
            (paste)="onPaste($event)"
          >
            <div class="login_container">
              <div class="otp-input-group">
                <input
                  *ngFor="let control of ['digit1', 'digit2', 'digit3', 'digit4', 'digit5', 'digit6']"
                  type="number"
                  min="0"
                  max="9"
                  maxlength="1"
                  [formControlName]="control"
                  class="otp-input"
                  autocomplete="off"
                  (keyup)="onKeyUp($event, control)"
                  #otpInput

                >
              </div>
              <div class="countdown-text">
               Code expires in: {{ countdownDisplay }}
                <!-- <span *ngIf="resendEnabled" class="resend-text">
                  | <a (click)="resendOtp()">Resend OTP</a>
                </span> -->
              </div>
            </div>

            <div class="login_container">
              <button type="submit" class="btn__login" [disabled]="loading">
                Verify &rarr;
                <mat-spinner
                  *ngIf="loading"
                  class="custom-spinner"
                  diameter="20"
                ></mat-spinner>
              </button>
            </div>

            <div class="fgttext login_container">
              <span class="forgottext">
                <a routerLink="/login">Back to Login</a>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
