import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import { ClientManagementService } from '../client-management.service';
import { EncryptionService } from '@app/shared/services/encryptionService/encryption.service';

@Component({
  selector: 'app-add-client-dialog',
  templateUrl: './add-client-dialog.component.html',
  styleUrls: ['./add-client-dialog.component.scss']
})
export class AddClientDialogComponent implements OnInit {
  creating = false;
  details: FormGroup;

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private clientService: ClientManagementService,
    private dialogRef: MatDialogRef<AddClientDialogComponent>,
    private toaster: ToastrService,
    private encryptionService: EncryptionService
  ) {
    this.details = this.fb.group({
      logo: '',
      email: '',
      password: '',
      username: '',
      companyName: ''
    });
  }

  ngOnInit(): void {}

  private openSnackBar(message: string) {
    this.snackBar.open(message, null, { duration: 2000 });
  }

  onLogoChange({ target }: Event) {
    const formData = new FormData();
    const file = (target as HTMLInputElement).files[0];

    formData.append('logo', file);

    this.details.controls.logo.setValue(formData);
  }

  createClient() {
    if (!this.details.valid) return;

    this.creating = true;
    const formValue = this.details.value;
    try {

      const encryptedPassword = this.encryptionService.encryptData(formValue.password);

      const urlSafePassword = encodeURIComponent(encryptedPassword);

      const clientData = {
        ...formValue,
        password: urlSafePassword
      };

      const subscription = this.clientService
        .createClient(clientData)
        .subscribe(
          ({ status, message }: any) => {
            this.openSnackBar(message);
            if (status === 0) {
              this.toaster.success(message);
              this.dialogRef.close();
            } else {
              this.toaster.error(message);
            }
            this.creating = false;
          },
          (err) => {
            this.toaster.error(err);
            this.creating = false;
          }
        );
    } catch (encryptionError) {
      this.creating = false;
      this.toaster.error('Password encryption failed');
      console.error('Encryption error:', encryptionError);
    }
  }
}