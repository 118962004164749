import { Component, OnDestroy, OnInit } from '@angular/core';
import { BufferType } from '@app/shared/models/map.model';
import { features } from 'process';
import { Observable, Subscription } from 'rxjs';
import { MapAddressesService } from 'src/app/shared/services/map/map-addresses.service';
@Component({
  selector: 'app-risk-panel',
  templateUrl: './risk-panel.component.html',

  styleUrls: ['./risk-panel.component.scss'],
})
export class RiskComponent implements OnInit, OnDestroy {
  // Observables

  riskCategories: any[];
  currentAddressObservable: Observable<any> = this.mapAddressService.getCurrentAddress();
  loading$ = this.mapAddressService.getLoading();
  riskCategoriesSubscription: Subscription;
  currentAddressSubscription: Subscription;
  // current address
  currentAddress = null;

  constructor(private mapAddressService: MapAddressesService) {}

  ngOnInit(): void {
    this.riskCategoriesSubscription = this.mapAddressService
      .fetchRiskCategories()
      .subscribe((data) => {
        this.riskCategories = data;
        this.mapAddressService.updateRiskCategories$(data);
        this.riskDetailClicked(this.getKeyValue(this.riskCategories[0])[0][1]);
      });

    this.currentAddressSubscription = this.currentAddressObservable.subscribe(
      (data) => {
        this.currentAddress = data;
        // console.log('current Adddress', this.currentAddress);
      }
    );
  }

  changeRiskCategories(data:any){
    console.log(data)
    this.mapAddressService.updateRiskCategories$(data);
  }

  ngOnDestroy(): void {
    this.riskCategoriesSubscription.unsubscribe();
    this.currentAddressSubscription.unsubscribe();
  }

  getKeyValue(obj): any {
    return Object.entries(obj);
  }

  riskDetailClicked(riskType): void {
    // console.log(riskType);
    if (this.currentAddressObservable) {
      // Get details from current address

      const addressID = this.currentAddress.id;
      const lat = this.currentAddress.latitude;
      const long = this.currentAddress.longitude;
      const risk_type = riskType.toUpperCase();

      // An array holding static risk
      const staticRiskArray = ['River', 'Ocean', 'Fire'];

      this.mapAddressService.updateRiskExpansionPanelOpened(riskType);
      // update the expanded panel observalbe

      // check if it is not a static riskPanel that is clicked this is because of we have to use different endpoints

      if (!staticRiskArray.includes(riskType)) {
        // Reach server for data
        this.mapAddressService
          .fetchAddressRiskDetails(addressID, lat, long, risk_type)
          .subscribe((data: any) => {
            // console.log(data);
            const riskDetails = data.riskData;
            const riskBoundary = data.boundary;

            // Since it is not a static risk that is clicked update the buffer rings to null
            this.mapAddressService.updateBufferRingsObservable(null);

            // updating risk details
            this.mapAddressService.updateRiskDetails(riskDetails);

            // updating risk polygon
            this.mapAddressService.updateRiskBoundary(riskBoundary);
          });
      } else if (staticRiskArray.includes(riskType)) {
        // check the type of static risktype show the appropriate buffer can be added
        switch (riskType) {
          case 'Fire':
            this.mapAddressService.updateBufferRingsObservable(
              BufferType.stationS
            );
            break;
          case 'River':
            this.mapAddressService.updateBufferRingsObservable(
              BufferType.rivers
            );
            break;
          case 'Ocean':
            this.mapAddressService.updateBufferRingsObservable(
              BufferType.oceans
            );
            break;
          default:
            this.mapAddressService.updateBufferRingsObservable(
              BufferType.stationS
            );
        }

        // Reach the server for risk details
        this.mapAddressService
          .fetchAddressStaticRiskDetails(addressID, lat, long, risk_type)
          .subscribe((data: any) => {
            const riskDetails = this.generateStaticRiskDetails(data);

            // updating risk details
            this.mapAddressService.updateRiskDetails(riskDetails);

            // updating static risk geojson features
            this.mapAddressService.updateStaticRiskGeojsonFeatures(
              this.generateStaticRiskFeatures(data)
            );
          });
      }
    }
  }

  /* staticRiskDetailClicked(riskType): void {
    if (this.currentAddressObservable) {
      const addressID = this.currentAddress.id;
      const lat = this.currentAddress.latitude;
      const long = this.currentAddress.longitude;
      const risk_type = riskType ? riskType.toUpperCase() : 'FIRE';

      // update the expanded panel observalbe

      this.mapAddressService.updateRiskExpansionPanelOpened(riskType);

      this.mapAddressService
        .fetchAddressStaticRiskDetails(addressID, lat, long, risk_type)
        .subscribe((data: any) => {
          console.log(data);
          const riskDetails = data.riskData;
          const riskBoundary = data.boundary;

          // updating risk details
          this.mapAddressService.updateRiskDetails(riskDetails);

          // updating risk polygon
          this.mapAddressService.updateRiskBoundary(riskBoundary);
        });
    }
  } */

  /**
   *
   * @param data data from response
   * @description generate risk details and separate risk features
   */
  private generateStaticRiskDetails(
    data: Array<{
      eventType: string;
      range: string;
      eventIndex: number;
      features: any[];
    }>
  ): any {
    const riskData = data.map((d) => {
      // separate feature and riskData;
      const newObj = {
        type: d.eventType,
        distance:d.range,
        score: d.eventIndex,
        range: d.range,
      };
      return newObj;
    });
    return riskData;
  }

  private generateStaticRiskFeatures(
    data: Array<{
      eventType: string;
      range: string;
      eventIndex: number;
      features: any[];
    }>
  ) {
    const featArray = data.map((d) => {
      const { features } = d;

      return features;
    });
    // filter out emptys
    return featArray.filter((f) => !!f)[0];
  }
}
