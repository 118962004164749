import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@environments/environment';
import { User } from '@app/shared/models/user.model';
import { EncryptionService } from '../encryptionService/encryption.service';
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;
  public token;
  private refreshTokenTimeout;


  constructor(
    private router: Router,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private encryptionService: EncryptionService

  ) {
    this.userSubject = new BehaviorSubject<User>(
      JSON.parse(sessionStorage.getItem('user'))
    );
    this.user = this.userSubject.asObservable();

  }

  public get userValue(): User {
    return this.userSubject.value;
  }

  // tslint:disable-next-line: typedef
  login(username: string, password: string) {
    const encryptedPasswod = this.encryptionService.encryptData(password)
    return this.http
      .post<any>(
        `${environment.apiUrl}auth/signin`,
        { username,
          password: encryptedPasswod,
        },
        { withCredentials: true , observe: 'response'},

      )
      .pipe(
        map((response) => {
          // Cookie.set('user', JSON.stringify(user))
          const user = response.body
          sessionStorage.setItem('username', username);
          sessionStorage.setItem('user', JSON.stringify(user));
          this.userSubject.next(user);
          this.startRefreshTokenTimer();
          return response;
        })
      );
  }

    // tslint:disable-next-line: typedef
  verifyOtp(token: string, otp: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}auth/verify-otp`, {
     token,
     otp
    }, { withCredentials: true })
    .pipe(
      map((response) => {
        // Cookie.set('user', JSON.stringify(user))
        // sessionStorage.setItem('username', username);
        sessionStorage.setItem('user', JSON.stringify(response));
        this.userSubject.next(response as any);
        this.startRefreshTokenTimer();
        return response;
      })
    );;
  }


  // resendOtp(token: string) {
  //   return this.http.post<any>(`${environment.apiUrl}/resend-otp`, {
  //     token: token
  //   }, { observe: 'response' });
  // }

  logout(): void {
    this.http
      .post<any>(
        `${environment.apiUrl}auth/logout`,
        {},
        { withCredentials: true }
      )
      .subscribe(
        (res) => {
          localStorage.removeItem('otpTimer');

          if (res.status === 200) {
            this.stopRefreshTokenTimer();
            // Cookie.delete('user');
            sessionStorage.clear();
            this.userSubject.next(null);
            this.router.navigate(['/login']);
          } else {
            this.stopRefreshTokenTimer();
            // Cookie.delete('user');
            sessionStorage.clear();
            this.userSubject.next(null);
            this.router.navigate(['/login']);
          }
        },
        (err) => {
          localStorage.removeItem('otpTimer');
          this.stopRefreshTokenTimer();
          sessionStorage.clear();
          this.userSubject.next(null);
          this.router.navigate(['/login']);
        }
      );
  }

  // tslint:disable-next-line: typedef
  refreshToken() {
    return this.http
      .post<any>(
        `${environment.apiUrl}auth/refresh`,
        {},
        { withCredentials: true }
      )
      .pipe(
        map((user) => {
          // console.log('refresh successfully');
          const formerUser = JSON.parse(sessionStorage.getItem('user'));
          formerUser.accessToken = user.accessToken;
          sessionStorage.setItem('user', JSON.stringify(formerUser));
          this.userSubject.next(formerUser);
          this.startRefreshTokenTimer();
          return user;
        })
      );
  }

  // tslint:disable -next-line: typedef
  forgetPassword(emaildata) {
    const url = `${environment.apiUrl}auth/password/reset/?email=${emaildata}`;
    return this.http.get(url);
  }

  // tslint:disable-next-line: typedef
  resetPassword(resetData) {
    try {
      this.activatedRoute.queryParams.subscribe((urlParams) => {
        this.token = urlParams.token;
        // console.log(urlParams);
      });
    } catch (error) {
      // console.log(error);
    }

    const encryptedNewPassword = this.encryptionService.encryptData(resetData.newPassword)

    // const url = `${environment.apiUrl}auth/change-password?token=${this.token}&newPassword=${encryptedNewPassword}`;
    const url = `${environment.apiUrl}auth/change-password`;
    return this.http.put(url, {
      token: this.token,
      newPassword: encryptedNewPassword
    });
  }

  // helper methods
  private startRefreshTokenTimer(): void {
    // parse json object from base64 encoded jwt token
    if (!this.userValue?.accessToken) return;

    const accessToken = JSON.parse(
      atob(this.userValue.accessToken?.split('.')[1])
    );
    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(accessToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - 60 * 1000;
    this.refreshTokenTimeout = setTimeout(
      () => this.refreshToken().subscribe(),
      timeout
    );
  }

  private stopRefreshTokenTimer(): void {
    clearTimeout(this.refreshTokenTimeout);
  }
}
