import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { BehaviorSubject, merge, Observable, Subscription } from 'rxjs';
import { startWith, switchMap, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';
import { ActivatedRoute } from "@angular/router";
import {MatTableDataSource} from '@angular/material/table'

import {
  GisAddresses,
  GisAddresshttpResModel,
} from 'src/app/shared/models/user.model';
import { ProjectService } from 'src/app/shared/services/projectService/project.service';
import { AddressConfirmationComponent } from 'src/app/views/gis-management/address-confirmation/address-confirmation.component';


@Component({
  selector: 'app-gis-management',
  templateUrl: './gis-management.component.html',
  styleUrls: ['./gis-management.component.scss']
})
export class GisManagementComponent implements OnInit {
  displayedColumns = [
    'select',
    'geocodingId',
    'customername',
    'housenumber',
    'streetname',
    'areaname',
    'lga',
    'state',
    'message'
  ];
  selection = new SelectionModel<GisAddresses>(true, []);
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  status = -1;
  addressStatus;
  term = '';
  startDate = '';
  endDate = '';

  gisAddresses: Observable<GisAddresshttpResModel[]>;
  addressArray: [];
  AddressIdsArray: number[] = [];
  showAssignBtn = false;
  addressListIsEmpty$: BehaviorSubject<{
    value: boolean;
    type: string;
  }> = new BehaviorSubject({ value: false, type: 'empty' });
  isLoadingResults: BehaviorSubject<boolean> = new BehaviorSubject(true);
  sortSub: Subscription;
  searchControl: FormControl = new FormControl('');
  totalAddressCount;
  pageSize = 20;
  pageSizeOptions: number[] = [10, 20, 30, 50, 100, 300, 500];
  sub : any;
  client_id: any;

  constructor(
    private pService: ProjectService,
    private toaster: ToastrService,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) { }

  addressForm = new FormGroup({
    start: new FormControl(''),
    end: new FormControl(''),
  });

  private updateLoadingStatus(status: boolean) {
    this.isLoadingResults.next(status);
  }

  ngOnInit(): void {
    this.gisAddresses = this.pService.getGisAddresses();
    // console.log(this.gisAddresses);

    this.sub = this.route.params.subscribe((params) => {
      // console.log(params);
      this.client_id = +params["client_id"]; // (+) converts string 'vendor_id' to a number
      // console.log(this.client_id);
    });

    this.searchControl.valueChanges.subscribe((value) => {
      this.term = value;
      this.paginator.page.emit();
    });
  }

  ngAfterViewInit() {
    this.sortSub = this.sort.sortChange.subscribe(
      () => (this.paginator.pageIndex = 0)
    );
    merge(this.paginator.page, this.sort.sortChange)
      .pipe(
        tap((value) => {
          // console.log(value);
        }),
        startWith({}),
        switchMap(() => {
          this.updateLoadingStatus(true);
          return this.pService.fetchGisAddresses(
            this.client_id,
            this.paginator.pageIndex,
            this.paginator.pageSize,
            this.term
          );
        })
      )
      .subscribe(
        (res) => {
          this.addressArray = res.addressWithIssues;
          // console.log(this.addressArray);
          this.pService.updateAddressList(this.addressArray);
          this.totalAddressCount = res.addressCount;
          this.updateLoadingStatus(false);

          this.addressListIsEmpty$.next({
            value: res.addressCount <= 0,
            type: 'empty',
          });
        },
        (error) => {
          this.updateLoadingStatus(false);
          this.addressListIsEmpty$.next({
            value: true,
            type: 'connection',
          });
          // console.log(error);
        }
      );
  }

  updateAddressTable() {
    this.pService
      .fetchGisAddresses(
        this.client_id,
        this.paginator.pageIndex,
        this.paginator.pageSize,
        this.term
      )
      // Updating the address after assign action;
      .subscribe(
        (res: any) => {
          this.addressArray = res.addressWithIssues;
          this.pService.updateGisAddressList(this.addressArray);
          this.totalAddressCount = res.addressCount;
          this.updateLoadingStatus(false);

          this.addressListIsEmpty$.next({
            value: res.addressCount <= 0,
            type: 'empty',
          });
        },
        (error) => {
          this.updateLoadingStatus(false);
          this.addressListIsEmpty$.next({
            value: true,
            type: 'connection',
          });
          // console.log(error);
        }
      );
  }

  clearAllFilter() {
    this.searchControl.reset();
  }

addressConfirmation() {
  const dialogRef =this.dialog.open(AddressConfirmationComponent, {
    disableClose: true
  })

  dialogRef.afterClosed().subscribe((res)=> {
    if (res === 'approve') {
      this.addressStatus = 2;
      this.pService.gisConfirmation(this.addressStatus, this.AddressIdsArray).subscribe((res) => {
        // console.log(res);
        if (res['message']==='Success'){
          this.toaster.success(res['message']);
        this.updateAddressTable();
        } else{
          this.toaster.error(res['message']);
        this.updateAddressTable();
        }

      })
    }else {
      this.addressStatus = 2;
      this.pService.gisConfirmation(this.addressStatus, this.AddressIdsArray).subscribe((res) => {
        // console.log(res);
        if (res['message']==='Success'){
          this.toaster.success(res['message']);
        this.updateAddressTable();
        } else{
          this.toaster.error(res['message']);
        this.updateAddressTable();
        }
      })
    }
  })
}

singleToggle(AddressId, e, status) {
  if (e.checked === true) {

    // if checked push AddressId to AddressIdsArray[]
    this.AddressIdsArray.push(AddressId);
    this.showAssignBtn = true;
    // console.log(this.AddressIdsArray);
  } else if (e.checked === false) {

    // if unchecked remove the AddressId from AddressIdsArray[]
    const index = this.AddressIdsArray.indexOf(AddressId);
    if (index > -1) {
      this.AddressIdsArray.splice(index, 1);
      // console.log(this.AddressIdsArray);
    }
    if (!this.AddressIdsArray || !this.AddressIdsArray.length) {
      this.showAssignBtn = false;
    }
  }
}

downloadAddress() {
  // console.log(this.addressForm.value);
    if (
      (this.addressForm.value.start === '' &&
        this.addressForm.value.end === '') ||
      (this.addressForm.value.start === null &&
        this.addressForm.value.end === null)
    ) {
      this.toaster.warning('Enter a valid date range');
    } else {
      const startDate = this.pService.formatDate(this.addressForm.value.start);
      const endDate = this.pService.formatDate(this.addressForm.value.end);
      this.pService
        .downloadPendingExcelFile(startDate, endDate, this.client_id)
        .subscribe(
          (res: any) => {
            // console.log(res);
            const file = new File([res.body], 'Pending-addresses' + '.xlsx', {
              type: 'application/vnd.ms-excel;charset=utf-8',
            });
            if (res.status === 200) {
              this.toaster.success('success');
              saveAs(file, 'Pending-addresses.xlsx');
            } else if (res.status === 400) {
              this.toaster.warning(
                'No data within this date range',
                'Caution'
              );
            }
          },

          (error) => {
            // console.log(error);
            if (error.status === 400) {
              this.toaster.warning(
                'No data within this date range',
                'Caution'
              );
            }
          }
        );
      }
  }

  onAddressFormSubmit(value) {
    // console.log(value);
    if (
      value.start !== '' &&
      value.end !== '' &&
      value.start !== null &&
      value.end !== null
    ) {
      this.startDate = this.pService.formatDate(value.start);
      this.endDate = this.pService.formatDate(value.end);
    } else {
      this.startDate = '';
      this.endDate = '';
    }
    this.updateAddressTable();
  }



}
