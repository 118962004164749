import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BehaviorSubject, merge, Observable, Subscription } from 'rxjs';
import { startWith, switchMap, tap } from 'rxjs/operators';
import { gisUserhttpResModel } from 'src/app/shared/models/user.model';
import { GisUserService } from 'src/app/shared/services/userService/gis-user.service';
import { CreateGisuserComponent } from './create-gisuser/create-gisuser.component';

@Component({
  selector: 'app-gis-user',
  templateUrl: './gis-user.component.html',
  styleUrls: ['./gis-user.component.scss']
})
export class GisUserComponent implements OnInit {

  isLoadingResults$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  gisUsers: Observable<gisUserhttpResModel[]>;
  displayedColumns: string[] = [
    'fullname',
    'username',
    'email',
    'status',
    // 'action',
  ];
  searchControl: FormControl = new FormControl('');
  search = '';
  totalGisUsersCount;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 20, 30, 50, 100, 300];
  sortSub$: Subscription;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    private gService: GisUserService,
  ) { }

  ngOnInit(): void {
    this.gisUsers = this.gService.getGisUsers();
    // console.log(this.gisUsers);

    this.searchControl.valueChanges.subscribe((value) => {
      // console.log(value);
      this.search = value;
      this.paginator.page.emit();
    });
  }

  private updateLoadingStatus(status: boolean) {
    this.isLoadingResults$.next(status);
  }

  ngAfterViewInit() {
    this.sortSub$ = this.sort.sortChange.subscribe(
      () => (this.paginator.pageIndex = 0)
    );
    merge(this.paginator.page, this.sort.sortChange)
      .pipe(
        tap((value) => {
          // console.log(value);
        }),
        startWith({}),
        switchMap(() => {
          this.updateLoadingStatus(true);
          return this.gService.getAllGisUsers(
            this.paginator.pageIndex,
            this.paginator.pageSize,
            this.search
          );
        })
      )
      .subscribe(
        (res) => {
          // console.log(res);
          this.gService.updateGisUsers(res.users);
          this.totalGisUsersCount = res.count;
          this.updateLoadingStatus(false);
        },
        (error) => {
          // console.log(error);
        }
      );
  }

  updateGisUsersTable() {
    this.gService
      .getAllGisUsers(this.paginator.pageIndex, this.paginator.pageSize)
      .subscribe(
        (res: any) => {
          this.gService.updateGisUsers(res.users);
          this.totalGisUsersCount = res.count;
          this.updateLoadingStatus(false);
        },
        (error) => {
          this.updateLoadingStatus(false);
          // console.log(error);
        }
      );
  }

  createGisUserModal(): void {
    const dialogRef = this.dialog.open(CreateGisuserComponent, {
      width: '23vw',
      height: '56vh',
    });
    dialogRef.afterClosed().subscribe(() => {
      this.updateGisUsersTable();
    });
  }

  clearAllFilter() {
    this.search = '';
    this.paginator.page.emit();
    this.searchControl.setValue('');
  }
}
