import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './views/access-management/login/login.component';
import { ForgetPasswordComponent } from './views/access-management/forget-password/forget-password.component';
import { ResetPasswordComponent } from './views/access-management/reset-password/reset-password.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from './shared/vendors/angular-material-modules';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { MapComponent } from './views/map-management/map/map.component';
import { ClientManagementComponent } from './views/client-management/client-management.component';
import { ClientManagementService } from './views/client-management/client-management.service';
import { AddClientDialogComponent } from './views/client-management/add-client-dialog/add-client-dialog.component';
import { AddressStructureComponent } from './views/address-management/address-structure/address-structure.component';
import { HeaderFooterComponent } from './views/header-footer/header-footer.component';
import { UserManagementComponent } from './views/user-managemnt/user-management/user-management.component';
import { AddressUploadComponent } from './views/address-upload/address-upload.component';
import { RiskComponent } from './views/map-management/map/risk-panel-component/risk-panel.component';
import { AppFilterComponent } from './views/map-management/map/filter-component/filter.component';
import { FieldworkerComponent } from './views/user-managemnt/fieldworker/fieldworker.component';
import { CreateFieldworkerComponent } from './views/user-managemnt/fieldworker/create-fieldworker/create-fieldworker.component';
import { CreateUserComponent } from './views/user-managemnt/user-management/create-user/create-user.component';
import { ChangePasswordComponent } from './views/user-managemnt/user-management/change-password/change-password.component';
import { AddressSearchComponent } from './views/map-management/map/address-search-component/address-search.component';
import { AddressListsComponent } from './views/address-management/address-lists/address-lists.component';
import { AddressListComponent } from './views/map-management/map/address-list-component/address-list.component';
import { AccordionComponent } from './views/map-management/map/accordion-component/accordion.component';
import { ClientCardComponent } from './views/client-management/client-card/client-card.component';
import { ConfirmationComponent } from './views/confirmation/confirmation.component';
import { AssignAddressComponent } from './views/address-management/address-lists/assign-address/assign-address.component';
import { GisUserComponent } from './views/user-managemnt/gis-user/gis-user.component';
import { CreateGisuserComponent } from './views/user-managemnt/gis-user/create-gisuser/create-gisuser.component';
import { AuthenticationService } from './shared/services/authService/authentication.service';
// import { appInitializer } from './shared/helpers/app.initializer';
import { JwtInterceptor } from './shared/helpers/jwt.interceptor';
import { ErrorInterceptor } from './shared/helpers/error.interceptor';
import { CookieService } from 'ngx-cookie-service';
import { CreateFieldComponent } from './views/address-management/address-structure/create-field/create-field.component';
import { CreateOptionComponent } from './views/address-management/address-structure/create-option/create-option.component';
// import { CreateFieldComponent } from './views/address-management/address-structure/create-field/create-field.component';
import { GisManagementComponent } from './views/gis-management/gis-management.component';
import { AddressConfirmationComponent } from './views/gis-management/address-confirmation/address-confirmation.component';
import { ChangeProfilePasswordComponent } from './views/header-footer/change-profile-password/change-profile-password.component';
import { ChangePasswordFirstimeComponent } from './views/access-management/change-password-firstime/change-password-firstime.component';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { GoogleMapComponent } from './views/map-management/map/google-map-component/googleMap.component';
import { CdkTableExporterModule } from 'cdk-table-exporter';
import { MatTableExporterModule } from 'mat-table-exporter';
import { UpdateAddressComponent } from './views/address-management/address-lists/update-address/update-address.component';
import { DashboardModule } from 'src/app/views/dashboard/dashboard.module';
import { FieldworkerProgressComponent } from './views/user-managemnt/fieldworker/fieldworker-progress/fieldworker-progress.component';
import { ChangeTokenComponent } from './views/header-footer/change-token/change-token.component';
import { StreetviewComponent } from './views/map-management/map/streetview/streetview.component';
import { MapFooterComponent } from './views/map-management/map/map-footer/map-footer.component';
import { AppDragDropDirective } from './shared/directives/app-drag-drop.directive';
import { ViewLogComponent } from './views/address-management/address-lists/view-log/view-log.component';
import { AddressArchiveComponent } from './views/address-management/address-archive/address-archive.component';
import { DuplicateAddressComponent } from './views/address-management/address-lists/duplicate-address/duplicate-address.component';
import { VerifyOtpComponent } from './views/access-management/factor-authentication/factor-authentication.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    MapComponent,
    ClientManagementComponent,
    AddClientDialogComponent,
    GoogleMapComponent,
    RiskComponent,
    AddressStructureComponent,
    HeaderFooterComponent,
    UserManagementComponent,
    CreateUserComponent,
    AddressUploadComponent,
    AppFilterComponent,
    FieldworkerComponent,
    CreateFieldworkerComponent,
    ChangePasswordComponent,
    AddressSearchComponent,
    AddressListsComponent,
    AddressListComponent,
    AccordionComponent,
    ClientCardComponent,
    ConfirmationComponent,
    AssignAddressComponent,
    GisUserComponent,
    CreateGisuserComponent,
    CreateFieldComponent,
    CreateOptionComponent,
    GisManagementComponent,
    AddressConfirmationComponent,
    ChangeProfilePasswordComponent,
    ChangePasswordFirstimeComponent,
    VerifyOtpComponent,
    FieldworkerProgressComponent,
    UpdateAddressComponent,
    ChangeTokenComponent,
    StreetviewComponent,
    MapFooterComponent,
    AppDragDropDirective,
    ViewLogComponent,
    AddressArchiveComponent,
    DuplicateAddressComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularMaterialModule,
    CdkTableExporterModule,
    MatTableExporterModule,
    CommonModule,
    DashboardModule,
    ToastrModule.forRoot(),
    MatPasswordStrengthModule.forRoot(),
  ],
  providers: [
    CookieService,
    ClientManagementService,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: appInitializer,
    //   multi: true,
    //   deps: [AuthenticationService],
    // },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  entryComponents: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
