import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './views/access-management/login/login.component';
import { ForgetPasswordComponent } from './views/access-management/forget-password/forget-password.component';
import { ResetPasswordComponent } from './views/access-management/reset-password/reset-password.component';
import { ClientManagementComponent } from './views/client-management/client-management.component';
import { AddressStructureComponent } from './views/address-management/address-structure/address-structure.component';
import { HeaderFooterComponent } from './views/header-footer/header-footer.component';
import { MapComponent } from './views/map-management/map/map.component';
import { UserManagementComponent } from './views/user-managemnt/user-management/user-management.component';
import { AddressUploadComponent } from './views/address-upload/address-upload.component';
import { AddressListsComponent } from './views/address-management/address-lists/address-lists.component';
import { FieldworkerComponent } from './views/user-managemnt/fieldworker/fieldworker.component';
import { GisUserComponent } from './views/user-managemnt/gis-user/gis-user.component';
import { GisManagementComponent } from './views/gis-management/gis-management.component';
import {DashboardComponent} from 'src/app/views/dashboard/dashboard.component';
import { AuthGuard } from './shared/helpers/auth.guard';
import { Role } from './shared/models/role.model';
import { ChangePasswordFirstimeComponent } from './views/access-management/change-password-firstime/change-password-firstime.component';
import { AddressArchiveComponent } from './views/address-management/address-archive/address-archive.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'forget-password',
    component: ForgetPasswordComponent,
    data: {
      title: 'Forget Password Page'
    }
  },
  {
    path: 'change-password',
    component: ChangePasswordFirstimeComponent,
    data: {
      title: 'Change Password'
    }
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    data: {
      title: 'Reset Password Page'
    }
  },
  {
    path: '',
    component: HeaderFooterComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'map',
        component: MapComponent,
        data: {
          title: 'Risk MAp'
        }
      },
      {
        path: 'address-upload',
        component: AddressUploadComponent,
        data: {
          title: 'Address Upload'
        }
      },
      {
        path: 'address-form',
        component: AddressStructureComponent,
        data: {
          title: 'Address Form'
        }
      },
      {
        path: 'address-archive',
        component: AddressArchiveComponent,
        data: {
          title: 'Address Archive'
        }
      },
      {
        path: 'address/:outsideTat/:status',
        component: AddressListsComponent,
        data: {
          title: 'Address List'
        }
      },
      {
        path: 'address',
        component: AddressListsComponent,
        data: {
          title: 'Address List'
        }
      },
      {
        path: 'users',
        component: UserManagementComponent,
        data: {
          roles: [Role.Client],
          title: 'Office Staff Management'
        }
      },
      {
        path: 'gis-users',
        component: GisUserComponent,
        data: {
          roles: [Role.SuperAdmin],
          title: 'GIS Users Management'
        }
      },
      {
        path: 'agents',
        component: FieldworkerComponent,
        data: {
          title: 'Field Staff Management'
        }
      },

      {
        path: 'clients',
        component: ClientManagementComponent,
        data: {
          roles: [Role.SuperAdmin],
          title: 'Client Management'
        }
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: {
          title: 'Dashboard'
        }
      },
      {
        path: 'gis-address/:client_id',
        component: GisManagementComponent,
        data: {
          roles: [Role.GIS],
          title: 'Gis Address Management'
        }
      }
    ]
  },

  {
    path: '**',
    redirectTo: 'login'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
