import {
  Directive,
  Output,
  HostBinding,
  EventEmitter,
  HostListener
} from "@angular/core";

@Directive({
  selector: "[appDragDrop]"
})
export class AppDragDropDirective {
  constructor() {}

  @Output() fileDropped = new EventEmitter<any>();

  @HostBinding("style.background-color") public background =
    "rgba(221, 221, 221, 0.78)";
  @HostBinding("style.opacity") public opacity = "1";

  // Dragover listener

  @HostListener("dragover", ["$event"]) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    this.background = "#9ecbec";
    this.opacity = "0.8";
  }

  // Dragleave listener
  @HostListener("dragleave", ["$event"]) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = "#f5fcff";
    this.opacity = "1";
  }

  // Drop listener
  @HostListener("drop", ["$event"]) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = "#f5fcff";
    this.opacity = "1";
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
  }
}
