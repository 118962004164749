<div class="container">
  <form (ngSubmit)="onFormSubmit()" class="create-fieldworker-form" [formGroup]="addressDuplicateForm" #f="ngForm">

    <mat-form-field appearance="outline">
      <mat-label>Geocoding ID</mat-label>
      <input matInput formControlName="newGeocodingId" placeholder="Enter new geocoding ID" required>
      <mat-error *ngIf="addressDuplicateForm.get('newGeocodingId').hasError('required')">Geocoding ID is required</mat-error>
    </mat-form-field>

    <mat-grid-list cols="2" rowHeight="37px">
      <mat-grid-tile>
        <button type="button" mat-flat-button class="cancelBtn" color="warn" (click)="closeModal('yes')">CANCEL</button>
      </mat-grid-tile>
      <mat-grid-tile>
        <button [disabled]="addressDuplicateForm.invalid" mat-flat-button class="saveBtn">DUPLICATE
          <mat-spinner *ngIf="isLoading" class="custom-spinner" diameter="20"></mat-spinner>
        </button>
      </mat-grid-tile>
    </mat-grid-list>

  </form>
</div>
