import { Injectable } from '@angular/core';
// import { AddressField } from '../models/addressField.model';
import { HttpClient, HttpHeaders } from "@angular/common/http";
// import { baseUrl } from '../../server/urlConfig';
import { environment } from '@environments/environment';
import { FormBuilder, FormGroup, FormArray } from "@angular/forms";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
// import { AuthenticationService } from "../authService/authentication.service";
// import { DefaultAddressStructure } from "../../models/defaultAddress.model";

@Injectable({
  providedIn: 'root',
})
export class AddressStructureService {
  // defaultAddressStructure: DefaultAddressStructure = new DefaultAddressStructure();
  constructor(
    // private authService: AuthenticationService,
    private fb: FormBuilder,
    private http: HttpClient
  ) {}

  setStructure(fields: any) {
    const url = `${environment.apiUrl}addExtraFields`;
    return this.http.post(url, fields).pipe(catchError(this.handleError));
  }

  setoptions(option: any, fieldId) {
    const url = `${environment.apiUrl}addSelectOption?fieldId=${fieldId}`;
    return this.http
      .post(url, option.options)
      .pipe(catchError(this.handleError));
  }
  // get defaultStructure(): FormGroup[] {
  //   return Object.keys(this.defaultAddressStructure).map(key => {
  //     return this.fb.group({
  //       fieldName: this.fb.control({ value: key, disabled: true }),
  //       fieldType: this.fb.control({ value: "string", disabled: true }),
  //       required: this.fb.control({
  //         value: this.defaultAddressStructure[key],
  //         disabled: true
  //       })
  //     });
  //   });
  // }

  getDynamicStructure(): any {
    const url = environment.apiUrl + 'getExtraFields';
    return this.http.get(url).pipe(catchError(this.handleError));
  }

  deleteSelect(id) {
    const url = `${environment.apiUrl}deleteExtraField/?fieldId=${id}`;
    return this.http.delete(url).pipe(catchError(this.handleError));
  }

  deleteOption(id) {
    // `${environment}users/subUsers/?page=${page}&size=${pageSize}`
    const url = `${environment.apiUrl}deleteSelectOption/?optionId=${id}`;
    return this.http.delete(url).pipe(catchError(this.handleError));
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
      // console.log(error.error);
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\n
      Message: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
