<div class="container">
  <div class="label">
    <h3><b>{{username}}</b></h3>
  </div>

  <div class="flex-grid-thirds">
    <div class="col">
      <div class="card total">
          <ng-container *ngIf="!cardLoader; else loading">
            <div class="d-card" style="text-align: center;">
              <p class="d-card__label"> Total Addresses </p>
              <h3 class="d-card__number">{{ total }}</h3>
            </div>
          </ng-container>

          <ng-template #loading>
            <div class="spiner-div">
              <mat-spinner class="custom-spinner" diameter="50"></mat-spinner>
            </div>
          </ng-template>
      </div>
    </div>

    <div class="col">
      <div class="card verified">
        <ng-container *ngIf="!cardLoader; else loading">
          <div class="d-card" style="text-align: center;">
            <p class="d-card__label"> Verified Addresses </p>
            <h3 class="d-card__number">{{ verified }}</h3>
          </div>
        </ng-container>

        <ng-template #loading>
          <div class="spiner-div">
            <mat-spinner class="custom-spinner" diameter="50"></mat-spinner>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="col">
      <div class="card pending">
        <ng-container *ngIf="!cardLoader; else loading">
          <div class="d-card" style="text-align: center;">
            <p class="d-card__label"> Pending Addresses </p>
            <h3 class="d-card__number">{{ pending }}</h3>
          </div>
        </ng-container>

        <ng-template #loading>
          <div class="spiner-div">
            <mat-spinner class="custom-spinner" diameter="50"></mat-spinner>
          </div>
        </ng-template>
     </div>
    </div>
  </div>

</div>
