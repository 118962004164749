<div class="container">

  <form (ngSubmit)="onCreatefieldworker()" class="create-fieldworker-form" [formGroup]="fieldworkerForm" #f="ngForm">

    <mat-form-field appearance="outline">
      <mat-label>Agent Fullname</mat-label>
      <mat-icon matPrefix>contacts</mat-icon>
      <input matInput formControlName="fullname" placeholder="Full name" [errorStateMatcher]="matcher" required>
      <mat-error *ngIf="fieldworkerForm.get('fullname').hasError('required')">Enter agent full name</mat-error>
      <mat-error *ngIf="fieldworkerForm.get('fullname').hasError('pattern')">
        Only letters are allowed
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Username</mat-label>
      <mat-icon matPrefix>person</mat-icon>
      <input matInput formControlName="username" placeholder="Username" [errorStateMatcher]="matcher" required>
      <mat-error *ngIf="fieldworkerForm.get('username').hasError('required')">Enter agent username</mat-error>
            <mat-error *ngIf="fieldworkerForm.get('username').hasError('pattern')">
        Only letters and numbers are allowed
      </mat-error>
    </mat-form-field>

    <!-- <mat-form-field appearance="outline">
      <mat-label>Password</mat-label>
      <mat-icon matPrefix>lock</mat-icon>
      <input matInput [type]="hidePassword ? 'password' : 'text'" type="password" formControlName="password"
        name="password" required>
      <button type="button" mat-icon-button matSuffix (click)="hidePassword = !hidePassword"
        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
        <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
      <mat-error *ngIf="fieldworkerForm.controls['password'].invalid">Password is required</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Confirm Password</mat-label>
      <mat-icon matPrefix>lock</mat-icon>
      <input matInput [type]="hideConfirmPassword ? 'password' : 'text'" type="password"
        formControlName="confirmPassword" name="confirmPassword" required>
      <button type="button" mat-icon-button matSuffix (click)="hideConfirmPassword = !hideConfirmPassword"
        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideConfirmPassword">
        <mat-icon>{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
      <mat-error
        *ngIf="fieldworkerForm.controls['confirmPassword'].pristine || fieldworkerForm.controls['confirmPassword'].errors?.MatchPassword">
        Password and Confirm Password didn't match
      </mat-error>
    </mat-form-field> -->

    <!--password input filed-->
    <mat-form-field appearance="outline" style="width: 70%">
      <mat-label>Password</mat-label>
      <mat-pass-toggle-visibility #toggle matSuffix></mat-pass-toggle-visibility>
      <input matInput #passwordWithValidation [type]="toggle.type" required formControlName="password"
        placeholder="Password" maxlength="15" [value]="newPassword" [errorStateMatcher]="matcher"/>

      <!--password hint-->
      <mat-hint align="end" aria-live="polite">
        {{ passwordWithValidation.value.length }} / 15
      </mat-hint>

      <!--password Mat-error-->
      <mat-error *ngIf="fieldworkerForm.controls['password'].hasError('required')">Password is required</mat-error>
      <mat-error *ngIf="fieldworkerForm.controls['password'].hasError('invalidPassword')">
        Password is not valid
      </mat-error>
    </mat-form-field>
     <span>
      <button mat-button class="generateBtn" type="button" (click)="generatePassword()">{{buttonLabel}}</button>
    </span>
    <!--password-strength's main component-->
    <mat-password-strength #passwordComponentWithValidation [password]="passwordWithValidation.value" class="green"
      [min]="8" [max]="15" [enableLowerCaseLetterRule]="false">
    </mat-password-strength>

    <!--Password's strength info-->
    <mat-password-strength-info [passwordComponent]="passwordComponentWithValidation"></mat-password-strength-info>

    <br>
    <mat-grid-list cols="2" rowHeight="37px">
      <mat-grid-tile>
        <button type="button" mat-flat-button class="cancelBtn" color="warn" (click)="closeModal()">Cancel</button>
      </mat-grid-tile>
      <mat-grid-tile>
        <button [disabled]="fieldworkerForm.invalid" mat-flat-button class="saveBtn">Create
          <mat-spinner *ngIf="isLoading" class="custom-spinner" diameter="20"></mat-spinner>
        </button>
      </mat-grid-tile>
    </mat-grid-list>

  </form>
</div>
