<section class="accordion-container" role="region">
  <mat-accordion>
    <mat-expansion-panel *ngFor="let riskCategory of riskCategories" hideToggle
      [disabled]="disablePanel(getKeyValue(riskCategory)[0][1])"
      [expanded]="expandPanel(getKeyValue(riskCategory)[0][1])">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ getKeyValue(riskCategory)[0][1] }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <ng-container *ngIf="!(loading$ | async).riskDetailsFetch; else loading">
        <table class="risk-table">
          <thead>
            <ng-container *ngIf="riskDetailsBody && riskDetailsBody.length > 0">
              <th *ngFor="let riskDetailHeader of riskDetailsBody[0] | keyvalue" style="text-transform: capitalize">
                {{ riskDetailHeader.key }}
              </th>
            </ng-container>
          </thead>
          <tbody>
            <tr *ngFor="let riskBodyRow of riskDetailsBody" [style.BackgroundColor]="
                riskIndexColorization(riskBodyRow && riskBodyRow.score)
              ">
              <td *ngFor="let riskBody of riskBodyRow | keyvalue">
                {{ riskBody.value }}
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
      <ng-template #loading>
        <div class="loader-wrapper">
          <img style="height: 2rem" src="assets/img/loader.gif" />
        </div>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</section>
