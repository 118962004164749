import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import { ClientManagementService } from '../client-management.service';

@Component({
  selector: 'app-add-client-dialog',
  templateUrl: './add-client-dialog.component.html',
  styleUrls: ['./add-client-dialog.component.scss']
})
export class AddClientDialogComponent implements OnInit {
  creating = false;
  details: FormGroup;

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private clientService: ClientManagementService,
    private dialogRef: MatDialogRef<AddClientDialogComponent>,
    private toaster: ToastrService
  ) {
    this.details = this.fb.group({
      logo: '',
      email: '',
      password: '',
      username: '',
      companyName: ''
    });
  }

  ngOnInit(): void {}

  private openSnackBar(message: string) {
    this.snackBar.open(message, null, { duration: 2000 });
  }

  onLogoChange({ target }: Event) {
    const formData = new FormData();
    const file = (target as HTMLInputElement).files[0];

    formData.append('logo', file);

    this.details.controls.logo.setValue(formData);
  }

  createClient() {
    if (!this.details.valid) return;

    this.creating = true;
    const { value } = this.details;

    // console.log(value);

    const subscription = this.clientService
      .createClient(value)
      .subscribe(({ status, message }: any) => {

        this.openSnackBar(message);
        if (status === 0){
          this.toaster.success(message);
          this.creating = false;
          this.dialogRef.close();
        } else {
          this.toaster.error(message);
          this.creating = false;
        }

        if (status === 1) return;

        this.dialogRef.close();
      },
      (err => {
        // console.log(err);
          this.toaster.error(err);
          this.creating = false;
      })
      );
  }

  // createErrorHandler(e: Error) {
  //   this.creating = false;
  //   console.log(e);
  // }
}
