import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { FieldWorker, fieldWorkerhttpResModel } from '../../models/user.model';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FieldworkerService {
  private fieldWorker$: BehaviorSubject<
    fieldWorkerhttpResModel[]
  > = new BehaviorSubject([]);

  constructor(
    private http: HttpClient
  ) {}

  createAgent(fieldworkData: FieldWorker) {
    const url = `${environment.apiUrl}users/create/fieldworker`;
    const params = new HttpParams()
      .append('fullname', fieldworkData.fullname)
      .append('username', fieldworkData.username)
      .append('password', fieldworkData.password);

    return this.http
      .post(url, params)
      .pipe(retry(1), catchError(this.handleError));
  }

  getFieldWorkers() {
    return this.fieldWorker$.asObservable();
  }

  updateFieldWorker(data: fieldWorkerhttpResModel[]) {
    this.fieldWorker$.next(data);
  }

  getAllFieldWorkers(page: number, pageSize: number, search: string, status: number) {
    const url = `${environment.apiUrl}users/fieldworkers?page=${page}&size=${pageSize}&query=${search}&status=${status}`;
    return this.http.get<any>(url).pipe(
      retry(1),
      catchError(this.handleError),
      map((res: any) => {
        if (res.status === 0) {
          // console.log(res);
          const fieldWorkers = res;
          return fieldWorkers;
        } else {
          return of([]);
        }
      })
    );
  }

  toggleActivation(id, userRole) {
    if (userRole === 'fieldWorker') {
      const url = `${environment.apiUrl}users/fieldworker/toggle?id=${id}`;
      return this.http.post(url, {}).pipe(catchError(this.handleError));
    } else if (userRole === 'subUser') {
      const url = `${environment.apiUrl}users/clients/toggle-subuser?id=${id}`;
      return this.http.post(url, {}).pipe(catchError(this.handleError));
    }
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\n
            Message: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
