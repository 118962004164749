<div class="container">

  <div class="addGisBtn_container">
    <button (click)="createGisUserModal()" class="add-gis-btn" mat-button>
      <mat-icon>group_add</mat-icon>
      <span>&nbsp;Create GIS User</span>
    </button>
  </div>

  <mat-card class="example-card">
    <mat-card-header>
      <mat-card-title>GIS User Management</mat-card-title>
    </mat-card-header>
    <mat-divider></mat-divider>
    <br />

    <mat-card-content>
      <!-- search keyword -->
      <mat-form-field>
        <mat-label>
          Search ......................<mat-icon>search</mat-icon>
        </mat-label>
        <input matInput placeholder="Search for a GIS user" [formControl]="searchControl">
        <button type="button" mat-icon-button matSuffix (click)="clearAllFilter()">
          <mat-icon aria-hidden="false" aria-label="Clear filter" color="warn">backspace</mat-icon>
        </button>
      </mat-form-field>

      <div class="gisUser-container mat-elevation-z8">
        <div class="table-loading-shade" *ngIf="isLoadingResults$ | async">
          <mat-spinner class="custom-spinner"></mat-spinner>
        </div>

        <div class="gisUser-table-container">
          <table mat-table [dataSource]="gisUsers" class="gisUser-table" matSort matSortActive
            matSortDisableClear matSortDirection="desc">
            <!-- Full Name -->
            <ng-container matColumnDef="fullname">
              <th mat-header-cell *matHeaderCellDef>Full Name</th>
              <td mat-cell *matCellDef="let row">{{ row.fullname }}</td>
            </ng-container>

            <!-- Email -->
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef>Email</th>
              <td mat-cell *matCellDef="let row">{{ row.email }}</td>
            </ng-container>

            <!-- Username -->
            <ng-container matColumnDef="username">
              <th mat-header-cell *matHeaderCellDef>Username</th>
              <td mat-cell *matCellDef="let row">{{ row.username }}</td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>
                Status
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-icon *ngIf="row.isAccountNonLocked" style="color: rgb(32, 165, 19);">verified_user</mat-icon>

                <mat-icon *ngIf="!row.isAccountNonLocked" style="color: orangered;">unpublished</mat-icon>
              </td>
            </ng-container>

            <!-- <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>Actions</th>
              <td mat-cell *matCellDef="let row">
                <mat-menu style="padding: 0;" #appMenu="matMenu">

                  <button mat-menu-item (click)="changePassword(row.id, row.roles[0].name, row.username)" class="userActionTableBtn">
                    Change Password
                  </button>

                  <button (click)="changeToggle(row.id, 'Deactivate')" *ngIf="row.isAccountNonLocked" mat-menu-item
                    class="userActionTableBtn">
                    Deactivate Agent
                  </button>
                  <button (click)="changeToggle(row.id, 'Activate')" mat-menu-item class="userActionTableBtn"
                    *ngIf="!row.isAccountNonLocked">
                    Enable Agent
                  </button>
                </mat-menu>

                <button mat-icon-button color="primary" [matMenuTriggerFor]="appMenu">
                  <mat-icon>more_vert</mat-icon>
                </button>
              </td>
            </ng-container> -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" class="example-element-row"></tr>
          </table>
        </div>

        <mat-paginator [length]="totalGisUsersCount" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
          showFirstLastButtons></mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>

</div>
