import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { gisUserhttpResModel, gisUsers } from '../../models/user.model';
import { environment } from '@environments/environment';
import { EncryptionService } from '../encryptionService/encryption.service';

@Injectable({
  providedIn: 'root'
})
export class GisUserService {

  private gisUsers$: BehaviorSubject<gisUserhttpResModel[]> = new BehaviorSubject([]);

  constructor(
    private http: HttpClient,
    private encryptionService: EncryptionService
  ) { }

  createGisUser(gisUserData: gisUsers) {
    const encryptedPassword = this.encryptionService.encryptData(gisUserData.password)
    const url = `${environment.apiUrl}users/gis/create`;
    // const params = new HttpParams()
    // .append('fullname', gisUserData.fullname)
    // .append('email', gisUserData.email)
    // .append('username', gisUserData.username)
    // .append('password', encryptedPassword);

    return this.http
      .post(url,
        {
          fullname: gisUserData.fullname,
          email: gisUserData.email,
          username: gisUserData.username,
          password: encryptedPassword
        }
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  getGisUsers() {
    return this.gisUsers$.asObservable();
  }

  updateGisUsers(data: gisUserhttpResModel[]) {
    this.gisUsers$.next(data);
  }

  getAllGisUsers(
    page: number,
    pageSize: number,
    search = ''
  ) {
    const url = `${environment.apiUrl}users/gis?page=${page}&size=${pageSize}&query=${search}`;
    return this.http.get<any>(url).pipe(
      retry(1),
      catchError(this.handleError),
      map((res: any) => {
        if (res.status === 0) {
          // console.log(res);
          const gisUserss = res;
          return gisUserss;
        } else {
          return of([]);
        }
      })
    );
  }

  // toggleActivation(id, userRole) {
  //   if (userRole === 'fieldWorker')
  //   {
  //     const url = `${environment.apiUrl}users/fieldworker/toggle?id=${id}`;
  //   return this.http
  //     .post(url, {})
  //     .pipe(catchError(this.handleError));
  //   }
  //   else if (userRole === 'subUser')
  //   {
  //     const url = `${environment.apiUrl}users/clients/toggle-subuser?id=${id}`;
  //   return this.http
  //     .post(url, {})
  //     .pipe(catchError(this.handleError));
  //   }
  // }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\n
            Message: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
