<mat-sidenav-container class="map__container">
  <!-- Left Panel Start -->
  <mat-sidenav class="left-panel mat-elevation-z8" mode="side" role="region" opened>
    <mat-card class="left-panel__card">
      <app-filter (datePickerClosed)="onDatePickerClosed()"></app-filter>
      <app-address-search></app-address-search>

      <ng-container *ngIf="(addressListObservable$ | async).length > 0">
        <button class="download-btn" type="button" (click)="exportCsv()">
          <span>Export Data </span>
          <span *ngIf="downloadLoader">
            <mat-spinner class="custom-spinner" diameter="20"></mat-spinner>
          </span>
        </button>
      </ng-container>

      <app-address-list (addressClicked)="panMapOnAddressClick($event)"></app-address-list>
    </mat-card>
  </mat-sidenav>
  <!--/ Left Panel End -->
  <!-- Right Panel Start-->
  <mat-sidenav class="right-panel mat-elevation-z8" position="end" mode="side" #right_panel
    (openedChange)="rightPanelClosed($event)">
    <mat-card class="right-panel__card">
      <app-accordion></app-accordion>
      <button title="close risk panel" mat-icon-button (click)="right_panel.close()">
        <mat-icon>chevron_right</mat-icon>
      </button>
    </mat-card>
  </mat-sidenav>

  <!-- /Right Panel End -->

  <!-- Map main content -->
  <mat-sidenav-content class="main-content">
    <app-streetview></app-streetview>

    <app-map-footer></app-map-footer>

    <app-mapboxgl-map (viewRiskPanel)="openRightPanel()" (closePanel)='closePanelOnNewMarkerClick()'></app-mapboxgl-map>

    <app-risk-panel *ngIf="showRiskPanel"></app-risk-panel>
  </mat-sidenav-content>
</mat-sidenav-container>
