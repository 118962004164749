import { Component, OnInit, Input } from '@angular/core';
import { ClientManagementService } from '../client-management.service';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

import { ConfirmationComponent } from '../../confirmation/confirmation.component';
const defaultLogo =
  'https://img.icons8.com/material-rounded/96/000000/user-male-circle.png';

export interface Client {
  id: number;
  logo: string;
  email: string;
  fullname: string;
  isAccountNonLocked: boolean;
}

@Component({
  selector: 'app-client-card',
  templateUrl: './client-card.component.html',
  styleUrls: ['./client-card.component.scss']
})
export class ClientCardComponent implements OnInit {
  @Input() client: Client;
   isAccountLock : any;
   status
  constructor(
    private clientService: ClientManagementService,
    public dialog: MatDialog,
    private toaster: ToastrService
    ) {}

  ngOnInit(): void {
    const image = new Image();

    image.onload = () => (this.client.logo = image.src);
    image.onerror = () => (this.client.logo = defaultLogo);

    image.src = this.client.logo;
  }

  toggleClient() {
    this.isAccountLock = this.client.isAccountNonLocked;
    // console.log(this.client);
    if (this.isAccountLock=='false') {
      this.status = 'deactivate'
    } else {
      this.status = 'activate'
    }
    const dialogRef = this.dialog.open (ConfirmationComponent, {
      data: { action: `${this.status} Client` },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'cancel') {
      } else if ('ok') {
        this.clientService.toggleClient(this.client.id).subscribe((res: { status: number; message: string }) => {
          // console.log(res);
          if (res.status === 0) {
            this.toaster.success(res.message);
          } else {
            this.toaster.warning(res.message);
          }
        },
        (err) => {
          // console.log(err);
        }
        );
      }
    });
  }
}
