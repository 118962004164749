<div class="container">
  <form (ngSubmit)="onCreateGisUser()" class="create-gisuser-form" [formGroup]="gisuserForm" #f="ngForm">
    <mat-form-field appearance="outline">
      <mat-label>Fullname</mat-label>
      <mat-icon matPrefix>contacts</mat-icon>
      <input matInput formControlName="fullname" placeholder="Full name" required />
      <mat-error *ngIf="gisuserForm.get('fullname').hasError('required')">Full name is required</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Email</mat-label>
      <mat-icon matPrefix>email</mat-icon>
      <input matInput placeholder="pat@example.com" formControlName="email" />
      <!-- <mat-error *ngIf="gisuserForm.get('email').invalid">{{getErrorMessage()}}</mat-error> -->
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Username</mat-label>
      <mat-icon matPrefix>person</mat-icon>
      <input matInput formControlName="username" placeholder="Username" />
      <mat-error *ngIf="gisuserForm.get('username').hasError('required')">Username is required</mat-error>
    </mat-form-field>

    <!-- <mat-form-field appearance="outline">
      <mat-label>Password</mat-label>
      <mat-icon matPrefix>lock</mat-icon>
      <input matInput [type]="hidePassword ? 'password' : 'text'" type="password" formControlName="password"
        name="password">
      <button type="button" mat-icon-button matSuffix (click)="hidePassword = !hidePassword"
        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
        <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
    </mat-form-field> -->

    <!--password input filed-->
    <mat-form-field appearance="outline" style="width: 100%">
      <mat-label>Password</mat-label>
      <mat-pass-toggle-visibility #toggle matSuffix></mat-pass-toggle-visibility>
      <input matInput #passwordWithValidation [type]="toggle.type" required formControlName="password"
        placeholder="Password" />

      <!--password hint-->
      <mat-hint align="end" aria-live="polite">
        {{ passwordWithValidation.value.length }} / 15
      </mat-hint>

      <!--password Mat-error-->
      <mat-error *ngIf="gisuserForm.controls['password'].hasError('required')">Password is required</mat-error>
      <mat-error *ngIf="gisuserForm.controls['password'].hasError('invalidPassword')">
        Password is not valid
      </mat-error>
    </mat-form-field>

    <!--password-strength's main component-->
    <mat-password-strength #passwordComponentWithValidation [password]="passwordWithValidation.value" class="green"
      [min]="8" [max]="15" [enableLowerCaseLetterRule]="false">
    </mat-password-strength>

    <!--Password's strength info-->
    <mat-password-strength-info [passwordComponent]="passwordComponentWithValidation"></mat-password-strength-info>

    <mat-grid-list cols="2" rowHeight="37px">
      <mat-grid-tile>
        <button type="button" mat-flat-button class="cancelBtn" color="warn" (click)="closeModal()">
          Cancel
        </button>
      </mat-grid-tile>
      <mat-grid-tile>
        <button [disabled]="gisuserForm.invalid" mat-flat-button class="saveBtn">
          Create
          <mat-spinner *ngIf="isLoading" class="custom-spinner" diameter="20"></mat-spinner>
        </button>
      </mat-grid-tile>
    </mat-grid-list>
  </form>
</div>
