<div class="structure">
  <div class="flex">
    <div class="structure_modify">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Modify Address Form</mat-card-title>
          <mat-card-subtitle>Set the fieldname and fieldtype</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <form class="form-horizontal" class="structure_modify_form">
            <div class="structure_modify_form_div">
              <mat-form-field>
                <mat-label> Field Name </mat-label>
                <input value="Geocoding Id" matInput readonly />
              </mat-form-field>

              <mat-form-field>
                <mat-label> Field Type </mat-label>
                <input value="Text" matInput readonly />
              </mat-form-field>

              <mat-form-field>
                <mat-label> Field Required </mat-label>
                <input value="True" matInput readonly />
              </mat-form-field>
            </div>

            <div class="structure_modify_form_div">
              <mat-form-field>
                <mat-label> Field Name </mat-label>
                <input value="House Number" matInput readonly />
              </mat-form-field>
              <mat-form-field>
                <mat-label> Field Type </mat-label>
                <input value="Text" matInput readonly />
              </mat-form-field>
              <mat-form-field>
                <mat-label> Field Required </mat-label>
                <input value="True" matInput readonly />
              </mat-form-field>
            </div>

            <div class="structure_modify_form_div">
              <mat-form-field>
                <mat-label> Field Name </mat-label>
                <input value="Street Name" matInput readonly />
              </mat-form-field>
              <mat-form-field>
                <mat-label> Field Type </mat-label>
                <input value="Text" matInput readonly />
              </mat-form-field>
              <mat-form-field>
                <mat-label> Field Required </mat-label>
                <input value="True" matInput readonly />
              </mat-form-field>
            </div>

            <div class="structure_modify_form_div">
              <mat-form-field>
                <mat-label> Field Name </mat-label>
                <input value="Area Name" matInput readonly />
              </mat-form-field>

              <mat-form-field>
                <mat-label> Field Type </mat-label>
                <input value="Text" matInput readonly />
              </mat-form-field>
              <mat-form-field>
                <mat-label> Field Required </mat-label>
                <input value="True" matInput readonly />
              </mat-form-field>
            </div>

            <div class="structure_modify_form_div">
              <mat-form-field>
                <mat-label> Field Name </mat-label>
                <input value="State" matInput readonly />
              </mat-form-field>

              <mat-form-field>
                <mat-label> Field Type </mat-label>
                <input value="Text" matInput readonly />
              </mat-form-field>

              <mat-form-field>
                <mat-label> Field Required </mat-label>
                <input value="True" matInput readonly />
              </mat-form-field>
            </div>

            <div class="structure_modify_form_div">
              <mat-form-field>
                <mat-label> Field Name </mat-label>
                <input value="L.G.A" matInput readonly />
              </mat-form-field>

              <mat-form-field>
                <mat-label> Field Type </mat-label>
                <input value="Text" matInput readonly />
              </mat-form-field>

              <mat-form-field>
                <mat-label> Field Required </mat-label>
                <input value="True" matInput readonly />
              </mat-form-field>
            </div>

            <div *ngIf="extraFieldDiv" class="structure_modify_form_div">
              <h3 class="optional">Extra Fields</h3>
            </div>

            <ng-container class="fieldContainer" *ngFor="let item of dynamicForm">
              <div class="structure_modify_form_div">
                <mat-form-field>
                  <mat-label> Field Name </mat-label>
                  <input value="{{ item.fieldName }}" matInput readonly />
                </mat-form-field>

                <mat-form-field>
                  <mat-label> Field Type </mat-label>
                  <input value="{{ item.fieldType }}" matInput readonly />
                </mat-form-field>
                <mat-form-field>
                  <mat-label> Field Required </mat-label>
                  <input value="{{ item.required }}" matInput readonly />
                </mat-form-field>
                <button type="button" style="color: red; font-size: 1rem" mat-icon-button class="float-right"
                  (click)="removeField(item.id)">
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </div>

              <ng-container *ngIf="
                  item.fieldType == 'select' ||
                  item.fieldType == 'check' ||
                  item.fieldType == 'radio'
                ">
                <div class="option-container" style="
                    align-items: center !important;
                    display: flex;
                    justify-content: center;
                  ">
                  <div class="optional">
                    <h4>Field Options</h4>
                  </div>
                  <div>
                    <div *ngFor="let opt of item.options">
                      <div class="structure_modify_form_div">
                        <mat-form-field>
                          <mat-label> Options Name </mat-label>
                          <input value="{{ opt.value }}" matInput readonly />
                        </mat-form-field>
                        <button type="button" style="color: red; font-size: 1rem" mat-icon-button class="float-right"
                          (click)="removeOption(opt.id)">
                          <mat-icon>delete_outline</mat-icon>
                        </button>
                      </div>
                    </div>
                    <button mat-flat-button type="button" style="background-color: #093256; color: #ffffff"
                      (click)="openOptionModal(item.id)">
                      Add Option
                    </button>
                  </div>
                </div>
              </ng-container>
            </ng-container>

            <div class="button">
              <button mat-mini-fab style="background-color: #093256; float: right" (click)="openFieldModal()"
                mat-icon-button color="primary" aria-label="add address field">
                <mat-icon>add</mat-icon>
              </button>
            </div>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
