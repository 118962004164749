<div class="container">
  <mat-card class="card">
    <mat-card-header>
      <mat-card-title>Address Management</mat-card-title>
    </mat-card-header>
    <mat-divider></mat-divider>
    <mat-card-content>
      <div class="div">
        <div class="left">
          <button (click)="assignAddressModal()" *ngIf="showAssignBtn" class="assign-address-btn" mat-button>
            <mat-icon>send</mat-icon>
            <span>&nbsp; Assign </span>
          </button>
        </div>

        <div class="right">
          <form [formGroup]="addressForm" (ngSubmit)="onAddressFormSubmit(addressForm.value)" class="div_filter">
            <mat-form-field style="width: 10.5rem !important">
              <mat-label>Status</mat-label>
              <mat-select name="status" formControlName="status" (selectionChange)="statusChange($event.value)">
                <mat-option *ngFor="let status of statusOptions" [value]="status">{{ status.name }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Enter a date range</mat-label>
              <mat-date-range-input [rangePicker]="picker" disabled>
                <input matStartDate formControlName="start" placeholder="Start date" />
                <input matEndDate formControlName="end" placeholder="End date" />
              </mat-date-range-input>
              <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker color="primary" disabled="false"></mat-date-range-picker>

              <mat-error *ngIf="
                  addressForm.controls.start.hasError('matStartDateInvalid')
                ">Invalid start date
              </mat-error>
              <mat-error *ngIf="addressForm.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>

              <button type="button" mat-icon-button matSuffix (click)="clearAllFilter2()">
                <mat-icon aria-hidden="false" aria-label="Clear filter" color="warn">backspace</mat-icon>
              </button>
            </mat-form-field>

            <button type="submit" [disabled]="!addressForm.valid" style="margin-left: 1rem" class="assign-address-btn"
              mat-button>
              <mat-icon>search</mat-icon>
            </button>

            <button type="button" [disabled]="!addressForm.valid" (click)="downloadAddress()" style="margin-left: 1rem;"
              class="assign-address-btn" mat-button>
              <ng-container *ngIf="downloadLoader; else elseTemplate">
                <mat-spinner class="custom-spinner" diameter="20"></mat-spinner>
              </ng-container>
              <ng-template #elseTemplate>
                <mat-icon>get_app</mat-icon>
              </ng-template>
            </button>
          </form>
        </div>

        <div class="center">
          <mat-form-field class="div_search-field">
            <mat-label>
              Search here....... <mat-icon>search</mat-icon>
            </mat-label>
            <input matInput placeholder="Search for an address" [formControl]="searchControl">
            <button type="button" mat-icon-button matSuffix (click)="clearAllFilter()">
              <mat-icon aria-hidden="false" aria-label="Clear filter" color="warn">backspace</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>

      <!-- start Address Table Here  -->
      <div class="address-container mat-elevation-z8">
        <div class="table-loading-shade" *ngIf="isLoadingResults$ | async">
          <mat-spinner class="custom-spinner"></mat-spinner>
        </div>

        <div class="address-table-container">
          <table mat-table [dataSource]="addresses" matSort matSortActive matSortDisableClear matSortDirection="desc">
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <!-- <mat-checkbox color="primary" (change)="$event ? masterToggle($event) : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()">
                </mat-checkbox> -->
              </th>
              <td class="userList-td" mat-cell *matCellDef="let row">
                <ng-container *ngIf="isClientAdmin; else elseTemplate">
                  <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="
                    $event ? singleToggle(row.id, $event, row.status) : null
                  " [checked]="selection.isSelected(row)"
                    [disabled]="row.status !== 1 && row.status !== 3 && row.status !== 2 ? true : false">
                  </mat-checkbox>

                </ng-container>
                <ng-template #elseTemplate>
                  <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="
                    $event ? singleToggle(row.id, $event, row.status) : null
                  " [checked]="selection.isSelected(row)"
                    [disabled]="row.status !== 1 && row.status !== 3 ? true : false">
                  </mat-checkbox>
                </ng-template>
              </td>
            </ng-container>

            <!-- <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null"
                              [checked]="selection.hasValue() && isAllSelected()"
                              [indeterminate]="selection.hasValue() && !isAllSelected()"
                              [aria-label]="checkboxLabel()">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()"
                              (change)="$event ? selection.toggle(row) : null"
                              [checked]="selection.isSelected(row)"
                              [aria-label]="checkboxLabel(row)">
                </mat-checkbox>
              </td>
            </ng-container> -->

            <ng-container matColumnDef="geocodingId">
              <th mat-header-cell *matHeaderCellDef>Geocoding Id</th>
              <td mat-cell *matCellDef="let row">{{ row.geocodingId }}</td>
            </ng-container>

            <!-- <ng-container matColumnDef="customername">
              <th mat-header-cell *matHeaderCellDef> Customer Name </th>
              <td mat-cell *matCellDef="let row"> {{row.customername}} </td>
            </ng-container> -->

            <ng-container matColumnDef="housenumber">
              <th mat-header-cell *matHeaderCellDef>House Number</th>
              <td mat-cell *matCellDef="let row">{{ row.housenumber }}</td>
            </ng-container>

            <ng-container matColumnDef="streetname">
              <th mat-header-cell *matHeaderCellDef>Street Name</th>
              <td mat-cell *matCellDef="let row">{{ row.streetname }}</td>
            </ng-container>

            <ng-container matColumnDef="areaname">
              <th mat-header-cell *matHeaderCellDef>Area</th>
              <td mat-cell *matCellDef="let row">{{ row.areaname }}</td>
            </ng-container>

            <ng-container matColumnDef="lga">
              <th mat-header-cell *matHeaderCellDef>LGA</th>
              <td mat-cell *matCellDef="let row">{{ row.lga }}</td>
            </ng-container>

            <ng-container matColumnDef="state">
              <th mat-header-cell *matHeaderCellDef>State</th>
              <td mat-cell *matCellDef="let row">{{ row.state }}</td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>Status</th>
              <td mat-cell *matCellDef="let row">
                <mat-icon *ngIf="row.status=== 3" style="color:#003796ce;">engineering</mat-icon>
                <mat-icon *ngIf="row.status=== 2" style="color: rgb(32, 165, 19);">verified</mat-icon>
                <mat-icon *ngIf="row.status=== 0" style="color: rgba(255, 68, 0, 0.87);">error</mat-icon>
                <mat-icon *ngIf="row.status=== 1" style="color: red;">unpublished</mat-icon>
              </td>
            </ng-container>

            <ng-container *ngIf="showFiledworker" matColumnDef="fieldStaff">
              <th mat-header-cell *matHeaderCellDef>Field Staff Name</th>
              <td mat-cell *matCellDef="let row">{{ row.fieldworkerName }}</td>
            </ng-container>

            <ng-container matColumnDef="uploadedDate">
              <th mat-header-cell *matHeaderCellDef>Uploaded Date</th>
              <td mat-cell *matCellDef="let row">{{ row.uploadedDate }}</td>
            </ng-container>

            <ng-container matColumnDef="geocodedDate">
              <th mat-header-cell *matHeaderCellDef>Geocoded Date</th>
              <td mat-cell *matCellDef="let row">{{ row.geocodedDate }}</td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>Actions</th>
              <td mat-cell *matCellDef="let row">
                <mat-menu style="padding: 0;" #appMenu="matMenu">

                  <button mat-menu-item (click)="updateAddress(row)" class="userActionTableBtn">
                    Update Address
                  </button>

                  <button *ngIf="isClientAdmin && row.status === 2" mat-menu-item (click)="duplicateAddress(row.id)"
                    class="userActionTableBtn">
                    Duplicate Address
                  </button>

                  <button (click)="viewAddressOnMap(row)" mat-menu-item class="userActionTableBtn">
                    View on Map
                  </button>

                  <button *ngIf="isClientAdmin" (click)="viewAddressLog(row)" mat-menu-item class="userActionTableBtn">
                    View Address Log
                  </button>

                  <button *ngIf="isClientAdmin" (click)="archieveAddress(row.id)" mat-menu-item
                    class="userActionTableBtn">
                    Archive Address
                  </button>
                </mat-menu>

                <button mat-icon-button color="primary" [matMenuTriggerFor]="appMenu">
                  <mat-icon>more_vert</mat-icon>
                </button>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>

        <mat-paginator [length]="totalAddressCount" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
          showFirstLastButtons>
        </mat-paginator>
      </div>
      <!--Address Table Ends Here  -->
    </mat-card-content>
  </mat-card>
</div>
