<h1>New Field Creation </h1>
<mat-divider></mat-divider>

<form [formGroup]="extraField" class='form'>

  <div class='form_field'>
    <label>Field Name</label><br>
    <input placeholder='Field Name' formControlName="fieldName" required/>

  </div>

  <div class='form_field'>
    <label>Required</label><br>
    <select formControlName="required" required>
      <option>-Select-</option>
      <option value='true'>
        True
      </option>
      <option value='false'>
        False
      </option>
    </select>
  </div>

  <div class='form_field'>
    <label>Type</label><br>
     <select formControlName="fieldType"required>
       <option>-Select Type-</option>
        <option *ngFor="let type of typesArray" [value]='type'>
          {{type}}
        </option>
      </select>
  </div>
  <div *ngIf="showOptionField(extraField)">
    <button mat-raised-button type="button" (click)="addOption()">Add Option </button>

  <div formArrayName="options">
    <div *ngFor="let item of options.controls; let pointIndex=index" [formGroupName]="pointIndex">
    <label>
      <input class='optionField' formControlName="value" placeholder="Enter option"/>
    </label>
    <button type="button" style="color: red; font-size: 1rem" mat-icon-button (click)="deleteOption(pointIndex)">
        <mat-icon>close</mat-icon>
    </button>
    </div>

  </div>
</div>
  <div class="button">
              <button type="submit " class="address-structure-submit-btn"
                style="background-color: #093256; color: #f1f1f1; float:right" mat-button
                (click)="onSubmit()"
                [disabled]='disabled'>
                Submit Structure
                <mat-spinner *ngIf="loading" class="custom-spinner" diameter="20"></mat-spinner>
              </button>
              </div>
</form>
<!-- {{ this.extraField.value | json }} -->
