import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroupDirective,
  NgForm,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { FieldworkerService } from 'src/app/shared/services/userService/fieldworker.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && (control.dirty || control.touched));
  }
}

@Component({
  selector: 'app-create-fieldworker',
  templateUrl: './create-fieldworker.component.html',
  styleUrls: ['./create-fieldworker.component.scss'],
})
export class CreateFieldworkerComponent implements OnInit {

  matcher = new MyErrorStateMatcher();

  fieldworkerForm = this.fb.group(
    {
      fullname: ['', [Validators.required, Validators.pattern(/^[a-zA-Z ]+$/)]],
      username: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9]*$/)]],
      password: [null, [Validators.required, this.patternValidator()
      ]]
    }
  );
  hidePassword = true;
  hideConfirmPassword = true;
  isLoading;

  // auto generate password helpers
  dictionary: Array<string>;
  passwordLenght = 15;
  buttonLabel = 'Generate';
  newPassword: string;
  lowercase = true;
  uppercase = true;
  numbers = true;
  symbols = true;
  passwordXters = [
    {
      library: 'abcdefghijklmnopqrstuvwxyz',
    }, {
      library: 'ABCDEFGHIJKLMNOPWRSTUVWXYZ',
    }, {
      library: '0123456789',
    }, {
      library: '!@#$%^&*-_=+\\|:;\',.\<>/?~',
    }
  ];
  // Copy password to clipboard
  @ViewChild('passwordWithValidation') password: ElementRef;
  private copyPassword() {
    const inputElement = this.password.nativeElement as HTMLInputElement;
    inputElement.select();
    document.execCommand('copy');
  }

  constructor(
    public dialog: MatDialogRef<CreateFieldworkerComponent>,
    private fb: FormBuilder,
    private fService: FieldworkerService,
    public toastr: ToastrService
  ) {}

  ngOnInit(): void {}

  patternValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const regex = new RegExp(
        '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*[@#$%!~^&*()_+=?<>,.]).{8,}$'
      );
      const valid = regex.test(control.value);
      return valid ? null : { invalidPassword: true };
    };
  }

  onCreatefieldworker(): void {
    this.isLoading = true;
    this.fService.createAgent(this.fieldworkerForm.value).subscribe(
      (res: any) => {
        // console.log(res);
        this.isLoading = false;
        if (res.status === 0) {
          this.dialog.close();
          this.toastr.success(res.message);
        } else if (res.status === 1) {
          this.toastr.warning(res.message);
        } else{
          this.toastr.error(res.message);
        }
      },
      (error) => {
        this.isLoading = false;
        // console.log(error);
      }
    );
  }

  closeModal(): void {
    this.dialog.close();
  }

   // Generate password
   public generatePassword() {
    if (this.lowercase === false && this.uppercase === false && this.numbers === false && this.symbols === false) {
      return this.newPassword = '...';
    }

    // Create array from chosen checkboxes
    this.dictionary = [].concat(
      this.lowercase ? this.passwordXters[0].library.split('') : [],
      this.uppercase ? this.passwordXters[1].library.split('') : [],
      this.numbers ? this.passwordXters[2].library.split('') : [],
      this.symbols ? this.passwordXters[3].library.split('') : []
    );

    // Generate random password from array
    let newPassword = '';
    for (let i = 0; i < this.passwordLenght; i++) {
      newPassword += this.dictionary[Math.floor(Math.random() * this.dictionary.length)];
    }

    this.newPassword = newPassword;
    this.fieldworkerForm.patchValue({
      password: this.newPassword
    });

    console.log(this.fieldworkerForm.value);

    // Call copy function
    setTimeout(() => this.copyPassword());
    console.log(this.newPassword);

    // Change text on button when clicked
    this.buttonLabel = 'Copied!';
    setTimeout(() => { this.buttonLabel = 'Generate'; }, 1500);
  }
}

// export class PasswordValidation {
//   // tslint:disable-next-line: typedef
//   static MatchPassword(AC: AbstractControl) {
//     const password = AC.get('password').value;
//     if (AC.get('confirmPassword').touched || AC.get('confirmPassword').dirty) {
//       const verifyPassword = AC.get('confirmPassword').value;

//       if (password !== verifyPassword) {
//         AC.get('confirmPassword').setErrors({ MatchPassword: true });
//       } else {
//         return null;
//       }
//     }
//   }
// }
