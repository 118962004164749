import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog} from '@angular/material/dialog';
import { BehaviorSubject, merge, Observable, of as observableOf, Subscription } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { ConfirmationComponent } from '../../confirmation/confirmation.component';
import { userhttpResModel} from 'src/app/shared/models/user.model';
import { SubUserService } from 'src/app/shared/services/userService/sub-user.service';
import { FieldworkerService } from 'src/app/shared/services/userService/fieldworker.service';
import { CreateUserComponent } from './create-user/create-user.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { FormControl } from '@angular/forms';


@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  displayedColumns: string[] = ['fullname', 'username', 'email', 'status', 'action'];
  isLoadingResults$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  subUser: Observable<userhttpResModel[]>;

  sortSub$: Subscription;

  searchControl: FormControl = new FormControl('');
  search = '';
  status = -1;

  statusOptions = [
    { code: -1, name: 'All' },
    { code: 2, name: 'Activated' },
    { code: 0, name: 'Deactivated' }
  ];

  pageSize = 10;
  pageSizeOptions: number[] = [10, 20, 30, 50, 100];
  totalStaffCount;

  constructor(
    public dialog: MatDialog,
    private uService: SubUserService,
    private toaster: ToastrService,
    private fService: FieldworkerService
    ) { }

  ngOnInit(): void {
    this.subUser = this.uService.getSubUsers();

    this.searchControl.valueChanges.subscribe((value) => {
      // console.log(value);
      this.search = value;
      this.paginator.page.emit();
    });
  }

  private updateLoadingStatus(status: boolean) {
    this.isLoadingResults$.next(status);
  }

  ngAfterViewInit() {

    // If the user changes the sort order, reset back to the first page.
    this.sortSub$ = this.sort.sortChange.subscribe(
      () => (this.paginator.pageIndex = 0)
    );

    merge(this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.updateLoadingStatus(true);
          return this.uService.getAllSubUsers(
            this.paginator.pageIndex,
            this.paginator.pageSize,
            this.search,
            this.status
          );
        })
      )
      .subscribe(res => {
        // console.log(res);
        this.uService.updateUser(res.users);
        this.totalStaffCount = res.count;
        this.updateLoadingStatus(false);
      },
      (error) => {
        // console.log(error);
      }
    );
  }

  updateUserTable() {
    this.uService
      .getAllSubUsers(this.paginator.pageIndex, this.paginator.pageSize, this.search, this.status)
      .subscribe(
        (res: any) => {
          this.uService.updateUser(res.users);
          this.totalStaffCount = res.count;
          this.updateLoadingStatus(false);
        },
        (error) => {
          this.updateLoadingStatus(false);
          // console.log(error);
        }
      );
  }

  changeToggle(id: number, status: string) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: { action: `${status} Sub User` },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'cancel') {
      } else if ('ok') {
        this.fService.toggleActivation(id, 'subUser').subscribe(
          (res: { status: number; message: string }) => {
            if (res.status === 0) {
              this.toaster.success(res.message);
              this.updateUserTable();
            } else {
              this.toaster.warning(res.message);
            }
          },
          (error) => {
            this.toaster.error(error, 'error');
          }
        );
      }
    });
  }

  createUserModal(): void {
    const dialogRef = this.dialog.open(CreateUserComponent, {
      minWidth: '18rem',
      maxWidth: '25rem',
      minHeight: '17rem'
      // data: {id, name}
    });
    dialogRef.afterClosed().subscribe((value) => {
      this.updateUserTable();
    })
  }

  changePassword(id, name, user_role): void {
    // console.log(id, name);
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      minWidth: '18rem',
      maxWidth: '25rem',
      minHeight: '17rem',
      data: {id, name, user_role}
    });
  }

  statusChange(status): void{
   this.status = status;
   this.updateUserTable();
  }

  clearAllFilter() {
    this.search = '';
    this.paginator.page.emit();
    this.searchControl.setValue('');
  }
}
