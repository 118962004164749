import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import {
  AddresshttpResModel,
  GisAddresshttpResModel,
} from '../../models/user.model';
@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  constructor(private http: HttpClient) {}

  private addresses$: BehaviorSubject<
    AddresshttpResModel[]
  > = new BehaviorSubject([]);

  private GisAddresses: BehaviorSubject<
    GisAddresshttpResModel[]
  > = new BehaviorSubject([]);

  // private addresses$: BehaviorSubject<
  //   AddresshttpResModel[]
  // > = new BehaviorSubject([]);

  getGisAddresses() {
    return this.GisAddresses.asObservable();
  }

  // tslint:disable-next-line: typedef
  getAddresses() {
    return this.addresses$.asObservable();
  }

  getArchives() {
    const url = `${environment.apiUrl}archive`;
    return this.http.get<any>(url, {});
  }

  // tslint:disable-next-line: typedef
  updateGisAddressList(data: GisAddresshttpResModel[]) {
    this.GisAddresses.next(data);
  }

  updateAddressList(data: AddresshttpResModel[]) {
    this.addresses$.next(data);
  }

  fetchGisAddresses(clientId, page, size, query) {

    const url = `${environment.apiUrl}gis/addresses?client_id=${clientId}&size=${size}&page=${page}&query=${query}`;
    return this.http.get(url).pipe(
      map((res: any) => {
        if (res.status === 0) {
          return res;
        } else {
          return of([]);
        }
      })
    );
  }

  gisConfirmation(status: number, addressIds: number[]) {
    const requestUrl = `${environment.apiUrl}manually/verify-set-of-addresses?status=${status}&address_ids=${addressIds}`;
    return this.http.post(requestUrl, {});
  }

  downloadPendingExcelFile(start, end, clientId) {
    const url = `${environment.apiUrl}gis/addresses-download?from=${start}&to=${end}&clientId=${clientId}`;
    return this.http.get(url, {
      responseType: 'blob',
      observe: 'response',
    });
  }

  fetchAddresses(
    page: number,
    pageSize: number,
    key: number,
    status: number,
    term: string,
    tat: string,
    start: string,
    end: string
  ) {
    const url = `${environment.apiUrl}addresses?page=${page}&size=${pageSize}&status=${status}&query=${term}&outsideTat=${tat}&fromDate=${start}&toDate=${end}&geocodedBy=${key}`;
    return this.http.get(url).pipe(
      map((res: any) => {
        if (res.status === 0) {
          return res;
        } else {
          return of([]);
        }
      })
    );
  }

  fetchArchives(page: number, pageSize: number, term: string) {

    const url = `${environment.apiUrl}archive?page=${page}&size=${pageSize}&query=${term}`;

    return this.http.get(url).pipe(
      map((res: any) => {
        if (res.status === 0) {
          return res;
        } else {
          return of([]);
        }
      })
    );
  }

  // tslint:disable-next-line: typedef
  singleAddressUpload(detail) {
    const url = `${environment.apiUrl}single/upload?areaname=${detail.areaName}&customername=${detail.customerName}&housenumber=${detail.houseNumber}&lga=${detail.lga}&geocodingId=${detail.transactionName}&state=${detail.state}&streetname=${detail.streetName}`;
    return this.http.post(url, {});
  }

  // tslint:disable-next-line: typedef
  multipleAddressUpload(file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    const url = `${environment.apiUrl}multiple/upload`;
    return this.http.post(url, formData);
  }

  // tslint:disable-next-line: typedef
  assignAddress(fieldWorkerId: number, addressIds: number[]) {
    const requestUrl = `${environment.apiUrl}assign-to-fieldworker?fieldworker_id=${fieldWorkerId}&addresses=${addressIds}`;
    return this.http.post(requestUrl, {});
  }

  // tslint:disable-next-line: typedef
  downloadExcelFile(start, end, status, geocodedBy) {
    const url = `${environment.apiUrl}address/report/download?from=${start}&to=${end}&status=${status}&geocodedBy=${geocodedBy}`;
    return this.http.get(url, {
      responseType: 'blob',
      observe: 'response',
    });
  }

  // tslint:disable-next-line: typedef
  downloadFile() {
    const url = `${environment.apiUrl}fetchAddressTemplate`;
    return this.http.get(url, {
      responseType: 'blob',
      observe: 'response',
    });
  }

  public formatDate(date: Date): string {
    const day = `${
      +date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`
    }`;

    const fullYear = date.getFullYear();
    const month = `${
      +date.getMonth() + 1 < 10
        ? `0${+date.getMonth() + 1}`
        : `${+date.getMonth() + 1}`
    }`;
    return [fullYear, month, day].join('-');
  }

  getFieldWorkerReport(id: number) {
    const url = `${environment.apiUrl}fieldworker-report?fieldworker_id=${id}`;
    return this.http.get<any>(url, {});
  }

  getCounts() {
    const url = `${environment.apiUrl}address-count`;
    return this.http.get<any>(url, {});
  }

  getOutsideTatCounts() {
    const url = `${environment.apiUrl}outside-tat`;
    return this.http.get<any>(url, {});
  }

  getPieData(state, lga) {
    const url = `${environment.apiUrl}address-pie-chart?state=${state}&lga=${lga}`;
    return this.http.get<any>(url, {});
  }

  getLineData(state) {
    const url = `${environment.apiUrl}address-coverage?state=${state}`;
    return this.http.get<any>(url, {});
  }

  getTimeOutData() {
    const url = `${environment.apiUrl}allocation`;
    return this.http.get<any>(url, {});
  }

  updateAddress(addressData: any) {
    const url = `${environment.apiUrl}update-address?address_id=${addressData.id}&housenumber=${addressData.houseNumber}&streetname=${addressData.street}&areaname=${addressData.area}&lga=${addressData.lga}&state=${addressData.state}`;
    return this.http.put<any>(url, {});
  }

  duplicateAddress(addressData: any) {
    const url = `${environment.apiUrl}duplicate-address?address_id=${addressData.address_id}&newGeocodingId=${addressData.newGeocodingId}`;
    return this.http.put<any>(url, {});
  }

  getState() {
    const url = `${environment.apiUrl}state`;
    return this.http.get<any>(url);
  }

  getLga(state) {
    const url = `${environment.apiUrl}lga?state=${state}`;
    return this.http.get<any>(url);
  }

  archieveAddress(addressId: number) {
    const url = `${environment.apiUrl}archive-address?address_id=${addressId}`;
    return this.http.post<any>(url, {});
  }

  getAddressLog(id) {
    const url = `${environment.apiUrl}log/address?id=${id}`;
    return this.http.get<any>(url);
  }
}
