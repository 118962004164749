import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-streetview',
  template: `<div class="mat-elevation-z8" #panoramaDiv></div>`,
  styleUrls: ['./streetview.component.scss'],
})
export class StreetviewComponent implements OnInit, AfterViewInit {
  @ViewChild('panoramaDiv', { static: true })
  panoramaContainer: ElementRef<HTMLDivElement>;
  panorama: google.maps.StreetViewPanorama;
  panoromaOptions: google.maps.StreetViewPanoramaOptions = {
    addressControl: false,
    visible: false,
  };
  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.initPanorama();
  }

  private initPanorama(): void {
    this.panorama = new google.maps.StreetViewPanorama(
      this.panoramaContainer.nativeElement,
      this.panoromaOptions
    );
  }
}
