import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularMaterialModule } from 'src/app/shared/vendors/angular-material-modules';
import { DashboardComponent } from 'src/app/views/dashboard/dashboard.component';
import { DashboardRoutingModule } from './dashboard-routing.module';

@NgModule({
    declarations: [
        DashboardComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        AngularMaterialModule,
        ReactiveFormsModule,
        DashboardRoutingModule
    ]
})
export class DashboardModule {}
