import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { AuthenticationService } from '@app/shared/services/authService/authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService, private toaster: ToastrService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // return next.handle(request).pipe(catchError(err => {
        //     if ([401, 403].includes(err.status) && this.authenticationService.userValue) {
        //         // auto logout if 401 or 403 response returned from api
        //         this.authenticationService.logout();

        //     }
        //     const error = (err && err.error && err.error.message) || err.statusText;
        //     console.error(err);
        //     return throwError(error);

        return next.handle(request).pipe(catchError(err => {
          if ([401, 403].indexOf(err.status) !== -1) {
              // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
              this.authenticationService.logout();
          }

          const error = err.error.message || err.statusText;
          return throwError(error);
        }));
    }
}
