<div class="container">
    <div class="label">
      <h3><b>CHANGE TOKEN</b></h3>
    </div>
  
    <div class="form-group">
      <form class="" [formGroup]="changeTokenForm" #f="ngForm">
  
         <!--old password input filed-->
        <mat-form-field appearance="outline">
          <mat-label>Token</mat-label>
          <input matInput type='text' 
          readonly
          formControlName="token"
            name="Token" 
            [value]='tokenValue' />
        </mat-form-field>
        <div *ngIf='hidePassword'>
          <small>Please enter your password to change token</small>
        <mat-form-field appearance="outline">
          <mat-label>Password</mat-label>
          <input matInput
          [type]="password ? 'password' : 'text'"
          type='password' 
          formControlName="password"
            name="password" 
            [value]='passwordValue'
             required/>
             <button type="button" mat-icon-button matSuffix (click)="password = !password"
          [attr.aria-label]="'Hide password'" [attr.aria-pressed]="password">
          <mat-icon>{{
            password ? "visibility_off" : "visibility"
          }}</mat-icon>
        </button>
        </mat-form-field>
        </div>
        
  
        <!--Button-->
        <br>
        <mat-grid-list cols="2" rowHeight="37px">
          <mat-grid-tile>
            <button type="button" mat-flat-button class="cancelBtn" color="warn" (click)="closeModal()">
              Cancel
            </button>
          </mat-grid-tile>
          <mat-grid-tile>
            <button mat-flat-button class="saveBtn" 
            (click)='confirmwithPassword()'
            [disabled]='changeTokenForm.invalid'>
              Change
              <mat-spinner *ngIf="isLoading" class="custom-spinner" diameter="20"></mat-spinner>
            </button>
          </mat-grid-tile>
        </mat-grid-list>
      </form>
    </div>
  </div>
