import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroupDirective,
  NgForm,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Router } from '@angular/router';
import { SubUserService } from '@app/shared/services/userService/sub-user.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmPasswordValidator } from '../reset-password/confirm-password.validator';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    return !!(control && control.invalid && (control.dirty || control.touched));
  }
}

@Component({
  selector: 'app-change-password-firstime',
  templateUrl: './change-password-firstime.component.html',
  styleUrls: ['./change-password-firstime.component.scss'],
})
export class ChangePasswordFirstimeComponent implements OnInit {
  matcher = new MyErrorStateMatcher();

  changePasswordForm = this.fb.group(
    {
      oldPassword: ['', Validators.required],
      password: [null, [Validators.required, this.patternValidator()]],
      confirmPassword: ['', Validators.required],
    },
    {
      validator: ConfirmPasswordValidator('password', 'confirmPassword'),
    }
  );

  hideOldPassword = true;
  hideConfirmPassword = true;
  isLoading;

  constructor(
    private fb: FormBuilder,
    public toastr: ToastrService,
    public userService: SubUserService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  patternValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const regex = new RegExp(
        '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*[@#$%!~^&*()_+=?<>,.]).{8,}$'
      );
      const valid = regex.test(control.value);
      return valid ? null : { invalidPassword: true };
    };
  }

  getErrorMessage() {
    if (this.changePasswordForm.get('confirmPassword').hasError('required')) {
      return 'Confirm Password is required';
    }else{
    return this.changePasswordForm.get('confirmPassword').errors?.confirmPasswordValidator ? 'New Password and Confirm Password didn\'t match': ' ';
    }
  }

  passwordChange(): void {
    // console.log(this.changePasswordForm.value);
    this.isLoading = true;
    this.userService
      .changeProfilePassword(this.changePasswordForm.value)
      .subscribe(
        (res: any) => {
          this.isLoading = false;
          if (res.status === 0) {
            this.toastr.success(res.message, 'Success');
            this.router.navigate(['/map']);
          } else {
            this.toastr.warning(res.message);
          }
        },
        (error) => {
          this.isLoading = false;
          this.toastr.error(error, 'Failed');
        }
      );
  }
}
