<div class="container">
  <form (ngSubmit)="onUpdateAddress()" class="create-fieldworker-form" [formGroup]="addressUpdateForm" #f="ngForm">

    <mat-form-field appearance="outline">
      <mat-label>Geocoding Id</mat-label>
      <input matInput formControlName="geocodingId" placeholder="Geocoding Id" readonly>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>House Number</mat-label>
      <input matInput formControlName="houseNumber" placeholder="House Number">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Street Name</mat-label>
      <input matInput formControlName="street" placeholder="Street Name" required>
      <mat-error *ngIf="addressUpdateForm.get('street').hasError('required')"> Enter Street Name</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Area</mat-label>
      <input matInput formControlName="area" placeholder="Area" required>
      <mat-error *ngIf="addressUpdateForm.get('area').hasError('required')">Enter Area</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>LGA</mat-label>
      <input matInput formControlName="lga" placeholder="lga" required>
      <mat-error *ngIf="addressUpdateForm.get('lga').hasError('required')">Enter Local Government Area</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>State</mat-label>
      <input matInput formControlName="state" placeholder="State" required>
      <mat-error *ngIf="addressUpdateForm.get('state').hasError('required')">Enter State</mat-error>
    </mat-form-field>

    <mat-grid-list cols="2" rowHeight="37px">
      <mat-grid-tile>
        <button type="button" mat-flat-button class="cancelBtn" color="warn" (click)="closeModal('yes')">CANCEL</button>
      </mat-grid-tile>
      <mat-grid-tile>
        <button [disabled]="addressUpdateForm.invalid" mat-flat-button class="saveBtn">UPDATE
          <mat-spinner *ngIf="isLoading" class="custom-spinner" diameter="20"></mat-spinner>
        </button>
      </mat-grid-tile>
    </mat-grid-list>

  </form>
</div>
