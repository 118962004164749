import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { saveAs } from 'file-saver';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ToastrService } from 'ngx-toastr';
import { ExportType, ExcelOptions } from 'mat-table-exporter';

import { ProjectService } from '../../shared/services/projectService/project.service';

@Component({
  selector: 'app-address-upload',
  templateUrl: './address-upload.component.html',
  styleUrls: ['./address-upload.component.scss'],
})
export class AddressUploadComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  displayedColumns = [
    'geocodingId',
    'housenumber',
    'streetname',
    'areaname',
    'lga',
    'state',
    'message'
  ];
  failedFile = "Failed Uploaded Addresses";
  options: ExcelOptions = {fileName: this.failedFile, sheet: 'Failed_addresses', Props: {Author: 'Polaris Digitech'}, columnWidths: [6]};
  exportType = ExportType.XLSX;

  singleAddressFormGroup: FormGroup = this.fb.group({
    transactionName: this.fb.control('', Validators.required),
    state: this.fb.control('', Validators.required),
    lga: this.fb.control('', Validators.required),
    houseNumber: this.fb.control(''),
    streetName: this.fb.control('', Validators.required),
    areaName: this.fb.control('', Validators.required),
  });

  constructor(
    private fb: FormBuilder,
    private pService: ProjectService,
    private toastr: ToastrService,
  ) {}

  dataSource;
  failedAddress;
  templateDisable=false;
  templateLoading = false;
  sloading=false;
  dloading=false;
  Ddisable=true;
  submitted = false;
  fileName = 'Template';
  failed=false;



  files: any = [];

  ngOnInit(): void {}

  validateAddressField(fieldName) {
    const ctrl = this.singleAddressFormGroup.get(fieldName);
    return ctrl.touched && ctrl.invalid;
  }

  uploadFile(event) {
    // console.log(event.length);

    if (event.length > 0) {
      this.files = Array.from(event).splice(0, 1);
      this.Ddisable = false;
      // console.log(this.files);
    }
  }

  multipleUpload() {
    const [file] = this.files;
    this.dloading = true;
    this.Ddisable = false;
    this.pService.multipleAddressUpload(file).subscribe(
      (res: any) => {
        // console.log(res);
        this.files.splice(0, 1);
        if (res.status === 0 && res.report.failedCount === 0) {
          this.toastr.success(`${res.report.successCount} addresses successfully uploaded, and ${res.report.failedCount} addresses Failed`);
          this.Ddisable = false;
          this.dloading = false;
        } else if (res.status === 0 && res.report.failedCount >= 1) {
          // console.log(res.report.addresses);
          this.Ddisable = false;
          this.dloading = false;
          this.failed = true;
          this.failedAddress = res.report.addresses;
          this.dataSource = new MatTableDataSource<any>(this.failedAddress);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.toastr.warning(`${res.report.successCount} addresses successfully uploaded, and ${res.report.failedCount} addresses Failed`);
        }
      },
      (err) => {
        this.Ddisable = false;
        this.dloading = false;
        this.toastr.error(err, "Error");
      }
    );
  }

  deleteAttachment(index) {
    this.files.splice(index, 1);
  }

  singleUpload() {
    if (this.singleAddressFormGroup.invalid){
      this.toastr.warning('All fields are required');
      this.singleAddressFormGroup.markAllAsTouched();
    }else{
        // console.log(this.singleAddressFormGroup.value);
      this.sloading = true;
      this.pService.singleAddressUpload(this.singleAddressFormGroup.value).subscribe(
        (res: any) => {
          // console.log(res);
          this.sloading = false;
          if (res.status === 0 ) {
            this.toastr.success(res.message);
            // this.singleAddressFormGroup.reset();
            this.clear(this.singleAddressFormGroup);
          } else{
            this.toastr.error(res.message, 'Error');
          }
        },
        err => {
          // console.log(err);
          this.sloading = false;
          this.toastr.error(err, "Failed");
        }
      );
    }

  }


  clear(form: any): void {
    console.log(form);
    form.reset();
    Object.keys(form.controls).forEach(key =>{
       form.controls[key].setErrors(null)
    });
  }

  download() {
    this.templateDisable=true;
    this.templateLoading = true;
    this.pService.downloadFile().subscribe(
      (res: any) => {
        // console.log(res);
        if (res['status'] === 200) {
          this.toastr.success('Template downloaded successfully');
          // this.Sdisable = true;
          const file = new File([res.body], this.fileName + '.xlsx', {
            type: 'application/vnd.ms-excel;charset=utf-8',
          });
          saveAs(file, 'template.xlsx');
          this.templateDisable= false;
          this.templateLoading = false;
        } else {
          this.toastr.error(res.message, 'Error');
          this.templateDisable= false;
          this.templateLoading = false;
          // this.Sdisable = false;
        }
      },
      (err) => {
        // this.Sdisable = false;
        this.toastr.error(err, 'Failed!');
        this.templateDisable= false;
          this.templateLoading = false;
      }
    );
  }

}
