import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, NgForm, ValidatorFn, AbstractControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SubUserService } from 'src/app/shared/services/userService/sub-user.service';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
})
export class CreateUserComponent implements OnInit {
  disable;
  loading;
  submitted = false;

  CreateUser: FormGroup = this.fb.group({
    fullname: this.fb.control(''),
    username: this.fb.control('', {
      validators: [Validators.required],
    }),
    password: this.fb.control('', {validators: [Validators.required, this.patternValidator()]}),
    email: this.fb.control(''),
  });

  hidePassword = true;

  // auto generate password helpers
  dictionary: Array<string>;
  passwordLenght = 15;
  buttonLabel = 'Generate';
  newPassword: string;
  lowercase = true;
  uppercase = true;
  numbers = true;
  symbols = true;
  passwordXters = [
    {
      library: 'abcdefghijklmnopqrstuvwxyz',
    }, {
      library: 'ABCDEFGHIJKLMNOPWRSTUVWXYZ',
    }, {
      library: '0123456789',
    }, {
      library: '!@#$%^&*-_=+\\|:;\',.\<>/?~',
    }
  ];
  // Copy password to clipboard
  @ViewChild('passwordWithValidation') password: ElementRef;
  private copyPassword() {
    const inputElement = this.password.nativeElement as HTMLInputElement;
    inputElement.select();
    document.execCommand('copy');
  }

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CreateUserComponent>,
    public uService: SubUserService,
    public Toastr: ToastrService
  ) {}



  ngOnInit(): void {}

  patternValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const regex = new RegExp(
        '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*[@#$%!~^&*()_+=?<>,.]).{8,}$'
      );
      const valid = regex.test(control.value);
      return valid ? null : { invalidPassword: true };
    };
  }

  // tslint:disable-next-line: typedef
  get f() {
    return this.CreateUser.controls;
  }

  // tslint:disable-next-line: typedef
  onCreateUser() {
    this.submitted = true;
    this.loading = true;
    this.disable = true;
    // console.log(this.CreateUser.value);
    this.uService.createSubUsers(this.CreateUser.value).subscribe(
      (res) => {
        // console.log();
        if (res['status'] === 0) {
          this.Toastr.success(res['message'], 'Success');
          this.loading = true;
          this.disable = true;
          this.closeDialog();
        } else {
          this.Toastr.error(res['message'], 'Error');
          this.disable = false;
          this.loading = false;
        }
      },
      (err) => {
        this.Toastr.error(err, 'Failed!');
        this.disable = false;
        this.loading = false;
      }
    );
  }

  // tslint:disable-next-line: typedef
  closeDialog() {
    this.dialogRef.close();
  }

  // Generate password
  public generatePassword() {
    if (this.lowercase === false && this.uppercase === false && this.numbers === false && this.symbols === false) {
      return this.newPassword = '...';
    }

    // Create array from chosen checkboxes
    this.dictionary = [].concat(
      this.lowercase ? this.passwordXters[0].library.split('') : [],
      this.uppercase ? this.passwordXters[1].library.split('') : [],
      this.numbers ? this.passwordXters[2].library.split('') : [],
      this.symbols ? this.passwordXters[3].library.split('') : []
    );

    // Generate random password from array
    let newPassword = '';
    for (let i = 0; i < this.passwordLenght; i++) {
      newPassword += this.dictionary[Math.floor(Math.random() * this.dictionary.length)];
    }

    this.newPassword = newPassword;
    this.CreateUser.patchValue({
      password: this.newPassword
    });

    // Call copy function
    setTimeout(() => this.copyPassword());

    // Change text on button when clicked
    this.buttonLabel = 'Copied!';
    setTimeout(() => { this.buttonLabel = 'Generate'; }, 1500);
  }
}
