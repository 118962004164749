<div class='container'>
    <mat-card class="card">
      <mat-card-header>
        <mat-card-title>Client Address Management</mat-card-title>
      </mat-card-header>
      <mat-divider></mat-divider>
      <mat-card-content>

        <div class="div">
          <div class='left'>
            <button (click)="addressConfirmation()" *ngIf="showAssignBtn" class="assign-address-btn" mat-button>
              <!-- <mat-icon>send</mat-icon> -->
              <span>&nbsp; Confirm Address(es) </span>
            </button>
          </div>

          <div class="right">
            <form [formGroup]="addressForm" (ngSubmit)="onAddressFormSubmit(addressForm.value)" class="div_filter">

              <mat-form-field>
                <mat-label>Enter a date range</mat-label>
                <mat-date-range-input [rangePicker]="picker" disabled>
                  <input matStartDate formControlName="start" placeholder="Start date">
                  <input matEndDate formControlName="end" placeholder="End date">
                </mat-date-range-input>
                <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker color="primary" disabled="false"></mat-date-range-picker>

                <mat-error *ngIf="addressForm.controls.start.hasError('matStartDateInvalid')">Invalid start date
                </mat-error>
                <mat-error *ngIf="addressForm.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
              </mat-form-field>

              <button type="button" [disabled]="!addressForm.valid" (click)="downloadAddress()" style="margin-left: 1rem;" class="assign-address-btn"
                mat-button>
                <mat-icon>get_app</mat-icon>
              </button>

            </form>
          </div>

          <div class="center">
            <mat-form-field class="div_search-field">
              <mat-label>
                Search here....... <mat-icon>search</mat-icon>
              </mat-label>
              <input matInput placeholder="Search address" [formControl]="searchControl">
            </mat-form-field>
          </div>
        </div>

        <!-- start Address Table Here  -->
        <div class="container mat-elevation-z8">

          <div class="table-loading-shade" *ngIf="isLoadingResults | async">
            <mat-spinner class="custom-spinner"></mat-spinner>
          </div>

          <div class='table-container'>
            <table mat-table [dataSource]="addressArray" matSort matSortActive matSortDisableClear matSortDirection="desc">

              <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                </th>
                <td class="userList-td" mat-cell *matCellDef="let row">
                  <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="
                      $event ? singleToggle(row.address_id, $event, row.status) : null
                    " [checked]="selection.isSelected(row)" >
                  </mat-checkbox>
                </td>
              </ng-container>

              <ng-container matColumnDef="geocodingId">
                <th mat-header-cell *matHeaderCellDef> Policy Id </th>
                <td mat-cell *matCellDef="let row"> {{row.policy_id}} </td>
              </ng-container>

              <ng-container matColumnDef="message">
                <th mat-header-cell *matHeaderCellDef> Message </th>
                <td mat-cell *matCellDef="let row"> {{row.message}} </td>
              </ng-container>

              <ng-container matColumnDef="customername">
                <th mat-header-cell *matHeaderCellDef> Customer Name </th>
                <td mat-cell *matCellDef="let row"> {{row.customername}} </td>
              </ng-container>

              <ng-container matColumnDef="housenumber">
                <th mat-header-cell *matHeaderCellDef> House Number </th>
                <td mat-cell *matCellDef="let row"> {{row.house_number}} </td>
              </ng-container>

              <ng-container matColumnDef="streetname">
                <th mat-header-cell *matHeaderCellDef> Street Name </th>
                <td mat-cell *matCellDef="let row"> {{row.streetname}} </td>
              </ng-container>

              <ng-container matColumnDef="areaname">
                <th mat-header-cell *matHeaderCellDef> Area </th>
                <td mat-cell *matCellDef="let row"> {{row.area_name}} </td>
              </ng-container>

              <ng-container matColumnDef="lga">
                <th mat-header-cell *matHeaderCellDef> LGA </th>
                <td mat-cell *matCellDef="let row"> {{row.lga}} </td>
              </ng-container>

              <ng-container matColumnDef="state">
                <th mat-header-cell *matHeaderCellDef> State </th>
                <td mat-cell *matCellDef="let row"> {{row.state}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>

          <mat-paginator [length]="totalAddressCount" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
            showFirstLastButtons>
          </mat-paginator>
        </div>

        <!--Address Table Ends Here  -->

      </mat-card-content>
    </mat-card>
  </div>

