<div>
    <div>
        <h3><b>Geocoding Id: {{geocodingId}}</b></h3>
    </div>
    <mat-divider></mat-divider>
    <div *ngFor='let log of logs' class='body-container' >
        <div>
           <!-- <label>Activities: </label> -->
            <p>Activity: {{log.username}} {{log.title}}</p> 
        </div>
        <div>
            <!-- <label>Date: </label> -->
            <p>Date: {{log.logDate}}</p> 
        </div>
    </div>
</div>
