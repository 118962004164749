<div class="con-flu">
  <div class="bg-whole">
    <div class="image-bg">
      <img style="width: 100%; height: 100vh" src="/assets/img/axa.jpg" />
    </div>

    <div class="rec2">
      <div class="card-bg">
        <p class="log-title">Secure Member Forget Password</p>

        <div class="flex-cen">
          <form class="form-horizontal" (ngSubmit)="forgetPassword()">
            <div class="login_container">
              <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <mat-icon class="white-icon" matPrefix>perm_identity</mat-icon>
                <input
                  matInput
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  [(ngModel)]="detail.email"
                />
              </mat-form-field>
            </div>

            <div class="login_container">
              <button type="submit" class="btn__login">
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Send
              </button>
            </div>
            <div class="login_container login" style="text-align: center">
              <br />
              <span class="logintext"><a routerLink="/login"> Login </a></span>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
