<div class="container">
  <div class="label">
    <h3><b>CHANGE PASSWORD</b></h3>
  </div>

  <div class="form-group">
    <form (ngSubmit)="ChangePassword()" class="" [formGroup]="changePasswordForm" #f="ngForm">

      <!--password input filed-->
      <mat-form-field appearance="outline" style="width: 70%">
        <mat-label>Password</mat-label>
        <mat-pass-toggle-visibility #toggle matSuffix></mat-pass-toggle-visibility>
        <input matInput #passwordWithValidation [type]="toggle.type" required formControlName="newPassword"
         placeholder="Password" maxlength="15" [value]="newPassword">

        <!--password hint-->
        <mat-hint align="end" aria-live="polite">
          {{passwordWithValidation.value.length}} / 15
        </mat-hint>

        <!--password Mat-error-->
        <mat-error *ngIf="changePasswordForm.controls['newPassword'].hasError('required')">Password is required</mat-error>
        <mat-error *ngIf="changePasswordForm.controls['newPassword'].hasError('invalidPassword')"> Password is not valid </mat-error>

      </mat-form-field>

      <span>
        <button mat-button class="generateBtn" type="button" (click)="generatePassword()">{{buttonLabel}}</button>
      </span>

      <!--password-strength's main component-->
      <mat-password-strength #passwordComponentWithValidation
        [password]="passwordWithValidation.value" class="green" [min]="8" [max]="15" [enableLowerCaseLetterRule]="false">
      </mat-password-strength>

       <!--Password's strength info-->
      <mat-password-strength-info [passwordComponent]="passwordComponentWithValidation"></mat-password-strength-info>

      <!--Button-->
      <br>
      <mat-grid-list cols="2" rowHeight="37px">
        <mat-grid-tile>
          <button type="button" mat-flat-button class="cancelBtn" color="warn" (click)="closeModal()">
            Cancel
          </button>
        </mat-grid-tile>
        <mat-grid-tile>
          <button [disabled]="changePasswordForm.invalid" mat-flat-button class="saveBtn">
            Save
            <mat-spinner *ngIf="isLoading" class="custom-spinner" diameter="20"></mat-spinner>
          </button>
        </mat-grid-tile>
      </mat-grid-list>
    </form>
  </div>
</div>

