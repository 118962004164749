import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import {
  Addresses,
  AddresshttpResModel,
  User,
} from 'src/app/shared/models/user.model';
import { Role } from '@app/shared/models/role.model';
import { BehaviorSubject, merge, Observable, Subscription } from 'rxjs';
import { ProjectService } from 'src/app/shared/services/projectService/project.service';
import { startWith, switchMap, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';
import { AssignAddressComponent } from './assign-address/assign-address.component';
import { UpdateAddressComponent } from './update-address/update-address.component';
import { MapAddressesService } from '@app/shared/services/map/map-addresses.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@app/shared/services/authService/authentication.service';
import { ConfirmationComponent } from '@app/views/confirmation/confirmation.component';
import { ViewLogComponent } from './view-log/view-log.component';
import { DuplicateAddressComponent } from './duplicate-address/duplicate-address.component';

@Component({
  selector: 'app-address-lists',
  templateUrl: './address-lists.component.html',
  styleUrls: ['./address-lists.component.scss'],
})
export class AddressListsComponent implements OnInit {
  displayedColumns = [
    'select',
    'geocodingId',
    'housenumber',
    'streetname',
    'areaname',
    'lga',
    'state',
    'status',
    'uploadedDate',
    'geocodedDate',
    'action',
  ];

  selection = new SelectionModel<Addresses>(true, []);
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  status = -1;
  key = 0;
  outsideTat = '';
  term = '';
  startDate = '';
  endDate = '';

  addresses: Observable<AddresshttpResModel[]>;
  addressArray: [];
  AddressIdsArray: number[] = [];
  showAssignBtn = false;
  downloadLoader = false;
  showFiledworker = false;

  addressListIsEmpty$: BehaviorSubject<{
    value: boolean;
    type: string;
  }> = new BehaviorSubject({ value: false, type: 'empty' });
  isLoadingResults$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  sortSub$: Subscription;
  searchControl: FormControl = new FormControl('');
  totalAddressCount;
  pageSize = 20;
  pageSizeOptions: number[] = [10, 20, 30, 50, 100, 300, 500];

  statusOptions = [
    { code: -1, name: 'All', key: 0 },
    { code: 2, name: 'All Geocoded', key: 0 },
    { code: 2, name: 'Geocoded by System', key: 1 },
    { code: 2, name: 'Geocoded by Field Agents', key: 2 },
    { code: 1, name: 'Partially Geocoded', key: 0 },
    { code: 3, name: 'Assigned', key: 0 },
  ];

  user: User;

  constructor(
    public dialog: MatDialog,
    public addressService: ProjectService,
    private toaster: ToastrService,
    private mapAddressservice: MapAddressesService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService
  ) {
    this.authService.user.subscribe((x) => (this.user = x));
  }

  addressForm = new FormGroup({
    status: new FormControl(-1),
    start: new FormControl(''),
    end: new FormControl(''),
  });

  private updateLoadingStatus(status: boolean) {
    this.isLoadingResults$.next(status);
  }

  ngOnInit(): void {
    this.addresses = this.addressService.getAddresses();
    // get route params
    this.route.params.subscribe((params) => {
      if (JSON.stringify(params) !== '{}') {
        this.status = +params['status']; // (+) converts string 'status' to a number
        this.outsideTat = params['outsideTat'];
        this.showFiledworker = true;
        // add field staff column
        this.displayedColumns.splice(8, 0, 'fieldStaff');
      }
    });

    this.searchControl.valueChanges.subscribe((value) => {
      this.term = value;
      this.paginator.page.emit();
    });
  }

  // check if user is a client admin
  get isClientAdmin() {
    return this.user && this.user.roles[0].authority === Role.Client;
  }

  statusChange(statusCode: any) {
    console.log(statusCode);
    this.status = statusCode.code;
    this.key = statusCode.key;
  }

  // Tola start from here to show the address logs
  viewAddressLog(addressInfo: any) {
    console.log(addressInfo);
    const geocodingId = addressInfo.geocodingId;
    const id = addressInfo.id;
    const dialogRef = this.dialog.open(ViewLogComponent, {
      width: '35vw',
      minHeight: '20vh',
      maxHeight: '45vh',
      data: { geocodingId, id },
    });
  }

  ngAfterViewInit() {
    this.sortSub$ = this.sort.sortChange.subscribe(
      () => (this.paginator.pageIndex = 0)
    );
    merge(this.paginator.page, this.sort.sortChange)
      .pipe(
        tap((value) => {
          // console.log(value);
        }),
        startWith({}),
        switchMap(() => {
          this.updateLoadingStatus(true);
          return this.addressService.fetchAddresses(
            this.paginator.pageIndex,
            this.paginator.pageSize,
            this.key,
            this.status,
            this.term,
            this.outsideTat,
            this.startDate,
            this.endDate
          );
        })
      )
      .subscribe(
        (res) => {
          this.addressArray = res.addresses;
          this.addressService.updateAddressList(this.addressArray);
          this.totalAddressCount = res.addressCount;
          this.updateLoadingStatus(false);

          this.addressListIsEmpty$.next({
            value: res.addressCount <= 0,
            type: 'empty',
          });
        },
        (error) => {
          this.updateLoadingStatus(false);
          this.addressListIsEmpty$.next({
            value: true,
            type: 'connection',
          });
          // console.log(error);
        }
      );
  }

  updateAddressTable() {
    this.updateLoadingStatus(true);
    this.addressService
      .fetchAddresses(
        this.paginator.pageIndex,
        this.paginator.pageSize,
        this.key,
        this.status,
        this.term,
        this.outsideTat,
        this.startDate,
        this.endDate
      )
      // Updating the address after assign action;
      .subscribe(
        (res: any) => {
          this.addressArray = res.addresses;
          this.addressService.updateAddressList(this.addressArray);
          this.totalAddressCount = res.addressCount;
          this.updateLoadingStatus(false);

          this.addressListIsEmpty$.next({
            value: res.addressCount <= 0,
            type: 'empty',
          });
        },
        (error) => {
          this.updateLoadingStatus(false);
          this.addressListIsEmpty$.next({
            value: true,
            type: 'connection',
          });
          // console.log(error);
        }
      );
  }

  clearAllFilter() {
    this.status = -1;
    this.startDate = '';
    this.endDate = '';
    this.term = '';
    this.key = 0;
    this.outsideTat = '';
    this.paginator.page.emit();
    this.searchControl.setValue('');
  }

  clearAllFilter2() {
    this.addressForm.reset({ status: -1, start: '', end: '' });
    this.key = 0;
    this.status = -1;
    this.startDate = '';
    this.endDate = '';
    this.term = '';
    this.outsideTat = '';
    this.paginator.page.emit();
    this.searchControl.setValue('');
  }

  archieveAddress(addressId) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: { action: `archive this address? this address can't be return` },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'cancel') {
      } else if ('ok') {
        this.addressService.archieveAddress(addressId).subscribe(
          (res: { status: number; message: string }) => {
            if (res.status === 0) {
              this.toaster.success(res.message);
              this.updateAddressTable();
            } else {
              this.toaster.warning(res.message);
            }
          },
          (error) => {
            this.toaster.error(error, 'error');
          }
        );
      }
    });
  }

  assignAddressModal() {
    const dialogRef = this.dialog.open(AssignAddressComponent, {
      width: '28rem',
      height: '15rem',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((fieldWorkerId) => {
      if (fieldWorkerId === 'close') {
      } else {
        this.addressService
          .assignAddress(fieldWorkerId, this.AddressIdsArray)
          .subscribe(
            (res: any) => {
              // console.log(res);
              if (res.status === 0) {
                this.updateAddressTable();
                this.toaster.success(
                  'Address(s) successfully assigned',
                  'Success'
                );
                this.selection.clear();
                this.AddressIdsArray = [];
                this.showAssignBtn = false;
              } else {
                this.toaster.error('Failed to assign Address(s)', 'Failed!');
              }
            },
            (error) => {
              // console.log(error);
              this.toaster.error(error, 'Failed!');
            }
          );
      }
    });
  }

  onAddressFormSubmit(value) {
    // console.log(value);
    // this.status = value.code;
    // this.key = value.key;
    this.outsideTat = '';
    if (
      value.start !== '' &&
      value.end !== '' &&
      value.start !== null &&
      value.end !== null
    ) {
      this.startDate = this.addressService.formatDate(value.start);
      this.endDate = this.addressService.formatDate(value.end);
    } else {
      this.startDate = '';
      this.endDate = '';
    }

    if (this.key === 2 && this.status === 2) {
      if (this.displayedColumns.includes('fieldStaff')) {
        this.showFiledworker = true;
      } else {
        this.showFiledworker = true;
        this.displayedColumns.splice(8, 0, 'fieldStaff');
      }
    } else {
      if (this.displayedColumns.includes('fieldStaff')) {
        this.showFiledworker = false;
        this.displayedColumns.splice(8, 1)
      }
    }
    this.updateAddressTable();
  }

  updateAddress(addressInfo): void {
    const dialogRef = this.dialog.open(UpdateAddressComponent, {
      width: '23rem',
      height: '36rem',
      disableClose: true,
      data: addressInfo,
    });
    dialogRef.afterClosed().subscribe((cancel) => {
      if (cancel === 'yes') {
      } else {
        this.updateAddressTable();
      }
    });
  }

  duplicateAddress(AddressId) {
    const dialogRef = this.dialog.open(DuplicateAddressComponent, {
      width: '23rem',
      height: '12rem',
      disableClose: true,
      data: AddressId,
    });
    dialogRef.afterClosed().subscribe((cancel) => {
      if (cancel === 'yes') {
      } else {
        this.updateAddressTable();
      }
    });
  }

  // /** Whether the number of selected elements matches the total number of rows. */
  // isAllSelected() {
  //   //console.log('this is the array' , this.addressArray);
  //   const numSelected = this.selection.selected.length;
  //   const numRows = 16; //this.addressArray.length;
  //   return numSelected === numRows;
  // }

  // /** Selects all rows if they are not all selected; otherwise clear selection. */
  // masterToggle() {
  //   this.isAllSelected() ?
  //       this.selection.clear() :
  //       this.addressArray.forEach(row => {
  //         if(row['status'] === 3){
  //           this.transactionId.push(row['geocodingId']);
  //           console.log(this.transactionId);
  //           this.selection.select(row);
  //         }

  //       });
  // }

  // /** The label for the checkbox on the passed row */
  // checkboxLabel(row?: Addresses): string {
  //   if (!row) {
  //     return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
  //   }
  //   return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.geocodingId + 1}`;
  // }

  /** Selects single row box checked */
  singleToggle(AddressId, e, status) {
    if (e.checked === true) {
      // if checked push AddressId to AddressIdsArray[]
      this.AddressIdsArray.push(AddressId);
      this.showAssignBtn = true;
      // console.log(this.AddressIdsArray);
    } else if (e.checked === false) {
      // if unchecked remove the AddressId from AddressIdsArray[]
      const index = this.AddressIdsArray.indexOf(AddressId);
      if (index > -1) {
        this.AddressIdsArray.splice(index, 1);
        // console.log(this.AddressIdsArray);
      }
      if (!this.AddressIdsArray || !this.AddressIdsArray.length) {
        this.showAssignBtn = false;
      }
    }
  }

  downloadAddress() {
    // if (this.addressForm.value.status !== 2) {
    //   this.toaster.warning('Only geocoded addresses can be download');
    // } else {
    if (
      (this.addressForm.value.start === '' &&
        this.addressForm.value.end === '') ||
      (this.addressForm.value.start === null &&
        this.addressForm.value.end === null)
    ) {
      this.toaster.warning('Enter a valid date range');
    } else {
      const startDate = this.addressService.formatDate(
        this.addressForm.value.start
      );
      const endDate = this.addressService.formatDate(
        this.addressForm.value.end
      );
      // const status = this.addressForm.value.status;
      this.downloadLoader = true;
      this.addressService
        .downloadExcelFile(startDate, endDate, this.status, this.key)
        .subscribe(
          (res: any) => {
            // console.log(res);
            const file = new File([res.body], 'Addresses' + '.xlsx', {
              type: 'application/vnd.ms-excel;charset=utf-8',
            });
            this.downloadLoader = false;
            if (res.status === 200) {
              this.toaster.success('success');
              saveAs(file, 'Addresses.xlsx');
            } else if (res.status === 400) {
              this.toaster.warning('No data within this date range', 'Caution');
            }
          },
          (error) => {
            this.downloadLoader = false;
            this.toaster.warning(error, 'Failed');
          }
        );
    }
  }

  viewAddressOnMap(address): void {
    this.mapAddressservice.updateAddressObservable([address]);
    this.router.navigate(['/map']);
  }
}
