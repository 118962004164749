import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Role } from '@app/shared/models/role.model';
import { User } from '@app/shared/models/user.model';
import { AuthenticationService } from '@app/shared/services/authService/authentication.service';
// import { Cookie } from 'ng2-cookies';
// import { CookieService } from 'ngx-cookie-service';
import { ChangeProfilePasswordComponent } from './change-profile-password/change-profile-password.component';
import { ChangeTokenComponent } from '@app/views/header-footer/change-token/change-token.component';
import { Router } from '@angular/router';
import { ProjectService } from '@app/shared/services/projectService/project.service';
@Component({
  selector: 'app-header-footer',
  templateUrl: './header-footer.component.html',
  styleUrls: ['./header-footer.component.scss'],
})
export class HeaderFooterComponent implements OnInit {
  user: User;
  username;
  TatCount;
  isCountAvailable: boolean;

  constructor(
    private authService: AuthenticationService,
    public dialog: MatDialog,
    private router: Router,
    private address: ProjectService
  ) {
    this.authService.user.subscribe((x) => (this.user = x));
  }

  ngOnInit(): void {
    this.getOutsideTATCount();
    this.username = sessionStorage.getItem('username');
  }

  changePassword(): void {
    const dialogRef = this.dialog.open(ChangeProfilePasswordComponent, {
      width: '25rem',
      height: '33rem',
      // disableClose: true,
    });
  }

  getOutsideTATCount() {
    this.address.getOutsideTatCounts().subscribe(
      (res) => {
        this.TatCount = res.count;
        this.isCountAvailable = res.hasAddress;
      },
      (err) => console.log(err)
    );
  }

  changeToken() {
    const dialogRef = this.dialog.open(ChangeTokenComponent, {
      width: '25rem',
      minHeight: '16rem',
      disableClose: true,
    });
  }

  // viewAddressesOutsideTAT(outTat, status){
  //   this.router.navigate(['/address', outTat, status]);
  // }

  // tslint:disable-next-line: typedef
  get isSuperAdminAdmin() {
    return this.user && this.user.roles[0].authority === Role.SuperAdmin;
  }

  // tslint:disable-next-line: typedef
  get isClientAdmin() {
    return this.user && this.user.roles[0].authority === Role.Client;
  }

  // tslint:disable-next-line: typedef
  get isSubUser() {
    return this.user && this.user.roles[0].authority === Role.SubUser;
  }

  // tslint:disable-next-line: typedef
  get isGisUser() {
    return this.user && this.user.roles[0].authority === Role.GIS;
  }

  logOut(): void {
    this.authService.logout();
  }
}
