import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MapAddressesService } from 'src/app/shared/services/map/map-addresses.service';
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class AppFilterComponent implements OnInit, OnDestroy {
  dateRange: FormGroup;

  @Output() datePickerClosed: EventEmitter<any> = new EventEmitter();
  constructor(private mapAddressService: MapAddressesService) {
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    const day = today.getDate();


    this.dateRange = new FormGroup({
      start: new FormControl(new Date(year, month, day)),
      end: new FormControl(new Date(year, month, day > 7 ? day - 7 : 1)),
    });
  }
  ngOnInit(): void {}

  closed(): void {
    // get fromDate
    const fromDate = this.mapAddressService.getISOStringFromDate(
      new Date(this.dateRange.get('start').value)
    );
    // get toDate
    const toDate = this.mapAddressService.getISOStringFromDate(
      new Date(this.dateRange.get('end').value)
    );
    // update address list observable
    this.mapAddressService.fetchMapAddresses(fromDate, toDate);

    // emit closed to map component
    this.datePickerClosed.emit();

    this.mapAddressService.updateIsPolygonDrawn(false);

  }

  ngOnDestroy(): void {}
}
