import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormControl, FormGroup } from '@angular/forms';


import { ProjectService } from 'src/app/shared/services/projectService/project.service';
import { startWith, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, merge, Observable, Subscription } from 'rxjs';
import {
  Addresses,
  AddresshttpResModel,
  User
} from 'src/app/shared/models/user.model';

@Component({
  selector: 'app-address-archive',
  templateUrl: './address-archive.component.html',
  styleUrls: ['./address-archive.component.scss']
})
export class AddressArchiveComponent implements OnInit {

  displayedColumns = [
    'geocodingId',
    'housenumber',
    'streetname',
    'areaname',
    'lga',
    'state',
    'status',
    'uploadedDate',
    'geocodedDate',
    'archivedDate'
  ];
// : Observable<AddresshttpResModel[]>
  ;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  addresses: Observable<AddresshttpResModel[]>;
  term = '';
  addressArray: [];
  addressListIsEmpty$: BehaviorSubject<{
    value: boolean;
    type: string;
  }> = new BehaviorSubject({ value: false, type: 'empty' });
  sortSub$: Subscription;
  isLoadingResults$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  searchControl: FormControl = new FormControl('');
  pageSize = 10;
  pageSizeOptions: number[] = [10, 20, 30, 50, 100, 300, 500];
  totalAddressCount;
  
  statusOptions = [
    { code: -1, name: 'All' },
    { code: 2, name: 'Geocoded' },
    { code: 3, name: 'Assigned' },
    { code: 1, name: 'Failed' },
  ];
  constructor(
    public addressService: ProjectService
  ) { }

  ngOnInit(): void {
    this.addresses = this.addressService.getAddresses();
    this.searchControl.valueChanges.subscribe((value) => {
      this.term = value;
      this.paginator.page.emit();
    });
  }

  ngAfterViewInit() {
    this.sortSub$ = this.sort.sortChange.subscribe(
      () => (this.paginator.pageIndex = 0)
    );
    merge(this.paginator.page, this.sort.sortChange)
      .pipe(
        tap((value) => {
          // console.log(value);
        }),
        startWith({}),
        switchMap(() => {
          this.updateLoadingStatus(true);
          return this.addressService.fetchArchives(
            this.paginator.pageIndex,
            this.paginator.pageSize,
            this.term
          );
        })
      )
      .subscribe(
        (res) => {
          console.log(res);
          this.addressArray = res.addresses;
          this.addressService.updateAddressList(this.addressArray);
          this.totalAddressCount = res.addressCount;
          this.updateLoadingStatus(false);

          this.addressListIsEmpty$.next({
            value: res.addressCount <= 0,
            type: 'empty',
          });
        },
        (error) => {
          this.updateLoadingStatus(false);
          this.addressListIsEmpty$.next({
            value: true,
            type: 'connection',
          });
          // console.log(error);
        }
      );
  }

  getArchives() {       

    this.addressService.getArchives().subscribe((res:any)=>{
      this.updateLoadingStatus(false);
      console.log(res);
      this.totalAddressCount = res.addressCount;
      this.addresses = res.addresses;
    })
  }

  clearAllFilter() {
    this.term = '';
    this.paginator.page.emit();
    this.searchControl.setValue('');
  }
  private updateLoadingStatus(status: boolean) {
    this.isLoadingResults$.next(status);
  }

}
