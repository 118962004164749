<div class="container">
  <mat-card class="card">
    <mat-card-header>
      <mat-card-title>Archived Addresses</mat-card-title>
    </mat-card-header>
    <mat-divider></mat-divider>
    <mat-card-content>
      <div class="div">

        <div class="center">
          <mat-form-field class="div_search-field">
            <mat-label>
              Search here....... <mat-icon>search</mat-icon>
            </mat-label>
            <input matInput placeholder="Search for an address" [formControl]="searchControl">
            <button type="button" mat-icon-button matSuffix (click)="clearAllFilter()">
              <mat-icon aria-hidden="false" aria-label="Clear filter" color="warn">backspace</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>

      <!-- start Address Table Here  -->
      <div class="address-container mat-elevation-z8">
        <div class="table-loading-shade" *ngIf="isLoadingResults$ | async">
          <mat-spinner class="custom-spinner"></mat-spinner>
        </div>

        <div class="address-table-container">
          <table mat-table [dataSource]="addresses" matSort matSortActive matSortDisableClear matSortDirection="desc">

            <ng-container matColumnDef="geocodingId">
              <th mat-header-cell *matHeaderCellDef>Geocoding Id</th>
              <td mat-cell *matCellDef="let row">{{ row.geocodingId }}</td>
            </ng-container>

            <ng-container matColumnDef="housenumber">
              <th mat-header-cell *matHeaderCellDef>House Number</th>
              <td mat-cell *matCellDef="let row">{{ row.housenumber }}</td>
            </ng-container>

            <ng-container matColumnDef="streetname">
              <th mat-header-cell *matHeaderCellDef>Street Name</th>
              <td mat-cell *matCellDef="let row">{{ row.streetname }}</td>
            </ng-container>

            <ng-container matColumnDef="areaname">
              <th mat-header-cell *matHeaderCellDef>Area</th>
              <td mat-cell *matCellDef="let row">{{ row.areaname }}</td>
            </ng-container>

            <ng-container matColumnDef="lga">
              <th mat-header-cell *matHeaderCellDef>LGA</th>
              <td mat-cell *matCellDef="let row">{{ row.lga }}</td>
            </ng-container>

            <ng-container matColumnDef="state">
              <th mat-header-cell *matHeaderCellDef>State</th>
              <td mat-cell *matCellDef="let row">{{ row.state }}</td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>Status</th>
              <td mat-cell *matCellDef="let row">
                <mat-icon *ngIf="row.status=== 3" style="color:#003796ce;">engineering</mat-icon>
                <mat-icon *ngIf="row.status=== 2" style="color: rgb(32, 165, 19);">verified</mat-icon>
                <mat-icon *ngIf="row.status=== 0" style="color: rgba(255, 68, 0, 0.87);">error</mat-icon>
                <mat-icon *ngIf="row.status=== 1" style="color: red;">unpublished</mat-icon>
              </td>
            </ng-container>

            <ng-container matColumnDef="uploadedDate">
              <th mat-header-cell *matHeaderCellDef>Uploaded Date</th>
              <td mat-cell *matCellDef="let row">{{ row.uploadedDate }}</td>
            </ng-container>

            <ng-container matColumnDef="geocodedDate">
              <th mat-header-cell *matHeaderCellDef>Geocoded Date</th>
              <td mat-cell *matCellDef="let row">{{ row.geocodedDate }}</td>
            </ng-container>

            <ng-container matColumnDef="archivedDate">
              <th mat-header-cell *matHeaderCellDef>Archived Date</th>
              <td mat-cell *matCellDef="let row">{{ row.archivedDate }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>

        <mat-paginator [length]="totalAddressCount" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
          showFirstLastButtons>
        </mat-paginator>
      </div>
      <!--Address Table Ends Here  -->
    </mat-card-content>
  </mat-card>
</div>
