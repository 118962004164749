<main class="container">
  <div class="header">
    <button
      color="primary"
      mat-stroked-button
      (click)="addClient()"
      class="create-client-btn"
    >
      <mat-icon>add</mat-icon>
      <span class="create-client-btn__label">Create Client</span>
    </button>
  </div>
  <section class="loader">
    <mat-spinner diameter="10" *ngIf="loading"></mat-spinner>
  </section>
  <ng-template [ngIf]="!loading">
    <div class="grid">
      <app-client-card
        *ngFor="let client of clients"
        [client]="client"
      ></app-client-card>
      <mat-card matRipple class="create-client-btn" (click)="addClient()">
        <mat-icon class="icon">add_circle</mat-icon>
      </mat-card>
    </div>
  </ng-template>
</main>
