<div class="container">
  <div class="addAgentBtn_container">
    <button (click)="createFieldworkerModal()" class="add-agent-btn" mat-button>
      <mat-icon>group_add</mat-icon>
      <span>&nbsp;Create Agent</span>
    </button>
  </div>

  <mat-card class="example-card">
    <mat-card-header>
      <mat-card-title>Field Staff Management</mat-card-title>
    </mat-card-header>
    <mat-divider></mat-divider>
    <br />

    <mat-card-content>
      <!-- search keyword -->
      <mat-form-field>
        <mat-label>
          Search ......................<mat-icon>search</mat-icon>
        </mat-label>
        <input matInput placeholder="Search field agent" [formControl]="searchControl" />
        <button type="button" mat-icon-button matSuffix (click)="clearAllFilter()">
          <mat-icon aria-hidden="false" aria-label="Clear filter" color="warn">backspace</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field style="width: 7rem !important; float: right;">
        <mat-label>Status</mat-label>
        <mat-select name="status" [(ngModel)]="status" (selectionChange)="statusChange($event.value)">
          <mat-option *ngFor="let status of statusOptions" [value]='status.code'>{{status.name}}</mat-option>
        </mat-select>
      </mat-form-field>

      <div class="fieldworker-container mat-elevation-z8">
        <div class="table-loading-shade" *ngIf="isLoadingResults$ | async">
          <mat-spinner class="custom-spinner"></mat-spinner>
        </div>

        <div class="fieldworker-table-container">
          <table mat-table [dataSource]="fieldWorkers" class="fieldworker-table" matSort matSortActive
            matSortDisableClear matSortDirection="desc">
            <!-- Full Name -->
            <ng-container matColumnDef="fullname">
              <th mat-header-cell *matHeaderCellDef>Full Name</th>
              <td mat-cell *matCellDef="let row">{{ row.fullname }}</td>
            </ng-container>

            <!-- Username -->
            <ng-container matColumnDef="username">
              <th mat-header-cell *matHeaderCellDef>Username</th>
              <td mat-cell *matCellDef="let row">{{ row.username }}</td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>Status</th>
              <td mat-cell *matCellDef="let row">
                <!-- <mat-chip-list>
                  <mat-chip *ngIf="row.isAccountNonLocked === false" color="primary" selected> Disabled </mat-chip>
                </mat-chip-list>
                <mat-chip-list>
                  <mat-chip *ngIf="row.isAccountNonLocked === true" color="accent" selected> Enabled </mat-chip>
                </mat-chip-list> -->

                <mat-icon *ngIf="row.isAccountNonLocked" style="color: rgb(32, 165, 19)">verified_user</mat-icon>

                <mat-icon *ngIf="!row.isAccountNonLocked" style="color: orangered">unpublished</mat-icon>
              </td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>Actions</th>
              <td mat-cell *matCellDef="let row">
                <mat-menu style="padding: 0;" #appMenu="matMenu">

                  <button mat-menu-item (click)="viewCount(row.id, row.username)" class="userActionTableBtn">
                    View Address Progress
                  </button>

                  <button mat-menu-item (click)="changePassword(row.id, row.roles[0].name, row.username)" class="userActionTableBtn">
                    Change Password
                  </button>

                  <button (click)="changeToggle(row.id, 'Deactivate')" *ngIf="row.isAccountNonLocked" mat-menu-item
                    class="userActionTableBtn">
                    Deactivate Agent
                  </button>
                  <button (click)="changeToggle(row.id, 'Activate')" mat-menu-item class="userActionTableBtn"
                    *ngIf="!row.isAccountNonLocked">
                    Activate Agent
                  </button>
                </mat-menu>

                <button mat-icon-button color="primary" [matMenuTriggerFor]="appMenu">
                  <mat-icon>more_vert</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" class="example-element-row"></tr>
          </table>
        </div>

        <mat-paginator [length]="totalfieldWorkerCount" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
          showFirstLastButtons></mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>
</div>
