<div class='container'>
    <div class='container-upper'>
        <div class='body'>
          <div class='body-container'>
              <div class='container-cards' style="display:flex">
                  <div class="container-cards-card">
                        <ng-container >
                            <div class="d_label">
                              <p class="d_label-description">Address</p>
                              <h3 class="d_label-description2"> Geocoded </h3>

                              <p class="d_label-count">{{geocodedCount}}
                                <mat-spinner *ngIf="isLoading" class="custom-spinner" diameter="20"></mat-spinner>
                              </p>
                            </div>
                          </ng-container>
                        <div class='i'>
                            <img src='assets/img/geocoded.png'>
                        </div>
                  </div>

                  <div class="container-cards-card">

                    <ng-container >
                      <div class="d_label">
                        <p class="d_label-description">Address</p>
                        <h3 class="d_label-description2"> Partially Geocoded </h3>

                        <p class="d_label-count">{{failedCount}}
                          <mat-spinner *ngIf="isLoading" class="custom-spinner" diameter="20"></mat-spinner>
                        </p>
                      </div>
                    </ng-container>
                  <div class='i'>
                      <img src='assets/img/failed.png'>
                  </div>
                  </div>

                  <div class="container-cards-card">

                    <ng-container >
                      <div class="d_label">
                        <p class="d_label-description">Address</p>
                        <h3 class="d_label-description2"> Assigned </h3>

                        <p class="d_label-count">{{assignedCount}}
                          <mat-spinner *ngIf="isLoading" class="custom-spinner" diameter="20"></mat-spinner>
                        </p>
                      </div>
                    </ng-container>
                  <div class='i'>
                      <img src='assets/img/assign.png'>
                  </div>
                </div>
              </div>


              <div class='charts'>
                <mat-card class='matChart' style="background-color: #f9f9f9">
                  <mat-card-header>
                    <mat-card-title>Overview of Address Status/State</mat-card-title>
                  </mat-card-header>
                  <mat-divider></mat-divider>
                  <mat-card-content>
                    <div class='stateForm'>
                      <form style="margin-top: 1rem">
                        <mat-form-field style="width: 130px !important;">
                          <mat-label>Select State</mat-label>
                          <mat-select (selectionChange)="stateTypeChange($event.value)">
                            <mat-option *ngFor='let state of states' [value]="state">{{state}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-form-field style="width: 130px !important; margin-left: 1rem;">
                          <mat-label>Select LGA</mat-label>
                          <mat-select (selectionChange)="lgaTypeChange($event.value)">
                            <mat-option *ngFor='let lga of lgas' [value]="lga">{{lga}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </form>
                    </div>
                    <div *ngIf='isData'>
                        <p>There is no data {{state}}</p>
                      </div>
                    <div class='charts-chartdiv' id='pieChart'>

                  </div>
                  </mat-card-content>
                </mat-card>

                <mat-card class='matChart' style="background-color: #f9f9f9">
                  <mat-card-header>
                    <mat-card-title>Allocated Time</mat-card-title>
                  </mat-card-header>
                  <mat-divider></mat-divider>

                  <div class='charts-chartdiv'
                  id="chartdivTimeOut">

                  </div>
                </mat-card>

              </div>

              <div class='stateChart'>
              <mat-card class='matStateChart' style="background-color: #f9f9f9">
                <mat-card-header>
                  <mat-card-title>Geocoded Address by States</mat-card-title>
                </mat-card-header>
                <mat-divider></mat-divider>
                <mat-card-content>
                  <div class='stateForm'>
                    <form style="margin-top: 1rem">
                      <mat-form-field style="width: 130px !important;">
                        <mat-label>Select State</mat-label>
                        <mat-select (selectionChange)="barStateTypeChange($event.value)">
                          <mat-option *ngFor='let state of states' [value]="state">{{state}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </form>
                  </div>
                  <div class='stateChart-div' id='lineChart'>

                  </div>

                </mat-card-content>

              </mat-card>
            </div>
            </div>


          <div>

          </div>

        </div>
      </div>

</div>
