import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '@environments/environment';

import { userData, userhttpResModel } from '../../models/user.model';
import { EncryptionService } from '../encryptionService/encryption.service';

@Injectable({
  providedIn: 'root',
})
export class SubUserService {
  constructor(private http: HttpClient,
    private encryptionService: EncryptionService
  ) {}

  private user$: BehaviorSubject<userhttpResModel[]> = new BehaviorSubject([]);

  getSubUsers() {
    return this.user$.asObservable();
  }

  updateUser(data: userhttpResModel[]) {
    this.user$.next(data);
  }

  getAllSubUsers(
    page: number,
    pageSize: number,
    query: string,
    status: any
  ): Observable<any> {
    const url = `${environment.apiUrl}users/subUsers/?page=${page}&size=${pageSize}&query=${query}&status=${status}`;
    return this.http.get<any>(url).pipe();
  }

  createSubUsers(detail) {
    const encryptedPassword = this.encryptionService.encryptData(detail.password)
    const url = `${environment.apiUrl}users/client/create`;
    const body = {
      fullname: detail.fullname,
      username: detail.username,
      password: encryptedPassword,
      email: detail.email,
    };
    return this.http.post(url, body);
  }

  getToken() {
    const url = `${environment.apiUrl}users/api_key`;

    return this.http.get(url);
  }

  getNewToken(password) {
    // const url = `${environment.apiUrl}users/update-api_key/?password=${password}`;
    const url = `${environment.apiUrl}users/update-api_key`
    const encryptedPassword = this.encryptionService.encryptData(password)

    return this.http.put(url, {
      password: encryptedPassword
    });
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\n
            Message: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  changeProfilePassword(data) {
    const encryptedNewPassword = this.encryptionService.encryptData(data.password)
    const encryptedOldPassword = this.encryptionService.encryptData(data.oldPassword)
    const chnagePasswordBody = {
      newPassword: encryptedNewPassword,
      oldPassword: encryptedOldPassword,
    };
    const url = `${environment.apiUrl}users/me/change-password_`;
    return this.http.put(url, chnagePasswordBody);
  }

  changeUserPassword(id, password, role, name) {

    const encryptedPassword = this.encryptionService.encryptData(password)

    const changePasswordBody = {
      id,
      encryptedPassword,
    };
    if (role === 'ROLE_SUBUSER') {
      // const url = `${environment.apiUrl}users/subUser/change-password/?id=${id}&password=${password}&name=${name}`;
      const url = `${environment.apiUrl}users/subUser/change-password`;
      return this.http.put(url, changePasswordBody).pipe();
    } else if (role === 'ROLE_FIELDWORKER') {
      // const url = `${environment.apiUrl}users/field/change-password/?id=${id}&password=${password}&name=${name}`;
      const url = `${environment.apiUrl}users/field/change-password`;
      return this.http.put(url, changePasswordBody).pipe();
    }
  }

  toggleActivation(id) {
    const url = `${environment.apiUrl}users/clients/toggle?id=${id}`;
    return this.http.post(url, {}).pipe();
  }
}
