export enum LoaderTypes {
  addressFetch = 'ADDRESSFETCH',
  riskDetailsFetch = 'RISKFETCH',
  riskCategoriesFetch = 'RISKCATEGORIESFETCH',
}

export enum BufferType {
  stationS = 'STATIONS',
  rivers = 'RIVERS',
  oceans = 'OCEANS',
}
