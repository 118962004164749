import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Observer,
  Subscriber,
  Subscription,
} from 'rxjs';
import { MapAddressesService } from 'src/app/shared/services/map/map-addresses.service';

@Component({
  selector: 'app-address-list',
  templateUrl: './address-list.component.html',
  styleUrls: ['./address-list.component.scss'],
})
export class AddressListComponent implements OnInit, OnDestroy {
  @Output() addressClicked: EventEmitter<{latitude; longitude; status;}> = new EventEmitter();

  addressListObservable$: Observable<any[]> = this.mapAddressService.getAddressList();

  // loading observable
  loading$ = this.mapAddressService.getLoading();

  constructor(private mapAddressService: MapAddressesService) {}

  ngOnInit(): void {
    this.loading$.subscribe((data) => {
      // console.log(data);
    });
  }

  addressItemClick(address): void {
    if (address) {
      const sidebar = false;
      const { latitude, longitude, status } = address;
      this.addressClicked.emit({ latitude, longitude, status });
      // set current address
      this.mapAddressService.updateCurrentAddress(address);
    }
  }

  ngOnDestroy(): void {}
}
