<ul class="filter-container">
  <li class="filter-label">
    <span class="">Date Range</span>
  </li>

  <li class="range-filter">
    <mat-date-range-input style="display: none" [formGroup]="dateRange" [rangePicker]="dateRangePicker">
      <input type="hidden" matStartDate placeholder="Start date" formControlName="start" />
      <input type="hidden" matEndDate placeholder="End date" formControlName="end" />
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="dateRangePicker">
      <img matDatepickerToggleIcon title="Filter by date" src="assets/img/calender.png" alt="" />
    </mat-datepicker-toggle>
    <mat-date-range-picker (closed)="closed()" #dateRangePicker></mat-date-range-picker>
  </li>
</ul>
