import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-map-footer',
  template: `
          <div id="polaris">
            <p>©2020 Polaris Digitech Limited. All rights reserved.</p>
          </div>
              `,
  styleUrls: ['./map-footer.component.scss']
})
export class MapFooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
