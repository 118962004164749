import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  Input
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { MapAddressesService } from 'src/app/shared/services/map/map-addresses.service';
import { AddressSearchComponent } from './address-search-component/address-search.component';
import { GoogleMapComponent } from './google-map-component/googleMap.component';
import { StreetviewComponent } from './streetview/streetview.component';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(GoogleMapComponent, { static: false }) mapComponent: GoogleMapComponent;
  @ViewChild(AddressSearchComponent, { static: false }) searchComponent: AddressSearchComponent;
  @ViewChild('right_panel', { static: false }) rightPanel: MatSidenav;
  @ViewChild(StreetviewComponent, { static: false }) streetviewComponent: StreetviewComponent;
  @ViewChild (GoogleMapComponent) check;

  constructor(private mapAddressService: MapAddressesService, private toaster: ToastrService,) {}

  showRiskPanel = false;
  downloadLoader: boolean;

  addressListObservable$: Observable<any[]> = this.mapAddressService.getAddressList();

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {

    // setInterval(()=>{
    //   console.log(this.check.value);
    //   // this.check.google.map.event.addListener(this.check.infowindow, 'closeclick', this.onclosed)
    // },15000)
    this.mapComponent.map.setStreetView(this.streetviewComponent.panorama);
  }

  onclosed() {
    // console.log('closed');
  }
  ngOnDestroy(): void {
    this.mapAddressService.updateAddressObservable([]);
  }

  receive($event) {

  }
  /**
   *
   * @param event: {latitude: number; longitude; status: number}
   * @description Pan the map to an address location and add marker with a custom icon
   * @returns null
   */
  panMapOnAddressClick(event): void {
    const map = this.mapComponent.map;
    const { latitude, longitude, status } = event;
    // Pans map to a particular location
      this.rightPanel.close();
    map.panTo({ lat: latitude, lng: longitude });
    map.setZoom(20);
  }

  onDatePickerClosed(): void {
    this.searchComponent.searchInput.reset();
  }

  rightPanelClosed(event): void {
    this.showRiskPanel = event;
  }

  closePanelOnNewMarkerClick(): void {
    this.mapAddressService.updateRiskBoundary(null);
    this.mapAddressService.updateBufferRingsObservable(null);
    this.rightPanel.close();
  }

  openRightPanel(): void {
    this.rightPanel.open().then(() => {
    });
  }

  // closeRightPanel(event:any) {
  //   console.log(event);
  //   if (event = true) {
  //     this.mapAddressService.updateRiskBoundary(null);
  //     this.rightPanel.close();
  //   }else{
  //     this.openRightPanel();
  //   }
  // }

  exportCsv(){
    this.downloadLoader = true;

    this.mapAddressService.downloadAddressDataFilter()

    .subscribe(
      (res: any) => {
        // console.log(res);
        const file = new File([res.body], 'Risk-data' + '.xlsx', {
          type: 'application/vnd.ms-excel;charset=utf-8',
        });
        this.downloadLoader = false;
        if (res.status === 200) {
          this.toaster.success('success');
          saveAs(file, 'Risk-data.xlsx');
        } else if (res.status === 400) {
          this.toaster.warning('No data return', 'Caution');
        }
      },
      (error) => {
        this.downloadLoader = false;
        this.toaster.warning(error, 'Failed');
      }
    );
  }
}
