import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoaderTypes } from '@app/shared/models/map.model';
import { Observable, Subscribable, Subscription } from 'rxjs';
import { MapAddressesService } from 'src/app/shared/services/map/map-addresses.service';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements OnInit, OnDestroy {
  // risk expansion observable
  riskExpansionPanelOpened$: Observable<any> = this.mapAddressService.getRiskExpansionPanelOpened();

  // loading observable
  loading$ = this.mapAddressService.getLoading();
  // risk categories observable
  riskCategoriesObservable$ = this.mapAddressService.getRiskCategories();

  // risk details observable
  riskDetailsObservable$ = this.mapAddressService.getRiskDetails();
  // subcriptions
  riskExpansionOpenedSubscription: Subscription;
  riskCategoriesSubscription: Subscription;
  riskDetailsSubcription: Subscription;

  // expansion panel to be opened
  toBeOpened = null;
  riskCategories = [];
  riskDetailsHeaders: any[] = [];
  riskDetailsBody: any[] = [];
  constructor(private mapAddressService: MapAddressesService) {}

  ngOnInit(): void {
    this.riskExpansionOpenedSubscription = this.riskExpansionPanelOpened$.subscribe(
      (data) => {
        this.toBeOpened = data;
      }
    );

    this.riskCategoriesSubscription = this.riskCategoriesObservable$.subscribe(
      (data) => {
        this.riskCategories = [
          ...data,
          { FIRE: 'Fire' },
          { RIVER: 'River' },
          { OCEAN: 'Ocean' },
        ];
      }
    );

    this.riskDetailsSubcription = this.riskDetailsObservable$.subscribe(
      (data: any[]) => {
        this.riskDetailsBody = data && this.generateRiskDetailsBody(data);

        this.riskDetailsHeaders =
          data && this.generateRiskHeaders(this.riskDetailsBody[0]);

        // update loader to stop loading
        this.mapAddressService.updateLoading(
          false,
          LoaderTypes.riskDetailsFetch
        );
      }
    );
  }

  ngOnDestroy(): void {
    this.riskExpansionOpenedSubscription.unsubscribe();
    this.riskCategoriesSubscription.unsubscribe();
  }

  getKeyValue(obj): any {
    return Object.entries(obj);
  }

  disablePanel(val): boolean {
    return this.toBeOpened !== val;
  }
  expandPanel(val): boolean {
    return this.toBeOpened === val;
  }

  riskIndexColorization(index: number): string {
    switch (index) {
      case 1:
        return '#00FF7F';
      case 2:
        return '#FFFF00';
      case 3:
        return '#ffc000';
      case 4:
        return '#e36c0a';
      case 5:
        return '#FF0000';
      default:
        return '#000000';
    }
  }

  private generateRiskHeaders(data: any): any[] {
    // return the keys of the return object ask header except for id;

    return Object.keys(data);
  }

  private generateRiskDetailsBody(data: any): any[] {
    // remove id property from the body
    return data.map((d: any) => {
      if (d.hasOwnProperty('id')) {
        const { id, ...rest } = d;
        return rest;
      } else {
        return d;
      }
    });
  }
}
