import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroupDirective, NgForm, ValidatorFn, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { GisUserService } from 'src/app/shared/services/userService/gis-user.service';
import { CreateFieldworkerComponent } from '../../fieldworker/create-fieldworker/create-fieldworker.component';

@Component({
  selector: 'app-create-gisuser',
  templateUrl: './create-gisuser.component.html',
  styleUrls: ['./create-gisuser.component.scss']
})
export class CreateGisuserComponent implements OnInit {

  gisuserForm = this.fb.group(
    {
      fullname: ['', Validators.required],
      username: ['', Validators.required],
      email:    ['', Validators.required],
      password: [null, [Validators.required, this.patternValidator()]],
    }
  );
  hidePassword = true;
  hideConfirmPassword = true;
  isLoading: boolean;



  constructor(
    public dialog: MatDialogRef<CreateFieldworkerComponent>,
    private fb: FormBuilder,
    private gService: GisUserService,
    public toastr: ToastrService
  ) {}

  ngOnInit(): void {}

  patternValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const regex = new RegExp(
        '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*[@#$%!~^&*()_+=?<>,.]).{8,}$'
      );
      const valid = regex.test(control.value);
      return valid ? null : { invalidPassword: true };
    };
  }

  onCreateGisUser(): void {
    this.isLoading = true;
    this.gService.createGisUser(this.gisuserForm.value).subscribe(
      (res: any) => {
        // console.log(res);
        this.isLoading = false;
        if (res.status === 0) {
          this.dialog.close();
          this.toastr.success(res.message);
        } else if (res.status === 1) {
          this.toastr.warning(res.message);
        }
      },
      (error) => {
        this.isLoading = false;
        // console.log(error);
      }
    );
  }

  closeModal(): void {
    this.dialog.close();
  }

  getErrorMessage()  {
    if (this.gisuserForm.get('email').hasError('required')) {
      return 'Email is required';
    }
    return this.gisuserForm.get('email').hasError('email') ? 'Not a valid email' : '';
  }
}
