import { Component, OnInit, AfterContentInit } from '@angular/core';
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormArray
} from "@angular/forms";
import { ToastrService} from 'ngx-toastr';
import { MatDialogRef } from '@angular/material/dialog';

import { AddressStructureService } from '../../../../shared/services/projectService/address-structure.service';
import { AddressStructureComponent } from '../address-structure.component';

@Component({
  selector: 'app-create-field',
  templateUrl: './create-field.component.html',
  styleUrls: ['./create-field.component.scss']
})
export class CreateFieldComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private aStructure: AddressStructureService,
    private toastr: ToastrService,
    private matRef: MatDialogRef<CreateFieldComponent>
  ) { }

  extraField: FormGroup;
  disabled;
  loading;
  

  typesArray = ["text", "number", "image", "date",
  "select", "check", "radio"
];

  
  ngOnInit(): void {
    this.extraField = this.fb.group({
      fieldName: this.fb.control('', Validators.required),
      required: this.fb.control('', Validators.required),
      fieldType: this.fb.control('', Validators.required),
      options: this.fb.array([])
    });

    // console.log(this.extraField.value);
  }

  showOptionField(extraField: FormGroup) {
    const fieldChosen = extraField.get("fieldType").value;
    
    return fieldChosen === "radio" ||
      fieldChosen === "check" ||
      fieldChosen === "select"
      ? true
      : false;
  }

  deleteOption(index) {
    this.options.removeAt(index);
  }

  get options() {
    return this.extraField.get("options") as FormArray;
  }

  addOption() {
      this.options.push(
        this.fb.group({value:''})
      );
  }

  onSubmit(){
    this.loading= true;
    this.disabled = true;
    this.aStructure.setStructure([this.extraField.value]).subscribe(
      (res) => {
        // console.log(res);
        if (res["status"] === 0 ) {
          this.toastr.success(res['message'], 'Success');
          this.disabled = true;
          this.loading= true;
          this.matRef.close(true);
        }else{
          this.toastr.error(res["message"], 'Error');
          this.disabled = false;
          this.loading = false;
        }
      },
      error =>  {
        this.toastr.error('Error', error);
        this.disabled = false;
        this.loading = false;
      }
    );
  }
  
}
