<div class='container'>

  <div class="addUserBtn_container">
    <button (click)='createUserModal()' class="add-user-btn" mat-button>
      <mat-icon>group_add</mat-icon>
      <span>&nbsp; Create Staff </span>
    </button>
  </div>

  <mat-card class="card">
    <mat-card-header>
      <mat-card-title>Office Staff Management</mat-card-title>
    </mat-card-header>
    <mat-divider></mat-divider>
    <br />

    <mat-card-content>

      <mat-form-field>
        <mat-label>
          Search ......................<mat-icon>search</mat-icon>
        </mat-label>
        <input matInput placeholder="Search Office Staff" [formControl]="searchControl">
        <button type="button" mat-icon-button matSuffix (click)="clearAllFilter()">
          <mat-icon aria-hidden="false" aria-label="Clear filter" color="warn">backspace</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field style="width: 7rem !important; float: right;">
        <mat-label>Status</mat-label>
        <mat-select name="status" [(ngModel)]="status" (selectionChange)="statusChange($event.value)">
          <mat-option *ngFor="let status of statusOptions" [value]='status.code'>{{status.name}}</mat-option>
        </mat-select>
      </mat-form-field>

      <div class="staff-container mat-elevation-z8">

        <div class="table-loading-shade" *ngIf="isLoadingResults$ | async">
          <mat-spinner class="custom-spinner"></mat-spinner>
        </div>

        <div class='staff-table-container'>
          <table mat-table [dataSource]="subUser" matSort matSortActive matSortDisableClear matSortDirection="desc">

            <!-- Fullname Column -->
            <ng-container matColumnDef="fullname">
              <th mat-header-cell *matHeaderCellDef> FullName </th>
              <td mat-cell *matCellDef="let data"> {{data.fullname}} </td>
            </ng-container>

            <!-- Username Column -->
            <ng-container matColumnDef="username">
              <th mat-header-cell *matHeaderCellDef> Username </th>
              <td mat-cell *matCellDef="let data"> {{data.username}} </td>
            </ng-container>

            <!-- Email Column -->
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef> Email </th>
              <td mat-cell *matCellDef="let data"> {{data.email}} </td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>
                Status
              </th>
              <td mat-cell *matCellDef="let data">

                <mat-icon *ngIf="data.isAccountNonLocked" style="color: rgb(32, 165, 19);">verified_user</mat-icon>

                <mat-icon *ngIf="!data.isAccountNonLocked" style="color: orangered;">unpublished</mat-icon>
              </td>
            </ng-container>

            <!-- Action Column -->
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef> Actions </th>
              <td mat-cell *matCellDef="let data">

                <mat-menu #appMenu="matMenu">
                  <button (click)="changeToggle(data.id, 'Deactivate')" *ngIf="data.isAccountNonLocked" mat-menu-item
                    class="userActionTableBtn">
                    Deactivate User
                  </button>
                  <button (click)="changeToggle(data.id, 'Activate')" mat-menu-item class="userActionTableBtn"
                    *ngIf="!data.isAccountNonLocked">
                    Activate User
                  </button>
                  <button mat-menu-item (click)="changePassword(data.id, data.username, data.roles[0].name)"> Change
                    Password </button>

                </mat-menu>

                <button mat-icon-button color="primary" [matMenuTriggerFor]="appMenu">
                  <mat-icon>more_vert</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let data; columns: displayedColumns;"></tr>
          </table>
        </div>

        <mat-paginator [pageSizeOptions]="pageSizeOptions" [length]="totalStaffCount" [pageSize]="pageSize"
          showFirstLastButtons></mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>
</div>
