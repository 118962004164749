<h1>Options Creation</h1>
<mat-divider></mat-divider>

<form [formGroup]="extraOptions" class='form'>
    <!-- <button
    mat-button
    type="button"
    (click)="addOption()"
    class='addOption'>Add Option </button> -->

  <div formArrayName="options" class='options'>
    <div *ngFor="let item of options.controls; let pointIndex=index" [formGroupName]="pointIndex">
    <label>
      <input class='optionField' formControlName="value" placeholder="Enter option"/>
    </label>
    <button type="button"
     style="color: red; font-size: 1rem"
     mat-icon-button
     (click)="deleteOption(pointIndex)">
        <mat-icon>delete_outline</mat-icon>
    </button>

    </div>

  </div>

  <div class='addOption'>
    <button
    class='addOption'
      mat-raised-button

      type="button"
      (click)="submitOptions()"
      [disabled]='disabled'
      >
      <mat-spinner *ngIf="loading" class="custom-spinner" diameter="20"></mat-spinner>
      Submit</button>
  </div>

  <div class='button'>
    <button mat-mini-fab
    style="background-color: #093256; float:right"
    (click)='addOption()' mat-icon-button color="primary"
      aria-label="add address field">
      <mat-icon>add</mat-icon>
    </button>
  </div>
</form>
