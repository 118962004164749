import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthenticationService } from 'src/app/shared/services/authService/authentication.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  error = '';
  hide = true;
  disable;
  loading;

  constructor(
    public toastr: ToastrService,
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder
  ) {
    // redirect to map if already logged in
    // if (this.authService.userValue) {
    //   this.router.navigate(['/map']);
    // }
  }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.loginForm.invalid) {

      this.toastr.error("Both fields are required", "Failed!");
      this.loading = false;
      this.disable = false;

    } else {
      this.disable = true;
      this.loading = true;
      this.authService.login(this.f.username.value, this.f.password.value)
        .pipe(first())
        .subscribe({
          next: (res) => {
            // console.log(res);
            if (res.firstTimeLoggedIn === true) {
              this.router.navigate(['/change-password']);
            }else{
              // get return url from query parameters or default to map page
              const returnUrl = this.route.snapshot.queryParams.returnUrl || '/map';
              this.router.navigateByUrl(returnUrl);
            }

          },
          error: (error) => {
            // console.log(error);
            this.loading = false;
            this.disable = false;
            this.toastr.error( error, 'Failed!');
          },
        });
    }
    
  }
}
