import { Component, OnInit, NgZone, AfterViewInit } from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import { ProjectService } from 'src/app/shared/services/projectService/project.service';
import { any } from '@amcharts/amcharts4/.internal/core/utils/Array';
am4core.useTheme(am4themes_animated);
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, AfterViewInit {
  isData = false;
  state = '';
  lga = '';
  barState = '';
  chartTime: am4charts.GaugeChart;
  chartPie: am4charts.PieChart;
  chartBar: am4charts.XYChart3D;
  failedCount;
  assignedCount;
  geocodedCount;
  isLoading = false;
  lgas=[];
  states = [
    // 'All',
    // 'Abia',
    // 'Adamawa',
    // 'Akwa Ibom',
    // 'Anambra',
    // 'Bauchi',
    // 'Bayelsa',
    // 'Benue',
    // 'Borno',
    // 'Cross River',
    // 'Delta',
    // 'Ebonyi',
    // 'Edo',
    // 'Ekiti',
    // 'Enugu',
    // 'FCT - Abuja',
    // 'Gombe',
    // 'Imo',
    // 'Jigawa',
    // 'Kaduna',
    // 'Kano',
    // 'Katsina',
    // 'Kebbi',
    // 'Kogi',
    // 'Kwara',
    // 'Lagos',
    // 'Nasarawa',
    // 'Niger',
    // 'Ogun',
    // 'Ondo',
    // 'Osun',
    // 'Oyo',
    // 'Plateau',
    // 'Rivers',
    // 'Sokoto',
    // 'Taraba',
    // 'Yobe',
    // 'Zamfara',
  ];

  constructor(private zone: NgZone, private pService: ProjectService) {}

  userType = 0;
  userId = 0;

  ngOnInit(): void {
    this.getAddressCounts();
    this.getState()
    // this.getPieData();
  }

  ngAfterViewInit() {
    this.getDoughnutChart();
    this.getBarChart();
    this.getPieChart();
  }

  stateTypeChange(stateValue) {
    // console.log(stateValue);
    if (stateValue === 'All') {
      this.state = '';
      this.getDoughnutChart();
    } else {
      this.state = stateValue;
      this.getDoughnutChart();
      this.getLga(this.state);
      
    }
  }

  lgaTypeChange(lgaValue) {
    console.log(this.lga);
    this.lga = lgaValue;
    this.getDoughnutChart();
    
  }

  barStateTypeChange(stateValue) {
    // console.log(stateValue);
    if (stateValue === 'All') {
      this.barState = '';
      this.getBarChart();
    } else {
      this.barState = stateValue;
      this.getBarChart();
    }
  }

  getState(){
    this.pService.getState().subscribe((res:any) => {
      // console.log(res);
      this.states = res.labels;
    })
  }
   getLga(state) {
     this.pService.getLga(state).subscribe((res:any) => {
      //  console.log(res);
       this.lgas = res.labels;
     })
   }


  getAddressCounts() {
    this.isLoading = true;
    this.pService.getCounts().subscribe((res: any) => {
      // console.log(res);
      this.isLoading = false;
      if (res.addressCount.assigned > 0) {
        this.assignedCount = res.addressCount.assigned;
      } else {
        this.assignedCount = 0;
      }
      if (res.addressCount.failed > 0) {
        this.failedCount = res.addressCount.failed;
      } else {
        this.failedCount = 0;
      }
      if (res.addressCount.geocoded > 0) {
        this.geocodedCount = res.addressCount.geocoded;
      } else {
        this.geocodedCount = 0;
      }
    });
  }

  getDoughnutChart() {
    // console.log(this.lga);
    this.pService.getPieData(this.state, this.lga).subscribe((res: any) => {
      let data = res.addressChart;
      // console.log(this.lga);
      if (res.addressCount === {}) {
        this.isData = true;
      } else {
        this.zone.runOutsideAngular(() => {
          const chartPie: any = am4core.create('pieChart', am4charts.PieChart);
          chartPie.hiddenState.properties.opacity = 0; // this creates initial fade-in
          chartPie.data = data;
          const pieSeries = chartPie.series.push(new am4charts.PieSeries());
          pieSeries.colors.list = [
            am4core.color('#F53C56'),
            am4core.color('#2DCE98'),
            am4core.color('#11CDEF'),
          ];
          pieSeries.dataFields.value = 'value';
          pieSeries.dataFields.category = 'label';
          pieSeries.innerRadius = am4core.percent(40);
          pieSeries.ticks.template.disabled = true;
          pieSeries.labels.template.disabled = true;

          const rgm = new am4core.RadialGradientModifier();
          rgm.brightnesses.push(-0.8, -0.8, -0.5, 0, -0.5);
          pieSeries.slices.template.fillModifier = rgm;
          pieSeries.slices.template.strokeModifier = rgm;
          pieSeries.slices.template.strokeOpacity = 0.4;
          pieSeries.slices.template.strokeWidth = 0;

          chartPie.legend = new am4charts.Legend();
          chartPie.legend.position = 'bottom';

          // Enable export
          // chartPie.exporting.menu = new am4core.ExportMenu();

          this.chartPie = chartPie;
        });
      }
    });
  }

  getBarChart() {
  this.pService.getLineData(this.barState).subscribe((res: any)=>{
  
    let data = res.addressChart;
    // console.log(data);

    this.zone.runOutsideAngular(() => {
      let chart = am4core.create("lineChart", am4charts.XYChart3D);
      chart.data= data;

      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "label";
      categoryAxis.renderer.labels.template.rotation = 270;
      categoryAxis.renderer.labels.template.hideOversized = false;
      categoryAxis.renderer.minGridDistance = 20;
      categoryAxis.renderer.labels.template.horizontalCenter = "right";
      categoryAxis.renderer.labels.template.verticalCenter = "middle";
      categoryAxis.tooltip.label.rotation = 270;
      categoryAxis.tooltip.label.horizontalCenter = "right";
      categoryAxis.tooltip.label.verticalCenter = "middle";

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.title.text = "Counts";
      valueAxis.title.fontWeight = "bold";

      // Create series
      let series = chart.series.push(new am4charts.ColumnSeries3D());
      series.dataFields.valueY = "value";
      series.dataFields.categoryX = "label";
      series.name = "Count";
      series.tooltipText = "{categoryX}: [bold]{valueY}[/]";
      series.columns.template.fillOpacity = .8;

      let columnTemplate = series.columns.template;
      columnTemplate.strokeWidth = 2;
      columnTemplate.strokeOpacity = 1;
      columnTemplate.stroke = am4core.color("#FFFFFF");

      columnTemplate.adapter.add("fill", function(fill, target) {
        return chart.colors.getIndex(target.dataItem.index);
      })

      columnTemplate.adapter.add("stroke", function(stroke, target) {
        return chart.colors.getIndex(target.dataItem.index);
      })

      chart.cursor = new am4charts.XYCursor();
      chart.cursor.lineX.strokeOpacity = 0;
      chart.cursor.lineY.strokeOpacity = 0;

      // Enable export
      // chart.exporting.menu = new am4core.ExportMenu();
  
      this.chartBar = chart;
    })

  })

  

}

getPieChart(){
  this.pService.getTimeOutData().subscribe((res: any)=>{
    console.log(res);
    let data = res.allocation;
  
  this.zone.runOutsideAngular(() => {
    let chart = am4core.create("chartdivTimeOut", am4charts.PieChart3D);
chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

chart.data = data;

chart.innerRadius = am4core.percent(30);
chart.depth = 50;

chart.legend = new am4charts.Legend();

let series = chart.series.push(new am4charts.PieSeries3D());
series.colors.list = [
  am4core.color('#F53C56'),
  am4core.color('#11CDEF')
]
series.dataFields.value = "value";
series.dataFields.depthValue = "value";
series.dataFields.category = "label";
series.slices.template.cornerRadius = 5;
series.colors.step = 1;
  })

})
}

}