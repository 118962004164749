import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProjectService } from '@app/shared/services/projectService/project.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-duplicate-address',
  templateUrl: './duplicate-address.component.html',
  styleUrls: ['./duplicate-address.component.scss']
})
export class DuplicateAddressComponent implements OnInit {

  addressDuplicateForm: FormGroup;
  isLoading;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data, public dialog: MatDialogRef<DuplicateAddressComponent>,
    private fb: FormBuilder,
    private addressService: ProjectService,
    public toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.addressDuplicateForm = this.fb.group(
      {
        address_id: [this.data],
        newGeocodingId: ['', Validators.required],
      }
    );
  }

  onFormSubmit(){
    this.isLoading = true;
    this.addressService.duplicateAddress(this.addressDuplicateForm.value).subscribe(
      (res: any) => {
        this.isLoading = false;
        if (res.status === 0) {
          this.dialog.close('make-request');
          this.toastr.success(res.message, 'Success');
        } else if (res.status === 1) {
          this.toastr.warning(res.message, 'Caution');
        }
      },
      (error) => {
        this.isLoading = false;
        this.toastr.error(error, 'Failed')
      }
    );

  }

  closeModal(response:string): void {
    this.dialog.close(response);
  }

}
