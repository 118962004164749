import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { AuthenticationService } from '../../../shared/services/authService/authentication.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss'],
})
export class ForgetPasswordComponent implements OnInit {
  disable;
  loading;
  public detail = { email: '' };
  constructor(
    public toastr: ToastrService,
    public AuthService: AuthenticationService
  ) {}

  ngOnInit(): void {}

  forgetPassword(): void {
    if (!this.detail.email || this.detail.email.trim().length <= 0){
      this.toastr.error("Fields is required", "Failed!");
      this.loading = false;
      this.disable = false;
    }else{
      this.loading= true;
    this.disable = true;
    this.AuthService.forgetPassword(this.detail.email).subscribe(
      (res) => {
        // console.log(res);
        if (res['status'] === 0) {
          this.toastr.success( res['message'], 'Success' );
          this.disable = true;
          this.loading = true;
        } else {
          this.toastr.error(res['message'], 'Error');
          this.loading= false;
          this.disable = false;
        }
      },
      (err) => {
          this.loading= false;
          this.disable = false;
          this.toastr.error(err, 'Failed!');
        }
    );
    }
    
  }
}
