import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { SubUserService } from '../../../../shared/services/userService/sub-user.service';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  disable: boolean;
  isLoading: boolean;

  // auto generate password helpers
  dictionary: Array<string>;
  passwordLenght = 15;
  buttonLabel = 'Generate';
  newPassword: string;
  lowercase = true;
  uppercase = true;
  numbers = true;
  symbols = true;
  passwordXters = [
    {
      library: 'abcdefghijklmnopqrstuvwxyz',
    }, {
      library: 'ABCDEFGHIJKLMNOPWRSTUVWXYZ',
    }, {
      library: '0123456789',
    }, {
      library: '!@#$%^&*-_=+\\|:;\',.\<>/?~',
    }
  ];
  // Copy password to clipboard
  @ViewChild('passwordWithValidation') password: ElementRef;
  private copyPassword() {
    const inputElement = this.password.nativeElement as HTMLInputElement;
    inputElement.select();
    document.execCommand('copy');
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    private uService: SubUserService,
    private fb: FormBuilder,
    public toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.changePasswordForm = this.fb.group({
      newPassword: ['', [Validators.required, this.patternValidator()]],
    });
  }

  patternValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const regex = new RegExp(
        '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*[@#$%!~^&*()_+=?<>,.]).{8,}$'
      );
      const valid = regex.test(control.value);
      return valid ? null : { invalidPassword: true };
    };
  }

  ChangePassword() {
    const password = this.changePasswordForm.value.newPassword;
    // console.log(password);
    if (!password || password.trim().length <= 0) {
      this.toastr.error('Fields required');
      this.disable = false;
    } else {
      this.uService
        .changeUserPassword(
          this.data.id,
          password,
          this.data.user_role,
          this.data.name
        )
        .subscribe(
          (res) => {
            // console.log(res);
            if (res['status'] === 0) {
              this.toastr.success(res['message'], 'Success');
              this.dialogRef.close();
              this.disable = true;
            } else {
              this.toastr.error(res['message'], 'Error');
              this.disable = false;
            }
          },
          (err) => {
            this.disable = false;
            this.dialogRef.close();
          }
        );
    }
  }

  // Generate password
  public generatePassword() {
    if (this.lowercase === false && this.uppercase === false && this.numbers === false && this.symbols === false) {
      return this.newPassword = '...';
    }

    // Create array from chosen checkboxes
    this.dictionary = [].concat(
      this.lowercase ? this.passwordXters[0].library.split('') : [],
      this.uppercase ? this.passwordXters[1].library.split('') : [],
      this.numbers ? this.passwordXters[2].library.split('') : [],
      this.symbols ? this.passwordXters[3].library.split('') : []
    );

    // Generate random password from array
    let newPassword = '';
    for (let i = 0; i < this.passwordLenght; i++) {
      newPassword += this.dictionary[Math.floor(Math.random() * this.dictionary.length)];
    }

    this.newPassword = newPassword;
    this.changePasswordForm.patchValue({
      newPassword: this.newPassword
    });

    // Call copy function
    setTimeout(() => this.copyPassword());

    // Change text on button when clicked
    this.buttonLabel = 'Copied!';
    setTimeout(() => { this.buttonLabel = 'Generate'; }, 1500);
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
