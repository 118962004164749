import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, merge, Observable, Subscription } from 'rxjs';
import { startWith, switchMap, tap } from 'rxjs/operators';
import { fieldWorkerhttpResModel } from 'src/app/shared/models/user.model';
import { FieldworkerService } from 'src/app/shared/services/userService/fieldworker.service';
import { ConfirmationComponent } from '../../confirmation/confirmation.component';
import { CreateFieldworkerComponent } from './create-fieldworker/create-fieldworker.component';
import { ChangePasswordComponent } from '../user-management/change-password/change-password.component';
import { FieldworkerProgressComponent } from './fieldworker-progress/fieldworker-progress.component';


@Component({
  selector: 'app-fieldworker',
  templateUrl: './fieldworker.component.html',
  styleUrls: ['./fieldworker.component.scss'],
})
export class FieldworkerComponent implements OnInit {
  isLoadingResults$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  fieldWorkers: Observable<fieldWorkerhttpResModel[]>;
  displayedColumns: string[] = [
    'fullname',
    'username',
    // 'email',
    // 'phoneNumber',
    // 'gender',
    'status',
    // 'address',
    // 'accountNumber',
    'action',
  ];
  searchControl: FormControl = new FormControl('');
  status = -1;
  statusOptions = [
    { code: -1, name: 'All' },
    { code: 1, name: 'Activated' },
    { code: 0, name: 'Deactivated' }
  ];
  search = '';
  totalfieldWorkerCount;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 20, 30, 50, 100, 300];
  sortSub$: Subscription;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    private fService: FieldworkerService,
    private toaster: ToastrService
  ) {}

  ngOnInit(): void {
    this.fieldWorkers = this.fService.getFieldWorkers();
    // console.log(this.fieldWorkers);

    this.searchControl.valueChanges.subscribe((value) => {
      // console.log(value);
      this.search = value;
      this.paginator.page.emit();
    });
  }

  private updateLoadingStatus(status: boolean) {
    this.isLoadingResults$.next(status);
  }

  ngAfterViewInit() {
    this.sortSub$ = this.sort.sortChange.subscribe(
      () => (this.paginator.pageIndex = 0)
    );
    merge(this.paginator.page, this.sort.sortChange)
      .pipe(
        tap((value) => {
          // console.log(value);
        }),
        startWith({}),
        switchMap(() => {
          this.updateLoadingStatus(true);
          return this.fService.getAllFieldWorkers(
            this.paginator.pageIndex,
            this.paginator.pageSize,
            this.search,
            this.status
          );
        })
      )
      .subscribe(
        (res) => {
          // console.log(res);
          this.fService.updateFieldWorker(res.users);
          this.totalfieldWorkerCount = res.count;
          this.updateLoadingStatus(false);
        },
        (error) => {
          // console.log(error);
        }
      );
  }

  updateFieldWorkerTable() {
    this.fService
      .getAllFieldWorkers(this.paginator.pageIndex, this.paginator.pageSize, this.search, this.status)
      .subscribe(
        (res: any) => {
          this.fService.updateFieldWorker(res.users);
          this.totalfieldWorkerCount = res.count;
          this.updateLoadingStatus(false);
        },
        (error) => {
          this.updateLoadingStatus(false);
          // console.log(error);
        }
      );
  }

  createFieldworkerModal(): void {
    const dialogRef = this.dialog.open(CreateFieldworkerComponent, {
      minWidth: '15rem',
      maxWidth: '25rem',
      height: '31rem',
    });
    dialogRef.afterClosed().subscribe(() => {
      this.updateFieldWorkerTable();
    });
  }

  changePassword(id, user_role, name): void{
    // console.log(id, user_role, name);
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      minWidth: '18rem',
      minHeight: '17rem',
      data: {id, name, user_role},
    });
  }

  viewCount(id: number, name: string): void{
    const dialogRef = this.dialog.open(FieldworkerProgressComponent, {
      minWidth: '50rem',
      minHeight: '20rem',
      data: {id, name}
    });
  }

  changeToggle(id: number, status: string) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: { action: `${status} Field Agent` },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'cancel') {
      } else if ('ok') {
        this.fService.toggleActivation(id, 'fieldWorker').subscribe(
          (res: { status: number; message: string }) => {
            if (res.status === 0) {
              this.toaster.success(res.message);
              this.updateFieldWorkerTable();
            } else {
              this.toaster.warning(res.message);
            }
          },
          (error) => {
            // console.log(error);
          }
        );
      }
    });
  }

  statusChange(status): void{
    this.status = status;
    this.updateFieldWorkerTable();
   }

  clearAllFilter() {
    this.search = '';
    this.paginator.page.emit();
    this.searchControl.setValue('');
  }
}
