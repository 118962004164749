import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SubUserService } from '@app/shared/services/userService/sub-user.service';

@Component({
  selector: 'app-change-token',
  templateUrl: './change-token.component.html',
  styleUrls: ['./change-token.component.scss']
})
export class ChangeTokenComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ChangeTokenComponent>,
    private fb: FormBuilder,
    public userService: SubUserService,
    public toastr: ToastrService
  ) { }

  changeTokenForm = this.fb.group({
    token: [''],
    password: ['', Validators.required]
  });

  hidePassword=true;
  password = true;
  showPassword=false;
  tokenValue;
  isLoading=false;

  ngOnInit(): void {
    this.userService.getToken().subscribe((res: any)=> {
      this.tokenValue = res.message;
    })
  }

  confirmwithPassword() {
    this.isLoading = true;
   const password = this.changeTokenForm.value.password;
    this.userService.getNewToken(password).subscribe((res:any)=>{
      this.isLoading = false;
      if (res.status==0){
        this.tokenValue=res.message;
        this.hidePassword=false;
      } else {
        this.toastr.error(res.message);
      }
      
    },
    (err)=>{
      this.toastr.error(err);
      this.isLoading = false;
    })
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
