import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

const url = environment.apiUrl + 'users/clients';

interface ClientDTO {
  email: string;
  logo: FormData;
  password: string;
  username: string;
  companyName: string;
}

@Injectable()
export class ClientManagementService {
  constructor(private http: HttpClient) {}

  createClient({ logo, ...client }: ClientDTO) {
    return this.http.post(environment.apiUrl + 'users/create', logo, { params: client as any });
  }

  getClients() {
    return this.http.get(url);
  }

  toggleClient(id: number) {
    return this.http.post(`${url}/toggle`, null, {params: { id: (id as any) as string }});
  }
}
