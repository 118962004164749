<ul class="risk-container">
  <ng-container *ngIf="!(loading$ | async).riskCategoriesFetch; else loading">
    <li *ngFor="let riskCategory of riskCategories">
      <label>
        <input
          [disabled]="!currentAddress"
          (click)="riskDetailClicked(getKeyValue(riskCategory)[0][1])"
          type="radio"
          name="risk"
        />
        <a [title]="!currentAddress ? 'Select An Address' : ''">{{
          getKeyValue(riskCategory)[0][1]
        }}</a>
      </label>
    </li>
    <li>
      <label>
        <input
          (click)="riskDetailClicked('Fire')"
          type="radio"
          name="risk"
          [disabled]="!currentAddress"
        />
        <a [title]="!currentAddress ? 'Select An Address' : ''">Fire</a>
      </label>
    </li>
    <li>
      <label>
        <input
          (click)="riskDetailClicked('River')"
          type="radio"
          name="risk"
          [disabled]="!currentAddress"
        />
        <a [title]="!currentAddress ? 'Select An Address' : ''">River</a>
      </label>
    </li>
    <li>
      <label>
        <input
          (click)="riskDetailClicked('Ocean')"
          type="radio"
          name="risk"
          [disabled]="!currentAddress"
        />
        <a [title]="!currentAddress ? 'Select An Address' : ''">Ocean</a>
      </label>
    </li>
  </ng-container>
</ul>

<ng-template #loading>
  <div class="">
    <div class="loader-wrapper">
      <img style="height: 2rem" src="assets/img/loader.gif" />
    </div>
  </div>
</ng-template>
