<div class="all">
  <div class="container">
    <div class='flex'>
      <mat-card class="container_single">
        <mat-card-header>
          <mat-card-title>
            Single Address Upload
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <form [formGroup]="singleAddressFormGroup">
            <div class="container_single_field">
              <mat-form-field appearance='outline'>
                <mat-label>
                  Geocoding Id
                </mat-label>
                <input type='text' [ngClass]="{
                'form-control': true,
                'is-invalid': validateAddressField('transactionName')}" matInput formControlName="transactionName">
                <mat-error *ngIf="singleAddressFormGroup.get('transactionName').hasError('required')">Enter address
                  Geocoding Id</mat-error>
              </mat-form-field>
            </div>
            <div class="container_single_field">
              <mat-form-field appearance='outline'>
                <mat-label>
                  House Number
                </mat-label>
                <input type='text' matInput formControlName="houseNumber">
                <!-- <mat-error *ngIf="singleAddressFormGroup.get('houseNumber').hasError('required')">Enter address House
                  Number</mat-error> -->
              </mat-form-field>
            </div>
            <div class="container_single_field">
              <mat-form-field appearance='outline'>
                <mat-label>
                  Street Name
                </mat-label>
                <input type='text' matInput formControlName="streetName">
                <mat-error *ngIf="singleAddressFormGroup.get('streetName').hasError('required')">Enter address Street
                  Name</mat-error>
              </mat-form-field>
            </div>
            <div class="container_single_field">
              <mat-form-field appearance='outline'>
                <mat-label>
                  Area Name
                </mat-label>
                <input type='text' matInput formControlName="areaName">
                <mat-error *ngIf="singleAddressFormGroup.get('areaName').hasError('required')">Enter address Area Name
                </mat-error>
              </mat-form-field>
            </div>
            <div class="container_single_field">
              <mat-form-field appearance='outline'>
                <mat-label>
                  L.G.A
                </mat-label>
                <input type='text' matInput formControlName="lga">
                <mat-error *ngIf="singleAddressFormGroup.get('lga').hasError('required')">Enter address LGA</mat-error>
              </mat-form-field>
            </div>
            <div class="container_single_field">
              <mat-form-field appearance='outline'>
                <mat-label>
                  State
                </mat-label>
                <input type='text' matInput formControlName="state">
                <mat-error *ngIf="singleAddressFormGroup.get('state').hasError('required')">Enter address state
                </mat-error>
              </mat-form-field>
            </div>
            <div class="container_single_field" style="margin-top: 1rem">
              <button class="container_single_field_button" mat-raised-button color="primary"
                [disabled]='singleAddressFormGroup.invalid' (click)='singleUpload()'>
                Upload
                <mat-spinner *ngIf="sloading" class="custom-spinner" diameter="20"></mat-spinner>
              </button>
            </div>
          </form>
        </mat-card-content>
      </mat-card>
    </div>

    <div class='flex'>
      <mat-card class='container_double'>
        <mat-card-header>
          <mat-card-title>
            Multi Addresses Upload
            <mat-card-subtitle>
              <button mat-raised-button (click)="download()" class='dButton' [disabled]='templateDisable'>
                Download template
                <mat-spinner *ngIf="templateLoading" class="custom-spinner" diameter="20"></mat-spinner>
              </button>
            </mat-card-subtitle>
          </mat-card-title>
        </mat-card-header>

        <mat-card-content>

          <div class="container_double_uploadfilecontainer" (click)="fileInput.click()" appDragDrop (fileDropped)="uploadFile($event)">
            <p class="upload-text">Drag and Drop or Click to Upload</p>
            <input hidden type="file" #fileInput (change)="uploadFile($event.target.files)" />
          </div>

          <div class="files-list" *ngFor="let file of files; let i = index">
            <p>File: {{ file.name }}</p>
            <button mat-icon-button style="float: right;" (click)="deleteAttachment(i)">
              <img src="assets/img/trash.svg" />
            </button>
          </div>

          <div class="container_single_field">
            <button class="container_single_field_button" mat-raised-button (click)='multipleUpload()' color="primary"
              [disabled]="Ddisable">
              Upload
              <mat-spinner *ngIf="dloading" class="custom-spinner" diameter="20"></mat-spinner>
            </button>
          </div>

        </mat-card-content>

      </mat-card>
    </div>
  </div>

  <mat-card *ngIf="failed" class='failed-Addresses-Container'>
    <mat-card-header>
      <mat-card-title>Failed Uploaded Addresses</mat-card-title>
      <mat-card-subtitle>
        <button mat-raised-button color="primary" (click)="exporter.exportTable(exportType, options)">
          Export Failed Address as Excel
          <mat-icon>get_app</mat-icon>
        </button>
      </mat-card-subtitle>
    </mat-card-header>
    <mat-divider></mat-divider>

    <mat-card-content>
      <table mat-table class="mat-elevation-z8" [dataSource]="dataSource" matSort matTableExporter
        #exporter="matTableExporter">
        <ng-container matColumnDef="geocodingId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>GEOCODING ID</th>
          <td mat-cell *matCellDef="let row"> {{row.geocodingId}}</td>
        </ng-container>

        <ng-container matColumnDef="housenumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>HOUSENUMBER</th>
          <td mat-cell *matCellDef="let row">{{row.housenumber}}</td>
        </ng-container>

        <ng-container matColumnDef="areaname">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>AREANAME</th>
          <td mat-cell *matCellDef="let row">{{row.areaname}}</td>
        </ng-container>

        <ng-container matColumnDef="streetname">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>STREETNAME</th>
          <td mat-cell *matCellDef="let row">{{row.streetname}}</td>
        </ng-container>

        <ng-container matColumnDef="lga">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>LGA</th>
          <td mat-cell *matCellDef="let row">{{row.lga}}</td>
        </ng-container>

        <ng-container matColumnDef="state">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>STATE</th>
          <td mat-cell *matCellDef="let row">{{row.state}}</td>
        </ng-container>

        <ng-container matColumnDef="message">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>ERROR MESSAGE</th>
          <td mat-cell *matCellDef="let row">
            <span style="font-size: 90%; font-weight: 500;" class="badge badge-danger"> {{row.error_message}}
            </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>
    </mat-card-content>
  </mat-card>

</div>
