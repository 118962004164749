<mat-card class="card">
  <mat-menu style="padding: 0;" #appMenu="matMenu">
    <button mat-menu-item [routerLink]="['/gis-address', client.id]">
      View Addresses
    </button>
  </mat-menu>
  <button mat-icon-button color="primary" [matMenuTriggerFor]="appMenu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <img mat-card-avatar [src]="client.logo" [alt]="client.fullname" />
  <div class="wrapper">
    <div class='header'>
      <mat-card-header>
        <mat-card-title class='header-item'>{{ client.fullname }}
        </mat-card-title>
        
      </mat-card-header>
    </div>
    <mat-card-content>
      <p><strong>Email:</strong> &nbsp;{{ client.email }}</p>
      <div class="status">
        <div>
          <strong>Status:</strong> &nbsp;
          <span class="status-label">{{
            client.isAccountNonLocked ? "Activated" : "Deactivated"
          }}</span>
        </div>
        <mat-slide-toggle
          color="primary"
          (change)="toggleClient()"
          [(ngModel)]="client.isAccountNonLocked"
        ></mat-slide-toggle>
      </div>
    </mat-card-content>
  </div>
</mat-card>
