import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddClientDialogComponent } from './add-client-dialog/add-client-dialog.component';
import { Client } from './client-card/client-card.component';
import { ClientManagementService } from './client-management.service';

@Component({
  selector: 'app-client-management',
  templateUrl: './client-management.component.html',
  styleUrls: ['./client-management.component.scss']
})
export class ClientManagementComponent implements OnInit {
  loading = true;
  clients: Client[];

  constructor(
    private clientService: ClientManagementService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getClients();
  }

  getClients = () => {
    this.clientService.getClients().subscribe(({ users }: any) => {
      this.loading = false;
      this.clients = users;
    });
  };

  addClient() {
    const dialogRef = this.dialog.open(AddClientDialogComponent, {
      width: '400px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(this.getClients);
  }
}
