import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import '../../../../utils/mapboxgl-control-minimap/mapboxgl-control-minimap.js';

import { MapAddressesService } from 'src/app/shared/services/map/map-addresses.service';
import { Observable, Subscription } from 'rxjs';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import { BufferType } from '@app/shared/models/map.model.js';
import { StreetviewComponent } from '../streetview/streetview.component.js';
import { AddressStructureService } from '@app/shared/services/projectService/address-structure.service.js';
import { add } from '@amcharts/amcharts4/.internal/core/utils/Array.js';

@Component({
  selector: 'app-mapboxgl-map',
  template: `<div #mapContainer id="map"></div>`,
  styleUrls: ['./googleMap.component.scss'],
})
export class GoogleMapComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() closePanel: EventEmitter<any> = new EventEmitter<any>();
  @Output() viewRiskPanel: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('mapContainer', { static: false }) mapContainer: ElementRef;

  polygonData: any;
  // panorama gotten from parent map
  // Google Maps Options
  // value=false;
  mapOptions: google.maps.MapOptions = {
    zoom: 13,
    center: { lat: 6.448727, lng: 3.576916 },
    disableDefaultUI: true,
    fullscreenControl: true,
    backgroundColor: '#f1f1f1',
    scaleControl: true,
    streetViewControl: true,
    streetViewControlOptions: {
      position: google.maps.ControlPosition.LEFT_TOP,
    },
    zoomControl: true,
    zoomControlOptions: { position: google.maps.ControlPosition.TOP_LEFT },
    mapTypeControl: true,
    mapTypeControlOptions: {
      mapTypeIds: ['hybrid', 'roadmap', 'satellite', 'terrain'],
      position: google.maps.ControlPosition.TOP_LEFT,
      style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
    },
  };

  // Google map instance
  map: google.maps.Map = null;

  // Google map cluster
  markerClusterer: MarkerClusterer;

  // Infowindow for markers
  infowindow: google.maps.InfoWindow = new google.maps.InfoWindow();

  // BufferRadius Circles Array
  buffRings: google.maps.Circle[] = null;

  // Address List Subscription
  addressListObservable: Observable<any> =
    this.mapAddressService.getAddressList();
  // Risk boundary Observable
  riskBoundaryObservable$: Observable<any> =
    this.mapAddressService.getRiskBoundaryGeojson();

  // Static Risk Geojson Features
  staticRiskGeojsonFeatures$: Observable<any> =
    this.mapAddressService.getStaticRiskGeojsonFeatures();

  // Current Address Observable
  currentAddressObservable$: Observable<any> =
    this.mapAddressService.getCurrentAddress();

  // Buffer observable

  bufferRingsObservable$: Observable<BufferType> =
    this.mapAddressService.getBufferRingsObservable();

  // Subscriptions
  addressListSubscription: Subscription;
  staticRiskGeojsonFeatureSubscription: Subscription;
  riskBoundaryGeojsonSubscription: Subscription;
  currentAddressSubscription: Subscription;
  bufferRingsObservableSubscription: Subscription;

  // Member to hold the currentAddressValue always
  currentAddress: any = null;

  constructor(
    private mapAddressService: MapAddressesService,
    private addresStrucuteService: AddressStructureService
  ) {}

  ngOnInit(): void {
    this.riskBoundaryGeojsonSubscription =
      this.riskBoundaryObservable$.subscribe((data) => {
        if (this.map && data) {
          // remove every existing feature in data
          this.map.data.forEach((f: google.maps.Data.Feature) =>
            this.map.data.remove(f)
          );
          // adds data
          this.map.data.addGeoJson(data);
        }
      });

    this.staticRiskGeojsonFeatureSubscription =
      this.staticRiskGeojsonFeatures$.subscribe((data) => {
        if (this.map && data) {
          // remove every existing feature in data
          this.map.data.forEach((f: google.maps.Data.Feature) =>
            this.map.data.remove(f)
          );
          // adds data

          this.map.data.addGeoJson(data);
        }
      });

    // Anytime current address is updated the value is assigned to currentAddress class member
    this.currentAddressSubscription = this.currentAddressObservable$.subscribe(
      (address) => {
        this.currentAddress = address;
      }
    );

    this.bufferRingsObservableSubscription =
      this.bufferRingsObservable$.subscribe((data) => {
        if (data !== null) {
          // Get the latitude and longitude from current address
          const coords: google.maps.LatLngLiteral = {
            lat: this.currentAddress.latitude,
            lng: this.currentAddress.longitude,
          };

          // Check if rings have already been added
          if (this.buffRings && this.buffRings.length > 0) {
            this.removeBufferRings();
          }

          // Add the buffers to map
          this.addBufferToMap(coords, data);
        } else {
          // when a non static risk type is clicked or rings are intended to be removed
          this.removeBufferRings();
        }
      });
  }

  ngAfterViewInit(): void {
    // Initialize google map
    this.initMap();

    if (this.map) {
      // Create and add marker clusterer to the map
      this.createMarkerClusterer([]);

      // Set map geojson styles
      this.map.data.setStyle({
        icon: 'assets/img/fire.png',
        strokeColor: 'blue',
      });

      const drawingManager = this.initDrawControl();

      // Add events to drawing manager
      drawingManager.addListener(
        'polygoncomplete',
        (polygon: google.maps.Polygon) => {
          const polygonPaths = polygon.getPaths();
          const mVCArrays = polygonPaths.getArray();
          const googleLatLngArrays = mVCArrays.map((mA) => mA.getArray());

          // building polygon coordinates from google maps polygon

          const tempArray: google.maps.LatLng[] = [];
          googleLatLngArrays.forEach((arr) => {
            tempArray.push(...arr);
          });
          // Ensure the first is the last // Geojson specification
          tempArray.push(tempArray[0]);

          const finalPolygon = {
            coordinates: [
              tempArray.map((latlng) => {
                return [latlng.lng(), latlng.lat()];
              }),
            ],
          };
          // for export method
          this.polygonData = finalPolygon;

          // make a call to get polygon addresses
          this.mapAddressService
            .fetchAddressesUsingPolygon(finalPolygon)
            .subscribe(
              (res) => {
                // update address list observable
                this.mapAddressService.updateAddressObservable(res);
                this.mapAddressService.updateIsPolygonDrawn(true);
                setTimeout(() => {
                  polygon.setMap(null);
                }, 5000);

                // repaint markercluster
                this.markerClusterer.repaint();
              },
              (err) => {
                this.mapAddressService.updateAddressObservable([]);
                setTimeout(() => {
                  polygon.setMap(null);
                }, 5000);
                this.markerClusterer.repaint();
              }
            );

          // change drawing mode
          drawingManager.setDrawingMode(null);
        }
      );
    }

    // delete

    // AddressList Subscription
    this.addressListSubscription = this.addressListObservable.subscribe(
      (data: any[]) => {
        if (this.markerClusterer) {
          const markers = this.createMarkers(data);
          // console.log(markers);

          // Clear any Existing markers when data arrives
          this.markerClusterer.clearMarkers();
          // Add new markers to clusterer from data that arrives
          this.markerClusterer.addMarkers(markers, true);
        }
      }
    );
  }

  // returnPolygonData(){
  //   return { type: 'polygon', value: this.polygonData }
  // }

  ngOnDestroy(): void {
    this.addressListSubscription.unsubscribe();
    this.riskBoundaryGeojsonSubscription.unsubscribe();
    this.bufferRingsObservableSubscription.unsubscribe();
    this.currentAddressSubscription.unsubscribe();
  }

  onViewRiskClick = (e) => {
    if (e.target.dataset.id === 'viewRisk') {
      this.viewRiskPanel.emit();
    }
  };

  /**
   * initMap
   * Initiates Leaflet map
   * * Only call it in the ngAfterviewInit lifecycle or further lifecycles
   */
  private initMap(): void {
    this.map = new google.maps.Map(
      this.mapContainer.nativeElement,
      this.mapOptions
    );

    // Add map controls
  }

  private initDrawControl(): google.maps.drawing.DrawingManager {
    const drawingManager = new google.maps.drawing.DrawingManager({
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: [google.maps.drawing.OverlayType.POLYGON],
        position: google.maps.ControlPosition.LEFT_TOP,
      },
      polygonOptions: {
        editable: true,
        strokeColor: '#003796ce',
        fillColor: '#003796ce',
        fillOpacity: 0.4,
      },
    });
    drawingManager.setMap(this.map);
    return drawingManager;
  }

  private createMarkerClusterer(markers: google.maps.Marker[]): void {
    const styles = [
      {
        width: 30,
        height: 30,
        className: 'custom-clustericon-1',
        url: '',
      },
      {
        width: 40,
        height: 40,
        className: 'custom-clustericon-2',
        url: '',
      },
      {
        width: 50,
        height: 50,
        className: 'custom-clustericon-3',
        url: '',
      },
    ];

    this.markerClusterer = new MarkerClusterer(this.map, markers, {
      maxZoom: 20,
      gridSize: 60,
      styles,
      clusterClass: 'custom-clustericon',
    });
  }

  private createMarkers(data: any[]): google.maps.Marker[] {
    if (data.length > 0) {
      return data
        .map((d) => {
          const icon =
            d.status === 1 ? 'assets/img/red.png' : 'assets/img/green.png';

          const marker =
            d.latitude && d.longitude
              ? new google.maps.Marker({
                  position: {
                    lat: d.latitude,
                    lng: d.longitude,
                  },
                  icon,
                })
              : null;

          // Add event to marker
          if (marker) {
            marker.addListener('click', this.markerClick.bind(this, marker, d));
          }

          return marker;
        })
        .filter((m) => m !== null);
    } else {
      return [];
    }
  }

  public async markerClick(marker, address, event) {
    this.closePanel.emit();

    // Update current Address
    this.mapAddressService.updateCurrentAddress(address);

    // Add action buttons to popup
    const actionBtnHtml = await this.generateMarkerContent(address);

    // create a html node to to add to popup

    const node = document.createElement('div');
    node.innerHTML = actionBtnHtml;
    node.addEventListener('click', this.onViewRiskClick);
    this.infowindow.setContent(node);
    this.infowindow.open(this.map, marker);

    google.maps.event.addListener(this.infowindow, 'closeclick', this.onclosed);
  }

  onclosed = () => {
    this.closePanel.emit();
  };

  // public check = setInterval(()=>{
  //   google.maps.event.addListener(this.infowindow, 'closeclick', this.onclosed)
  // },4000)
  /**
   *
   * @param coords The coordinates of the address clikcked
   * @param buffDetails The details gotten from the observable detailing whether to remove or and add and which distnces
   * @returns Concentric circles micking a multirings buffer
   */
  private addBufferToMap(
    coords: google.maps.LatLngLiteral,
    bufferType: string
  ): void {
    let distanceArray: {
      distance: number;
      color: string;
      opacity?: number;
    }[] = null;

    // Distances to choose from based on buffer type
    if (bufferType === BufferType.stationS) {
      distanceArray = [
        // { distance: 10000, color: '#00FF7F', opacity: 0.4 },
        // { distance: 2000, color: '#FFFF00', opacity: 0.5 },
        // { distance: 201, color: '#ffc000', opacity: 0.8 },
        // { distance: 501, color: '#ffc000', opacity: 0.9 },
        // { distance: 50, color: '#FF0000', opacity: 1 },

        { distance: 10000, color: '#00FF7F', opacity: 0.5 },
        { distance: 2000, color: '#FFFF00', opacity: 0.5 },
        { distance: 201, color: '#FFC000', opacity: 0.5 },
        { distance: 501, color: '#E36C0A', opacity: 0.3 },
        { distance: 50, color: '#FF0000', opacity: 0.2 },
      ];
    } else if (bufferType === BufferType.rivers) {
      distanceArray = [
        // { distance: 5000, color: '#00FF7F', opacity: 0.4 },
        // { distance: 2000, color: '#00FF7F', opacity: 0.5 },
        // { distance: 751, color: '#FFFF00', opacity: 0.8 },
        // { distance: 251, color: '#ffc000', opacity: 0.9 },
        // { distance: 150, color: '#FF0000', opacity: 1 },

        { distance: 10000, color: '#00FF7F', opacity: 0.5 },
        { distance: 2000, color: '#FFFF00', opacity: 0.5 },
        { distance: 751, color: '#FFC000', opacity: 0.5 },
        { distance: 251, color: '#E36C0A', opacity: 0.3 },
        { distance: 150, color: '#FF0000', opacity: 0.2 },
      ];
    } else if (bufferType === BufferType.oceans) {
      distanceArray = [
        // { distance: 450000, color: '#00FF7F', opacity: 0.4 },
        // { distance: 300000, color: '#00FF7F', opacity: 0.5 },
        // { distance: 100000, color: '#FFFF00', opacity: 0.8 },
        // { distance: 35001, color: '#ffc000', opacity: 0.9 },
        // { distance: 2000, color: '#FF0000', opacity: 1 },

        { distance: 800000, color: '#00FF7F', opacity: 0.5 },
        { distance: 300000, color: '#FFFF00', opacity: 0.5 },
        { distance: 100000, color: '#FFC000', opacity: 0.5 },
        { distance: 35001, color: '#E36C0A', opacity: 0.3 },
        { distance: 2000, color: '#FF0000', opacity: 0.2 },
      ];
    }

    this.buffRings = distanceArray.map((buffOpts) => {
      return new google.maps.Circle({
        strokeColor: buffOpts.color,
        strokeOpacity: 1,
        strokeWeight: 2,
        fillColor: buffOpts.color,
        fillOpacity: buffOpts.opacity ? buffOpts.opacity : 0.4,
        map: this.map,
        center: coords,
        radius: buffOpts.distance,
      });
    });
  }

  private removeBufferRings(): void {
    if (this.buffRings && this.buffRings.length > 0) {
      this.buffRings.forEach((ring) => {
        ring.setMap(null);
        this.buffRings = null;
      });
    }
  }

  // private generateMarkerContent(address: any): Promise<string> {
  //   return new Promise((resolve, reject) => {

  //     this.addresStrucuteService.getDynamicStructure().subscribe(
  //       (response: any) => {
  //         console.log(response.extraFields, "adressstructureurc from api");
  //         const result = response.extraFields;
  //         const map = Object.fromEntries(result.map((_) => [_.fieldName, _]));

  //         const extraData = address.extraFields
  //           ? JSON.parse(address.extraFields)
  //           : null;

  //         if (extraData) {
  //           const images = Object.entries(extraData).filter(
  //             ([k]) => map[k].fieldType == 'image'
  //           ).map(([_, url]) => url as string)

  //           const hasPicture = images.length > 0;

  //           const html = address
  //             ? `<h2>${address.housenumber},${address.streetname}${
  //                 address.areaname
  //               }</h2>
  //         <span style="text-transform: capitalize">State: ${
  //           address.state
  //         }</span>
  //         <br />
  //         <span>Latitude: ${address.latitude}</span>
  //         <br />
  //         <span>Longitude: ${address.longitude}</span>
  //         <br />
  //         <span>Elevation: ${address.elevation.toFixed(2)} meters</span>
  //         <br />
  //         ${hasPicture ? this.getPictureDetails(images) : ''}
  //         <hr>
  //         <div style="">
  //           <button data-id='viewRisk' style="border: none; cursor: pointer; outline: none; display: block; width: 100%; color: white; background:#003796ce; border-radius: 3px; padding: 3px;">View Risk</button>
  //         </div>`
  //             : '';
  //             return resolve(html)
  //         }
  //         reject(new Error('No data'))
  //       },

  //       (error) => {

  //       }
  //     );
  //   });
  // }

  private generateMarkerContent(address: any): Promise<string> {

    return new Promise((resolve, reject) => {
      this.addresStrucuteService.getDynamicStructure().subscribe(
        (response: any) => {
          const result = response.extraFields;
          const map = Object.fromEntries(result.map((_) => [_.fieldName, _]));

          const extraData = address.extraFields ? JSON.parse(address.extraFields) : null;
          if (!extraData) {        
            const html = this.generateHtmlContent(address)
            return resolve(html);
          } else {
            const images = Object.entries(extraData)
            .filter(([k, v]) => {
              return !map[k] && typeof v === 'string' && v.startsWith('http');
            })
            .map(([_, url]) => url as string);
            const html = this.generateHtmlContent(address,images)
            return resolve(html);

          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  }


  private generateHtmlContent(address: any,  images?: string[]): string {

    const hasPicture = images?.length > 0;

    return address
      ? `<h2>${address.housenumber},${address.streetname},${address.areaname}</h2>
         <span style="text-transform: capitalize">State: ${address.state}</span>
         <br />
         <span>Latitude: ${address.latitude}</span>
         <br />
         <span>Longitude: ${address.longitude}</span>
         <br />
         <span>Elevation: ${address.elevation.toFixed(2)} meters</span>
         <br />
         ${hasPicture ? this.getPictureDetails(images) : ''}
         <hr>
         <div>
           <button data-id='viewRisk' style="border: none; cursor: pointer; outline: none; display: block; width: 100%; color: white; background:#003796ce; border-radius: 3px; padding: 3px;">View Risk</button>
         </div>`
      : '';
  }


  private getPictureDetails(images: string[]): string {
    const slides = images
      .map((url) => `<img  class="slide" src="${url}" alt="Picture"/>`)
      .join('');

    return images.length
      ? `
        <details>
          <summary>View Picture</summary>
          <div class="carousel">
            <div class="slides">
              ${slides}
            </div>
          </div>
        </details>
      `
      : '';
  }

  // <span>Uploaded Time:<time datetime="${address.uploadedDate}">
  //         ${address.uploadedDate}</time></span>
}
