<header>
  <mat-toolbar  role="heading">
    <!-- Company Logo -->
    <a routerLink="/login" class="app-header-brand">
      <img class="app-header-brand__img" src="./assets/img/logo.png" alt="company logo" />
    </a>
    <span class="example-spacer"></span>

    <!-- Menu links -->
    <a class="app-header-nav__item"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
    [routerLink]="['/map']">
      <div class="menu home">
        <span class="menu-text">
          <mat-icon aria-hidden="false" aria-label="home">home</mat-icon>
          &nbsp;Home
        </span>
      </div>
    </a>

    <a *ngIf="isClientAdmin || isSubUser" class="app-header-nav__item"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
    [routerLink]="['/dashboard']">
      <div class="menu">
        <span class="menu-text">
          <mat-icon aria-hidden="false" aria-label="home">poll</mat-icon>
          &nbsp;Dashboard
        </span>
      </div>
    </a>
    <a class="app-header-nav__item">
      <div [matMenuTriggerFor]="user" class="menu">
        <span class="menu-text">
          <mat-icon>supervisor_account</mat-icon> &nbsp;User Management
        </span>
      </div>
      <!-- Dropdown -->
      <mat-menu style="padding: 0" #user="matMenu">
        <button *ngIf="isSuperAdminAdmin || isGisUser " mat-menu-item [routerLink]="['/clients']" routerLinkActive="active">
          <mat-icon>supervised_user_circle</mat-icon>Clients
        </button>
        <button *ngIf="isSuperAdminAdmin" mat-menu-item [routerLink]="['/gis-users']" routerLinkActive="active">
          <mat-icon>people_alt</mat-icon>GIS
        </button>
        <button *ngIf="isClientAdmin" mat-menu-item [routerLink]="['/users']" routerLinkActive="active">
          <mat-icon>people_alt</mat-icon>Office Staff
        </button>
        <button *ngIf="isClientAdmin || isSubUser" mat-menu-item [routerLink]="['/agents']" routerLinkActive="active">
          <mat-icon>groups</mat-icon>Field Staff
        </button>
      </mat-menu>
    </a>

    <a *ngIf="!isSuperAdminAdmin" class="app-header-nav__item">
      <div [matMenuTriggerFor]="address" class="menu">
        <span class="menu-text">
          <mat-icon>location_on</mat-icon> &nbsp;Address Management
        </span>
      </div>
      <!-- Dropdown -->
      <mat-menu style="padding: 0" #address="matMenu">
        <button *ngIf="isClientAdmin || isSubUser" mat-menu-item [routerLink]="['/address']" routerLinkActive="router-link-active">
          <mat-icon>location_city</mat-icon>Address List
        </button>
        <button *ngIf="isClientAdmin" mat-menu-item [routerLink]="['/address-form']" routerLinkActive="router-link-active">
          <mat-icon>event_note</mat-icon>Address Form
        </button>
        <button *ngIf="isClientAdmin" mat-menu-item [routerLink]="['/address-archive']" routerLinkActive="router-link-active">
          <mat-icon>archive</mat-icon>Address Archive
        </button>
      </mat-menu>
    </a>

    <a *ngIf="isClientAdmin || isSubUser" class="app-header-nav__item"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
    [routerLink]="['/address-upload']">
      <div class="menu">
        <span class="menu-text">
          <mat-icon>cloud_upload</mat-icon> &nbsp;Upload
        </span>
      </div>
    </a>

    <a *ngIf="isCountAvailable" class="app-header-nav__item"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
    [routerLink]="['/address', true, 3]">
      <div class="menu">
        <span class="menu-text">
           <mat-icon [matBadge]="TatCount" matBadgeColor="warn">notifications</mat-icon>
        </span>
      </div>
    </a>
    <!-- Menu links End Here -->

    <!-- <span class="example-spacer"></span> -->

    <!-- Demo Default User Profile Start here -->

    <a class="app-header-nav__logo">
      <div [matMenuTriggerFor]="menu" style="margin: 0 0.6rem;">
        <!-- <img #profileImage class="app-header-nav__img app-header-nav__img--profile" src="./assets/img/pro-file.png"
          alt="" /> -->
        <span class="menu-text">
          Hello {{ username }} &nbsp;<mat-icon>settings</mat-icon>
        </span>
      </div>
      <!-- Dropdown -->
      <mat-menu style="padding: 0" #menu="matMenu">
        <button *ngIf="isClientAdmin" mat-menu-item (click)="changeToken()">
         Change Api Token
        </button>
        <button mat-menu-item (click)="changePassword()" routerLinkActive="router-link-active">
          Change Password
          <!-- <mat-icon>vpn_key</mat-icon> -->
        </button>
        <button mat-menu-item (click)="logOut()">
          Logout
          <!-- <mat-icon>login</mat-icon> -->
        </button>
      </mat-menu>
    </a>
    <!-- Demo Default User Profile End Here -->
  </mat-toolbar>
</header>

<main class="app-body">
  <router-outlet></router-outlet>
</main>

<footer id="footer">
    <div class="footer-div">
       <p class="footer-text">©2020 Polaris Digitech Limited. All rights reserved.</p>
    </div>
</footer>
