<ng-container *ngIf="(addressListObservable$ | async).length > 0; else empty">
  <!-- <button type="button" style="width:100%; background-color:#00008E;border:0;color:#fff;padding:0.4rem;margin-bottom:5px;border-radius:4px;">Export to csv</button> -->
  <ul class="address-list-container">
    <li tabindex="0" *ngFor="let address of addressListObservable$ | async as addr" (click)="addressItemClick(address)"
      [title]="
        address &&
        address.housenumber + ', ' + address.streetname + ' ' + address.areaname
      ">
      <span>
        <img height="20" [src]="
            address && address.status === 1
              ? 'assets/img/red.png'
              : 'assets/img/green.png'
          " alt="address icon" />
        <h4>
          {{
            address &&
              address.housenumber +
                ", " +
                address.streetname +
                " " +
                address.areaname
          }}
        </h4>
      </span>

      <!-- <small>Customer Name: {{ address && address.customername }}</small><br /> -->
      <small style="text-transform: capitalize">State: {{ address && address.state }}</small><br />
      <small>Uploaded Date:
        <time [datetime]="address && address.uploadedDate">{{
          address && address.uploadedDate
        }}</time></small>
      <br>
      <small>Geocoded Date:
        <time [datetime]="address && address.geocodedDate">{{
            address && address.geocodedDate
          }}</time></small>
    </li>
  </ul>
</ng-container>

<ng-template #empty>
  <h3>No search yet or search returned nothing</h3>
</ng-template>

<div class="loader-wrapper" *ngIf="!!(loading$ | async).addressFetch">
  <img style="height: 3rem" src="assets/img/loader.gif" />
</div>
