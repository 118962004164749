// verify-otp.component.ts
import { Component, OnInit, ViewChildren, QueryList, ElementRef, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/services/authService/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { interval, Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-factor-authentication',
  templateUrl: './factor-authentication.component.html',
  styleUrls: ['./factor-authentication.component.scss']
})


export class VerifyOtpComponent implements OnInit, OnDestroy {
  otpForm: FormGroup;
  loading = false;
  submitted = false;
  countdown = 300; // 5 minutes in seconds
  countdownDisplay: string;
  private timerSub: Subscription;
  private storedToken: string
  @ViewChildren('otpInput') otpInputs!: QueryList<ElementRef>;


  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private toastr: ToastrService
  ) {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras?.state as {
      token: string;
    };

    this.storedToken = state?.token

  }

  ngOnInit(): void {
    this.initializeForm();
    this.startCountdown();

    if (!this.storedToken) {
      this.toastr.error('Invalid verification request', 'Error');
      this.router.navigate(['/login']);
    }
  }

  initializeForm(): void {
    this.otpForm = this.fb.group({
      digit1: ['', [Validators.required, Validators.pattern(/^[0-9]$/)]],
      digit2: ['', [Validators.required, Validators.pattern(/^[0-9]$/)]],
      digit3: ['', [Validators.required, Validators.pattern(/^[0-9]$/)]],
      digit4: ['', [Validators.required, Validators.pattern(/^[0-9]$/)]],
      digit5: ['', [Validators.required, Validators.pattern(/^[0-9]$/)]],
      digit6: ['', [Validators.required, Validators.pattern(/^[0-9]$/)]]
    });
  }

  startCountdown(): void {
    const storedTimer = localStorage.getItem('otpTimer');
    if (storedTimer) {
      const remaining = Math.floor((Number(storedTimer) - Date.now()) / 1000);
      this.countdown = remaining > 0 ? remaining : 0;
    } else {
      localStorage.setItem('otpTimer', (Date.now() + 300000).toString());
    }

    this.timerSub = timer(0, 1000).subscribe(() => {
      const endTime = Number(localStorage.getItem('otpTimer'));
      this.countdown = Math.max(0, Math.floor((endTime - Date.now()) / 1000));

      const minutes = Math.floor(this.countdown / 60);
      const seconds = this.countdown % 60;
      this.countdownDisplay = `${minutes}:${seconds.toString().padStart(2, '0')}`;

      if (this.countdown <= 0) {
        localStorage.removeItem('otpTimer');
        this.timerSub.unsubscribe();
        this.router.navigate(['/login'])
      }
    });
  }

  onPaste(event: ClipboardEvent): void {
    event.preventDefault();
    const clipboardData = event.clipboardData?.getData('text/plain').slice(0, 6);

    if (clipboardData?.match(/^\d{6}$/)) {
      const digits = clipboardData.split('');
      this.otpForm.patchValue({
        digit1: digits[0],
        digit2: digits[1],
        digit3: digits[2],
        digit4: digits[3],
        digit5: digits[4],
        digit6: digits[5]
      });
      this.onSubmit(); // Auto-submit on valid paste
    }
  }

  onKeyUp(event: KeyboardEvent, controlName: string): void {
    const input = event.target as HTMLInputElement;
    const value = input.value;

    // Allow navigation using arrow keys
    if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      return;
    }

    // Handle backspace/delete
    if (event.key === 'Backspace' || event.key === 'Delete') {
      if (!value && controlName !== 'digit1') {
        const currentIndex = this.getControlIndex(controlName);
        this.focusInput(currentIndex - 1);
      }
      return;
    }

    // Filter non-numeric input
    if (!/^\d$/.test(value)) {
      input.value = '';
      return;
    }

    // Move to next input
    const currentIndex = this.getControlIndex(controlName);
    if (value && currentIndex < 5) {
      this.focusInput(currentIndex + 1);
    }

    // Auto-submit when all fields are filled
    if (currentIndex === 5 && this.otpForm.valid) {
      this.onSubmit();
    }
  }

  private getControlIndex(controlName: string): number {
    return parseInt(controlName.replace('digit', '')) - 1;
  }

  private focusInput(index: number): void {
    const inputs = this.otpInputs.toArray();
    if (inputs[index]) {
      inputs[index].nativeElement.focus();
    }
  }



  get otpCode(): string {
    return Object.values(this.otpForm.value).join('');
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.otpForm.invalid) {
      this.toastr.error('Please enter a valid 6-digit code', 'Error');
      return;
    }

    this.loading = true;

    this.authService.verifyOtp(this.storedToken, this.otpCode)
      .pipe(first())
      .subscribe({
        next: (response) => {
          localStorage.removeItem('otpTimer');
          console.log(response)

          if (response.firstTimeLoggedIn) {
            this.router.navigate(['/change-password']);
          } else {
            const returnUrl = this.route.snapshot.queryParams.returnUrl || '/map';
            this.router.navigateByUrl(returnUrl);
          }
        },
        error: (error) => {
          this.loading = false;
          this.toastr.error( error, 'Failed!');
          this.otpForm.reset();
        }
      });
  }





  ngOnDestroy(): void {
    if (this.timerSub) {
      this.timerSub.unsubscribe();
    }
  }
}
