// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: 'http://34.89.56.255:8080/',
  apiUrl: 'https://35.195.248.149/',
  // apiUrl: 'https://test.polarisdigitech.net/axamansard/',
  publicKey:`
    -----BEGIN PUBLIC KEY-----
    MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA0sej3FmINRo/GipIHOJ7
    ojAKapVGI0ANhYCDSELTAyYwuZ8W4G3JN/CdMy84PIQmWnimpwO/21eOCHb+QEs7
    IedSu6StbRJFb4ZNxTPl7SB6gb9d88pfYBBrjJzJVzZUQb88mtL1udUssAcjCbTB
    dCyKILltk2pNpsqlyT1Cp7ycSJKsDhimLjlgdEjHf3T6v/ChXdhyVqsPlpznhh5q
    dd6kqzB3xoamriFEcNNLLv83euh3RCDUmvXw+nWMCmspHbg50eSfXc2hvIgpOCVS
    udk9bHIOC35IDeoF/GfMt+11S8rxEFeZHzxg8A7vw1kUJ4du5XWkXHpBMxiTQb+z
    +QIDAQAB
    -----END PUBLIC KEY-----
  `,
  mapbox: {
    accessToken:
      'pk.eyJ1IjoibGFubHVnYXIiLCJhIjoiY2pyaGw5enhwMWZ5dTQ0cno0c3hzMThzYSJ9.XEWRXoTd9FY7ok7ClxtxMg',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
