import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { FieldworkerService } from 'src/app/shared/services/userService/fieldworker.service';

@Component({
  selector: 'app-assign-address',
  templateUrl: './assign-address.component.html',
  styleUrls: ['./assign-address.component.scss']
})
export class AssignAddressComponent implements OnInit {

  httpLoading: boolean;
  disable: boolean;
  fieldWorkerArray: any[] = [];
  myControl = new FormControl();
  filteredOptions: Observable<any[]>;
  fOptions: any[] = [];

  constructor(
    private fService: FieldworkerService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<AssignAddressComponent>
  ) { }

  ngOnInit(): void {

    this.fService.getAllFieldWorkers(0, 10000, '', 1).subscribe(
      (res: any) => {
        this.fieldWorkerArray = res.users;
      }
    );
    this.myControl.valueChanges.pipe(startWith('')).subscribe(value => {
      this.fOptions = this._filter(value);
    });
  }

  displayName(value?: any): string | undefined {
    return value ? value.fullname : undefined;
  }

  private _filter(value: string): string[] {
    const filterValue = typeof value === 'string' ? value.toLowerCase() : '';

    return this.fieldWorkerArray.filter(fieldworker =>
      fieldworker.username.toLowerCase().includes(filterValue) || fieldworker.fullname.toLowerCase().includes(filterValue)
    );
  }

  assignAddress() {
    if (!this.myControl.value.id) {
      this.toastr.error('Please select a field-worker', 'Failed');
    } else {
    this.dialogRef.close(this.myControl.value.id);
    }
  }

  cancel() {
    this.dialogRef.close('close');
  }
}
