// encryption.service.ts
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import JSEncrypt from 'jsencrypt';

@Injectable({ providedIn: 'root' })
export class EncryptionService {
  private encryptor = new JSEncrypt();

  constructor() {
    this.initializeEncryption();
  }

  private initializeEncryption(): void {
    try {
      const cleanPublicKey = environment.publicKey
        .replace(/\n/g, '')
        .replace('-----BEGIN PUBLIC KEY-----', '')
        .replace('-----END PUBLIC KEY-----', '')
        .trim();

      this.encryptor.setPublicKey(
        `-----BEGIN PUBLIC KEY-----${cleanPublicKey}-----END PUBLIC KEY-----`
      );

      if (!this.encryptor.getPublicKey()) {
        throw new Error('Public key initialization failed');
      }
    } catch (error) {
      console.error('Encryption service initialization error:', error);
      throw new Error('Security system failed to initialize');
    }
  }

  public encryptData(data: string): string {
    const encrypted = this.encryptor.encrypt(data);
    if (!encrypted) {
      throw new Error('Encryption failed');
    }
    return encrypted;
  }

  public encryptForUrl(data: string): string {
    const encrypted = this.encryptor.encrypt(data);
    if (!encrypted) throw new Error('Encryption failed');

    // Convert to URL-safe base64
    return encrypted
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');
  }


  public validateEncryption(): boolean {
    try {
      const testString = 'test-encryption-' + Date.now();
      return !!this.encryptor.encrypt(testString);
    } catch (error) {
      return false;
    }
  }
}
