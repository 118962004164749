import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { AuthenticationService } from '../authService/authentication.service';
import { environment } from '@environments/environment';
import { BufferType, LoaderTypes } from '@app/shared/models/map.model';

@Injectable({
  providedIn: 'root',
})
export class MapAddressesService {

  private isPolygonDrawn$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  // An observable of all the addresses returned from the server to be shared across all components
  private addressListObserver$: BehaviorSubject<any[]> = new BehaviorSubject(
    []
  );

  // An observable of the geojson features mapped from the addresses from the server
  private geojsonAddresses$: BehaviorSubject<any> = new BehaviorSubject(null);

  // An observable of the current address clicked
  private currentAddress$: BehaviorSubject<any> = new BehaviorSubject(null);

  // An obserbable holding the value of of risk Details
  private riskDetails$: BehaviorSubject<any> = new BehaviorSubject(null);

  // An observable for risk boundary
  private riskBoundary$: BehaviorSubject<any> = new BehaviorSubject(null);

  // An observable holding the value of the risk expansion panel to be opened
  private riskExpansionPanelOpened$: BehaviorSubject<any> = new BehaviorSubject(
    null
  );
  // An observable holding the value of risk categories
  private riskCategories$: BehaviorSubject<any> = new BehaviorSubject([]);

  // An observable for loading when fetching risk and addresses
  private loading$: BehaviorSubject<any> = new BehaviorSubject({
    addressFetch: false,
    riskDetailsFetch: false,
    riskCategoriesFetch: false,
  });

  // An observable to hold static risk geojson features
  private staticRiskGeojsonFeatures$: BehaviorSubject<any> = new BehaviorSubject(
    null
  );

  // Buffer rings array Observable: the obserable holds a boolean on whether to show rings around marker or not
  private bufferRingsObservable$: BehaviorSubject<BufferType> = new BehaviorSubject(
    null
  );

  private addressPolygonData;
  private addressSearchData;


  constructor(
    private authService: AuthenticationService,
    private http: HttpClient
  ) {}

   // update the current address
   updateIsPolygonDrawn(condition): void {
    this.isPolygonDrawn$.next(condition);
  }

  /**
   * Todo implement Error
   * @param fromDate
   * @param toDate
   * @param query
   */
  searchAddressesFetch(
    fromDate = '',
    toDate = '',
    query = ''
  ): Observable<any> {
    // console.log(query,'hi')
    this.addressSearchData = {fromDate, toDate, query}
    const url = `${environment.apiUrl}addresses-onMap?fromDate=${fromDate}&query=${query}&toDate=${toDate}`;
    return this.http.get<any>(url).pipe(map((data) => data.addresses));
  }

  // update addresses fetched from the database server
  updateAddressObservable(data: any): void {
    // update loading
    this.updateLoading(false, LoaderTypes.addressFetch);
    // const addressGeojson = this.buildGeoJsonFromAddresses(data);
    // this.geojsonAddresses$.next(addressGeojson);
    this.addressListObserver$.next(data);
  }

  // update the current address
  updateCurrentAddress(data): void {
    this.currentAddress$.next(data);
  }

  // update the risk Details
  updateRiskDetails(data): void {
    this.riskDetails$.next(data);
  }

  // update the risk Boundary
  updateRiskBoundary(data): void {
    const geojsonboundary = this.buildRiskPolygonFromRiskData(data);
    this.riskBoundary$.next(geojsonboundary);
  }
  // update the risk Boundary
  updateRiskExpansionPanelOpened(data): void {
    this.riskExpansionPanelOpened$.next(data);
  }
  // update the risk Categories
  updateRiskCategories$(data): void {
    // update Loading
    this.updateLoading(false, LoaderTypes.riskCategoriesFetch);
    this.riskCategories$.next(data);
  }

  // update loader observable
  updateLoading(data: boolean, type: LoaderTypes): void {
    if (type === LoaderTypes.addressFetch) {
      this.loading$.next({ ...this.loading$.value, addressFetch: data });
    } else if (type === LoaderTypes.riskDetailsFetch) {
      this.loading$.next({ ...this.loading$.value, riskDetailsFetch: data });
    } else {
      this.loading$.next({ ...this.loading$.value, riskCategoriesFetch: data });
    }
  }

  // update bufferRingsObservable

  updateBufferRingsObservable(data): void {
    this.bufferRingsObservable$.next(data);
  }

  // update static geojson features observable
  updateStaticRiskGeojsonFeatures(data: any): void {
    const geojson = this.buildStaticRiskGeojson(data);
    this.staticRiskGeojsonFeatures$.next(geojson);
  }

  // update buffer rings
  getAddressList(): Observable<any[]> {
    return this.addressListObserver$.asObservable();
  }

  getGeojsonAddress(): Observable<any> {
    return this.geojsonAddresses$.asObservable();
  }
  getCurrentAddress(): Observable<any> {
    return this.currentAddress$.asObservable();
  }
  getRiskBoundaryGeojson(): Observable<any> {
    return this.riskBoundary$.asObservable();
  }
  getRiskDetails(): Observable<any> {
    return this.riskDetails$.asObservable();
  }
  getRiskExpansionPanelOpened(): Observable<any> {
    return this.riskExpansionPanelOpened$.asObservable();
  }
  getRiskCategories(): Observable<any> {
    return this.riskCategories$.asObservable();
  }

  getStaticRiskGeojsonFeatures(): Observable<any> {
    return this.staticRiskGeojsonFeatures$.asObservable();
  }

  getBufferRingsObservable(): Observable<BufferType> {
    return this.bufferRingsObservable$.asObservable();
  }

  getLoading(): Observable<boolean> {
    return this.loading$.asObservable();
  }

  getISOStringFromDate(date: Date): string {
    const pad = (num) => {
      return num < 10 ? '0' + num : num;
    };

    return date
      ? date.getFullYear() +
          '-' +
          pad(date.getMonth() + 1) +
          '-' +
          pad(date.getDate())
      : null;
  }

  // @todo: Implement error;
  fetchMapAddresses(fromDate = '', toDate = '', query = ''): void {
    this.addressSearchData = {fromDate, toDate, query}
    this.updateLoading(true, LoaderTypes.addressFetch);
    const url = `${environment.apiUrl}addresses-onMap?fromDate=${fromDate}&query=${query}&toDate=${toDate}`;
    this.http
      .get<any>(url)
      .pipe(map((data) => data.addresses))
      .subscribe(
        (addresses) => {
          this.updateAddressObservable(addresses);
        },
        (err) => {
          this.updateAddressObservable([]);
        }
      );
  }

  exportAddressSearch(){
    return this.addressSearchData
  }

  // Risks
  fetchRiskCategories(): Observable<any> {
    // Update loading
    this.updateLoading(true, LoaderTypes.riskCategoriesFetch);
    const url = environment.apiUrl + 'risks';
    return this.http.get(url);
  }

  // get risk details

  fetchAddressRiskDetails(
    addressID: number,
    lat: number,
    long: number,
    riskType: string
  ): Observable<any> {
    const url =
      environment.apiUrl +
      `address/risk-detail?address_id=${addressID}&lat=${lat}&long=${long}&risk_type=${riskType}`;

    // update loading
    this.updateLoading(true, LoaderTypes.riskDetailsFetch);
    return this.http.get(url).pipe(map((res: any) => res.riskDetail));
  }

  fetchAddressStaticRiskDetails(
    addressID: number,
    lat: number,
    long: number,
    riskType: string
  ): Observable<any> {
    let url;

    // update loading
    this.updateLoading(true, LoaderTypes.riskDetailsFetch);
    if (riskType === 'FIRE') {
      url =
        environment.apiUrl +
        `fire?address_id=${addressID}&latitude=${lat}&longitude=${long}`;
    } else if (riskType === 'RIVER') {
      url =
        environment.apiUrl +
        `water-bodies?address_id=${addressID}&latitude=${lat}&longitude=${long}`;
    } else {
      url =
        environment.apiUrl +
        `ocean?address_id=${addressID}&latitude=${lat}&longitude=${long}`;
    }

    return this.http.get(url).pipe(map((res: any) => res.relatedRisks));
  }

  /**
   *
   * @param polygon the polgon from drawing
   * @todo implement error handliing
   */
  fetchAddressesUsingPolygon(polygon: any): Observable<any> {

    this.addressPolygonData = {polygon};

    const url = environment.apiUrl + 'address/within-polygon';

    const httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(url, polygon).pipe(map((res: any) => res.addresses));
  }

  /**
   *
   * @param data
   * @description builds geojson from returned addresses from the DB
   * @fix coordinates[0] is used because the retured coordinates is an [[[[]]]] while [[[]]] is required
   */

  private buildRiskPolygonFromRiskData(data: any): any {
    const coordinates = data ? data.coordinates[0] : [];
    return {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates,
        properties: {},
      },
    };
  }

  private buildStaticRiskGeojson(
    data: { type: string; coordinates: number[] }[]
  ): any {
    const features = !!data
      ? data.map((f) => {
          return {
            type: 'Feature',
            geometry: {
              type: f.type,
              coordinates: f.coordinates,
            },
          };
        })
      : [];
    const geojson = {
      type: 'FeatureCollection',
      features,
      properties: {},
    };

    return geojson;
  }

  downloadAddressDataFilter(){
    let url;
    // console.log(this.isPolygonDrawn$.value, 'hii')

    if (this.isPolygonDrawn$.value) {
      const {polygon} =  this.addressPolygonData
      url = environment.apiUrl + 'address/within-polygon/download';
      return this.http.post(url, polygon, {
        responseType: 'blob',
        observe: 'response',
      });

    } else {
      const {fromDate, toDate, query} = this.addressSearchData;

      url = `${environment.apiUrl}addresses-onMap/download?fromDate=${fromDate}&query=${query}&toDate=${toDate}`;

      return this.http.get(url, {
        responseType: 'blob',
        observe: 'response',
      });

    }










  }
}
