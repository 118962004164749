import { Component, OnInit, Inject } from '@angular/core';
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormArray,
  AbstractControl,
  FormControl
} from "@angular/forms";
import {MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ToastrService } from 'ngx-toastr';

import { AddressStructureService } from "src/app/shared/services/projectService/address-structure.service";


@Component({
  selector: 'app-create-option',
  templateUrl: './create-option.component.html',
  styleUrls: ['./create-option.component.scss']
})
export class CreateOptionComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private aService: AddressStructureService,
    private toastr: ToastrService,
    private matRef: MatDialogRef<CreateOptionComponent>,
    @Inject (MAT_DIALOG_DATA) public data
  ) { }

  extraOptions: FormGroup;
    selectId = this.data.id;
    disabled;
    loading;
  ngOnInit(): void {
    this.extraOptions = this.fb.group({
      options: this.fb.array([
        this.fb.group({
          value:""
        })
      ])
    });
    // console.log(this.data);
  }

  submitOptions() {
    this.loading= true;
    this.disabled = true;
    this.aService.setoptions(this.extraOptions.value, this.selectId).subscribe(
      (res)=>{
        // console.log(res);
        if (res['status']===0){
          this.toastr.success(res['message']);
          this.loading= true;
          this.disabled = true;
          this.matRef.close(true);
        }
        else{
          this.toastr.error(res['message'], 'Error');
          this.disabled = false;
          this.loading= false;
        }

      })
  }

  deleteOption(index) {
    this.options.removeAt(index);
  }

  get options() {
    // console.log(this.extraField.get("options"));
    return this.extraOptions.get("options") as FormArray;
  }

  addOption() {
      this.options.push(
        this.fb.group({value:''})
      );
  }

}
