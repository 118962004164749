<div class="content">
    <!-- <mat-header>
        <mat-title>
            Address Confirmation
        </mat-title>
    </mat-header> -->
    <p class="warning-p" style="text-align: center;">
      &nbsp; Are you sure you want to Confirm Address(es)
    </p>
    <!-- <h5 class="warning-h">{{ data.action }}?</h5> -->
    <div class="action-buttons">
      <button (click)="onApprove()" class="action-buttons__ok-btn" mat-button>
          Approve
          <!-- *ngIf="httpLoading" -->
          <mat-spinner class="custom-spinner" diameter="20" *ngIf="httpLoading"></mat-spinner>
        </button>
      <button (click)="onDisapprove()" class="action-buttons__cancel-btn" mat-button>
          Disapprove
          <mat-spinner class="custom-spinner" diameter="20" *ngIf="httpLoading" ></mat-spinner>
        </button>
    </div>
  </div>