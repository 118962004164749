import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProjectService } from '@app/shared/services/projectService/project.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-update-address',
  templateUrl: './update-address.component.html',
  styleUrls: ['./update-address.component.scss']
})
export class UpdateAddressComponent implements OnInit {

  addressUpdateForm: FormGroup;
  isLoading;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data, public dialog: MatDialogRef<UpdateAddressComponent>,
    private fb: FormBuilder,
    private addressService: ProjectService,
    public toastr: ToastrService
  ) {}

  ngOnInit(): void {

    this.addressUpdateForm = this.fb.group(
      {
        id: [this.data.id],
        geocodingId: [this.data.geocodingId],
        houseNumber: [this.data.housenumber],
        street: [this.data.streetname, Validators.required],
        area: [this.data.areaname, Validators.required],
        lga: [this.data.lga, Validators.required],
        state: [this.data.state, Validators.required],
      }
    );
  }

  onUpdateAddress(): void {
    this.isLoading = true;
    this.addressService.updateAddress(this.addressUpdateForm.value).subscribe(
      (res: any) => {
        this.isLoading = false;
        if (res.status === 0) {
          this.dialog.close('request-made');
          this.toastr.success(res.message);
        } else if (res.status === 1) {
          this.toastr.warning(res.message);
        }
      },
      (error) => {
        this.isLoading = false;
        this.toastr.error(error, 'Failed')
      }
    );
  }

  closeModal(result:string): void {
    this.dialog.close(result);
  }
}

