import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProjectService } from '@app/shared/services/projectService/project.service'

@Component({
  selector: 'app-view-log',
  templateUrl: './view-log.component.html',
  styleUrls: ['./view-log.component.scss']
})
export class ViewLogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data, public dialog: MatDialogRef<ViewLogComponent>,
    private pService : ProjectService
  ) { }

  geocodingId = this.data.geocodingId;
  id = this.data.id;
  logs:any;

  ngOnInit(): void {
    this.pService.getAddressLog(this.id).subscribe(
      (res:any)=>{
        console.log(res);
        this.logs = res.logs;
        console.log(this.logs);
    })
  }

}
