<section class="search-container" role="region">
  <div class="search-group">
    <input type="text" placeholder="Search..." aria-placeholder="search addresses" aria-label="Address Search" matInput
      [formControl]="searchInput" [matAutocomplete]="auto" />

    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngFor="let address of filteredAddresses | async as aList" [value]="address">
        {{
          address &&
            address.housenumber +
              ", " +
            address.streetname +
              " " +
            address.areaname
        }}
      </mat-option>
    </mat-autocomplete>
    <span class="clear-btn" (click)="clearAddresses()"> clear </span>
  </div>
</section>
