import { Component, OnInit  } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-address-confirmation',
  templateUrl: './address-confirmation.component.html',
  styleUrls: ['./address-confirmation.component.scss']
})
export class AddressConfirmationComponent implements OnInit {

  httpLoading = false;

  constructor(
    private dialogRef: MatDialogRef<AddressConfirmationComponent>
  ) { }

  ngOnInit(): void {
  }
  onDisapprove() {
    this.httpLoading = true;
    this.dialogRef.close('disapprove');

  }

  onApprove() {
    this.httpLoading = true;
    this.dialogRef.close('approve');
  }
}
