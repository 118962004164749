import { AbstractControl, FormGroup } from '@angular/forms';

export function ConfirmPasswordValidator(
  controlName: string,
  matchingControlName: string
) {
  return (formGroup: FormGroup) => {
    let control = formGroup.controls[controlName];
    let matchingControl = formGroup.controls[matchingControlName];
    if (
      matchingControl.errors &&
      !matchingControl.errors.confirmPasswordValidator
    ) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmPasswordValidator: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}
// export class PasswordValidation {
//   static MatchPassword(AC: AbstractControl) {
//     let password = AC.get('password').value;
//     if (AC.get('confirmPassword').touched || AC.get('confirmPassword').dirty) {
//       let verifyPassword = AC.get('confirmPassword').value;

//       if (password != verifyPassword) {
//         AC.get('confirmPassword').setErrors({ MatchPassword: true });
//       } else {
//         return null;
//       }
//     }
//   }
// }
