import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { ConfirmPasswordValidator } from './confirm-password.validator';
import { Router } from '@angular/router';

import { AuthenticationService } from '../../../shared/services/authService/authentication.service';
import { ErrorStateMatcher } from '@angular/material/core';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    return !!(control && control.invalid && (control.dirty || control.touched));
  }
}
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  matcher = new MyErrorStateMatcher();

  disable;
  loading;
  resetPasswordForm: FormGroup;
  submitted: boolean = false;
  hidePassword = true;
  hideConfirmPassword = true;

  constructor(
    public toastr: ToastrService,
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.resetPasswordForm = this.fb.group(
      {
        newPassword: ['', Validators.required],
        confirmPassword: ['', Validators.required],
      },
      {
        validator: ConfirmPasswordValidator('newPassword', 'confirmPassword'),
      }
    );
  }

  resetPassword() {
    this.submitted = true;
    this.loading = true;
    this.disable = true;
    const value = this.resetPasswordForm.value;
    // console.log(this.resetPasswordForm.value);
    if (!this.resetPasswordForm.value.newPassword) {
      this.toastr.error('Fields required');
      this.disable = false;
      this.loading = false;
    } else {
      this.authService.resetPassword(this.resetPasswordForm.value).subscribe(
        (res) => {
          // console.log(res);
          this.toastr.success(res['message'], 'Success');
          this.router.navigate(['/login']);
          this.disable = true;
          this.loading = true;
        },
        (error) => {
          this.disable = false;
          this.loading = false;
          this.toastr.error(error, 'Failed');
        }
      );
    }
  }

  getErrorMessage() {
    if (this.resetPasswordForm.get('confirmPassword').hasError('required')) {
      return 'Confirm Password is required';
    }
    return this.resetPasswordForm.get('confirmPassword').errors
      ?.confirmPasswordValidator
      ? "Password and Confirm Password didn't match"
      : ' ';
  }
}
