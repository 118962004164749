<div class="con-flu">

  <div class="bg-whole">
    <div class="image-bg">
      <img style="width: 100%; height: -webkit-fill-available;" src="/assets/img/axa.jpg">
    </div>

    <div class="rec2">

      <div class="card-bg">
        <p class="log-title">Secure Member Reset Password </p>

        <div class="flex-cen">

          <form [formGroup]="resetPasswordForm" class="form-horizontal">

            <div class="Reset_container">
              <mat-form-field appearance="outline">
                <mat-label>New Password</mat-label>
                <mat-icon class="white-icon" matPrefix>lock</mat-icon>
                <input matInput [type]="hidePassword ? 'password' : 'text'" type="password"
                  formControlName="newPassword" name="newPassword" [errorStateMatcher]="matcher" required>
                <mat-error *ngIf="resetPasswordForm.get('newPassword').errors">New Password is required</mat-error>
                <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword"
                  [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
                  <mat-icon class="white-icon">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
              </mat-form-field>
            </div>

            <div class="Reset_container">
              <mat-form-field appearance="outline">
                <mat-label>Confirm Password</mat-label>
                <mat-icon class="white-icon" matPrefix>lock</mat-icon>
                <input matInput [type]="hideConfirmPassword ? 'password' : 'text'" type="password"
                  formControlName="confirmPassword" name="confirmPassword" [errorStateMatcher]="matcher" required>
                <mat-error *ngIf="resetPasswordForm.get('confirmPassword').invalid">{{getErrorMessage()}}</mat-error>
                <button mat-icon-button matSuffix (click)="hideConfirmPassword = !hideConfirmPassword"
                  [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideConfirmPassword">
                  <mat-icon class="white-icon">{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
              </mat-form-field>
            </div>

            <div class="Reset_container">
              <button type="submit" [disabled]="resetPasswordForm.invalid" [ngClass]="{'btn_resetInvalid': resetPasswordForm.invalid}" class="btn__Reset" (click)="resetPassword()">
                Reset &rarr;  <mat-spinner *ngIf="loading" class="custom-spinner" diameter="20"></mat-spinner>
              </button>
            </div>
            <div class="Reset_container login" style="text-align: center;">
              <br>
              <span class="logintext"><a routerLink="/login"> Login </a></span>
            </div>
          </form>

        </div>
      </div>
    </div>
  </div>

</div>
