<div class='user-container'>
  <!-- <div class="user-container_header">
    <mat-card-title class="user-container_header_title">Create User</mat-card-title>
    <mat-icon class="user-container_header_icon" (click)="closeDialog()">clear</mat-icon>
  </div> -->
    <form [formGroup]="CreateUser">

      <div class="user-container_content">
        <mat-form-field appearance='outline'>
          <mat-label>Fullname</mat-label>
          <input matInput name="fullname" formControlName="fullname" required [errorStateMatcher]="matcher" >
          <mat-error *ngIf="CreateUser.get('fullname').hasError('required')">
            Enter staff fullname
          </mat-error>
          <mat-error *ngIf="CreateUser.get('fullname').hasError('pattern')">
            Only letters and numbers are allowed
          </mat-error>
        </mat-form-field>
      </div>

      <div class="user-container_content">
        <mat-form-field appearance='outline'>
          <mat-label>Username</mat-label>
          <input matInput name="username" formControlName="username" required [errorStateMatcher]="matcher"
            [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
            <mat-error *ngIf="CreateUser.get('username').hasError('required')">Enter staff username</mat-error>
            <mat-error *ngIf="CreateUser.get('username').hasError('pattern')">
        Only letters and numbers are allowed
      </mat-error>
        </mat-form-field>

      </div>

      <div class="user-container_content">
        <mat-form-field appearance='outline'>
          <mat-label>Email</mat-label>
          <input matInput type="text" name="email" formControlName="email" required [errorStateMatcher]="matcher">
          <mat-error *ngIf="CreateUser.get('email').hasError('pattern')">
            Invalid email format
          </mat-error>
          <mat-error *ngIf="CreateUser.get('email').hasError('required')">
            Enter email
          </mat-error>
        </mat-form-field>
      </div>

      <div class="user-container_content">
        <!--password input filed-->
        <mat-form-field appearance="outline" style="width: 70%">
          <mat-label>Password</mat-label>
          <mat-pass-toggle-visibility #toggle matSuffix></mat-pass-toggle-visibility>
          <input matInput #passwordWithValidation [type]="toggle.type" required formControlName="password"
            placeholder="Password" maxlength="15" [value]="newPassword">
          <!--password hint-->
          <mat-hint align="end" aria-live="polite">
            {{passwordWithValidation.value.length}} / 15
          </mat-hint>

          <!--password Mat-error-->
          <mat-error *ngIf="CreateUser.controls['password'].hasError('required')">Password is required</mat-error>
          <mat-error *ngIf="CreateUser.controls['password'].hasError('invalidPassword')"> Password is not valid
          </mat-error>
        </mat-form-field>

        <span>
          <button mat-button class="generateBtn" type="button" (click)="generatePassword()">{{buttonLabel}}</button>
        </span>

        <!--password-strength's main component-->
        <mat-password-strength #passwordComponentWithValidation [password]="passwordWithValidation.value" class="green"
          [min]="8" [max]="15" [enableLowerCaseLetterRule]="false">
        </mat-password-strength>

        <!--Password's strength info-->
        <mat-password-strength-info [passwordComponent]="passwordComponentWithValidation"></mat-password-strength-info>
      </div>
      <br>

      <div class='buttons'>
        <button class="buttons_cancelButton" mat-raised-button color="warn" type="submit" (click)="closeDialog()">
          Cancel
        </button>

        <button class="buttons_createButton" mat-raised-button color="primary" type="submit" (click)="onCreateUser()"
        [disabled]='CreateUser.invalid'>
          <mat-spinner *ngIf="loading" class="custom-spinner" diameter="20"></mat-spinner>
          Create User
        </button>
      </div>


    </form>
</div>
