import { Component, OnInit} from '@angular/core';
import {
  FormGroup,
  Validators,
  FormBuilder
} from "@angular/forms";
import {MatDialog } from "@angular/material/dialog";
import { ToastrService } from 'ngx-toastr';

import { AddressStructureService } from "src/app/shared/services/projectService/address-structure.service";
import { CreateFieldComponent } from '../address-structure/create-field/create-field.component';
import { CreateOptionComponent } from '../address-structure/create-option/create-option.component';

@Component({
  selector: 'app-address-structure',
  templateUrl: './address-structure.component.html',
  styleUrls: ['./address-structure.component.scss']
})
export class AddressStructureComponent implements OnInit {
  typesArray = ["text", "number", "image", "date",
  "select", "check", "radio"
];
  addressStructureForm = this.fb.group({
    extraFields: this.fb.array([
      this.fb.group({
              fieldName: this.fb.control("", Validators.required),
              fieldType: this.fb.control("text", Validators.required),
              required: this.fb.control(true),
              options: this.fb.array([])
            })
    ])
  });

  extraFieldDiv = false;
  dynamicForm : FormGroup;
  httpLoading = false;
  dataLoading = true;

  constructor(
    private fb: FormBuilder,
    private addresStrucuteService: AddressStructureService,
    private toastr: ToastrService,
    private dialog: MatDialog
  ) {}
extraOptions: FormGroup;
  ngOnInit(): void {

    this.fetchDynamicaField();
  }


  fetchDynamicaField() {
    this.addresStrucuteService.getDynamicStructure().subscribe(
          (response: any) => {
            // console.log(response.extraFields, "adressstructure from api");
            const result = response.extraFields;
            this.dynamicForm =result;
            this.extraFieldDiv = false;
          },
          error => {
            this.toastr.error(error, "Error");
          }
        );
  }

  removeField(id) {
    // console.log(id);
    this.addresStrucuteService.deleteSelect(id).subscribe(
      (res)=> {
        // console.log(res);

        if (res["status"] === 0 ) {
          this.fetchDynamicaField();
          this.toastr.success(res["message"]);
        } else{
          this.toastr.error(res["message"], 'Error');
        }
      },
      error => {
        this.toastr.error(error);
      }
    )
  }

  removeOption(id) {
    // console.log('id', id);
    this.addresStrucuteService.deleteOption(id).subscribe(
      (res)=> {
        // console.log(res);
        this.fetchDynamicaField();
        if (res["status"] === 0 ) {
          this.toastr.success(res["message"]);
        } else{
          this.toastr.error(res["message"], 'Error');
        }
      },
      error => {
        this.toastr.error(error, 'Error');
      }
    )
  }

  openFieldModal() {
    const dialog = this.dialog.open(CreateFieldComponent, {
      minWidth: "20vw",
      maxHeight: "100vh",
      minHeight: "40vh"
    });
    dialog.afterClosed().subscribe(value=>{
      // console.log(value);
      this.fetchDynamicaField();

    })
  }

  openOptionModal(id) {
    // console.log(id);
   const dialog = this.dialog.open(CreateOptionComponent, {
      minWidth: "18vw",
      maxHeight: "100vh",
      minHeight: "25vh",
      data: {id: id}
    });
    dialog.afterClosed().subscribe(value=>{
      // console.log(value);
      this.fetchDynamicaField();
    })
  }

}
