<div class="container">
  <form (ngSubmit)="assignAddress()" #myForm="ngForm">
    <div class="label">
      <h5>Assign Address to:</h5>
    </div>

    <div class="form-group">
      <mat-form-field class="example-full-width">
        <mat-label>
          Search and Select Office Staff........ <mat-icon>search</mat-icon>
        </mat-label>
        <input type="text" placeholder="" aria-label="field Worker" matInput
          [formControl]="myControl" [matAutocomplete]="auto" />
        <mat-autocomplete #auto="matAutocomplete"  [displayWith]="displayName">
          <mat-option *ngFor="let worker of fOptions" [value]="worker">
            {{ worker.fullname }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <mat-grid-list cols="2" rowHeight="37px">
      <mat-grid-tile>
        <button type="button" mat-flat-button class="cancelBtn" color="warn" (click)="cancel()">Cancel</button>
      </mat-grid-tile>
      <mat-grid-tile>
        <button [disabled]="myControl.invalid" mat-flat-button class="saveBtn">Assign
          <mat-spinner *ngIf="httpLoading" class="custom-spinner" diameter="20"></mat-spinner>
        </button>
      </mat-grid-tile>
    </mat-grid-list>

    <!-- <button type="submit" id="submitBtn" class="btn btn-block" [disabled]="disable">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="httpLoading"></span>
      Confirm
    </button>
    <button type="button" class="btn btn-danger btn-block mt-2" style="border-radius: 30px;" (click)="cancel()"> Cancel</button> -->
  </form>

</div>
