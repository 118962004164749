<div class="con-flu">
  <div class="bg-whole">
    <div class="image-bg">
      <img style="width: 100%; height: 100vh" src="/assets/img/axa.jpg" />
    </div>

    <div class="rec2">
      <div class="card-bg">
        <p class="log-title">Secure Member Log-in</p>

        <div class="flex-cen">


          <form
            class="form-horizontal"
            [formGroup]="loginForm"
            (ngSubmit)="onSubmit()"
            autocomplete="off"
          >
            <!-- <h1 class="login_heading">LOGIN</h1> -->
            <div class="login_container">
              <mat-form-field appearance="outline">
                <mat-label>Username</mat-label>
                <mat-icon class="white-icon" matPrefix>perm_identity</mat-icon>
                <input
                  type="text"
                  matInput
                  placeholder="Enter your username or email"
                  name="email"
                  formControlName="username"
                  required
                  autocomplete="false"
                />
              </mat-form-field>
            </div>

            <div class="login_container">
              <mat-form-field
                style="padding-top: 1.7rem !important"
                appearance="outline"
              >
                <mat-label>Password</mat-label>
                <mat-icon class="white-icon" matPrefix>lock</mat-icon>
                <input
                  matInput
                  [type]="hide ? 'password' : 'text'"
                  type="password"
                  name="password"
                  formControlName="password"
                  required
                  autocomplete="new-password"
                />

                <button
                  type="button"
                  mat-icon-button
                  matSuffix
                  (click)="hide = !hide"
                  [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hide"
                >
                  <mat-icon class="white-icon">{{
                    hide ? "visibility_off" : "visibility"
                  }}</mat-icon>
                </button>
              </mat-form-field>
            </div>
            <br />
            <div class="fgttext login_container">
              <span class="forgottext">
                <a routerLink="/forget-password">Forgot your password?</a>
              </span>
            </div>
            <div class="login_container">
              <button type="submit" class="btn__login" [disabled]="loading">
                Login &rarr;
                <mat-spinner
                  *ngIf="loading"
                  class="custom-spinner"
                  diameter="20"
                ></mat-spinner>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
