import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProjectService } from '@app/shared/services/projectService/project.service';

@Component({
  selector: 'app-fieldworker-progress',
  templateUrl: './fieldworker-progress.component.html',
  styleUrls: ['./fieldworker-progress.component.scss']
})
export class FieldworkerProgressComponent implements OnInit {

  cardLoader = false;
  pending: number;
  total: number;
  verified: number;
  username = this.data.name;


  constructor(
    private pService: ProjectService,
    @Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<FieldworkerProgressComponent>,
  ) { }

  // tslint:disable-next-line: typedef
  ngOnInit(){
    this.cardLoader = true;
    this.pService
      .getFieldWorkerReport(this.data.id)
      .subscribe(
        (res: any) => {
          // console.log(res);
          this.cardLoader = false;
          if (res.status === 0) {
            const value = res.addressCount;
            this.pending = value.pending;
            this.verified = value.verified;
            this.total = this.pending + this.verified;
          }
        },
        (error) => {
          this.cardLoader = false;
          // console.log(error);
        }
      );
  }

}
