<div class="container">
  <div class="label">
    <h3><b>CHANGE PASSWORD</b></h3>
  </div>

  <div class="form-group">
    <form (ngSubmit)="passwordChange()" class="" [formGroup]="changePasswordForm" #f="ngForm">

      <!--old password input filed-->
      <mat-form-field appearance="outline">
        <mat-label>Old Password</mat-label>
        <input matInput [type]="hideOldPassword ? 'password' : 'text'" type="password" formControlName="oldPassword"
          name="oldPassword" required [errorStateMatcher]="matcher" />
        <button type="button" mat-icon-button matSuffix (click)="hideOldPassword = !hideOldPassword"
          [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideOldPassword">
          <mat-icon>{{
            hideOldPassword ? "visibility_off" : "visibility"
            }}</mat-icon>
        </button>
        <mat-error *ngIf="changePasswordForm.controls['oldPassword'].hasError('required')">Old password is required
        </mat-error>
      </mat-form-field>

      <!--password input filed-->
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>New Password</mat-label>
        <mat-pass-toggle-visibility #toggle matSuffix></mat-pass-toggle-visibility>
        <input matInput #passwordWithValidation [type]="toggle.type" required formControlName="password"
          placeholder="Password" maxlength="15" [errorStateMatcher]="matcher">

        <!--password hint-->
        <mat-hint align="end" aria-live="polite">
          {{passwordWithValidation.value.length}} / 15
        </mat-hint>

        <!--password Mat-error-->
        <mat-error *ngIf="changePasswordForm.controls['password'].hasError('required')">New Password is required</mat-error>
        <mat-error *ngIf="changePasswordForm.controls['password'].hasError('invalidPassword')">New Password is not valid
        </mat-error>

      </mat-form-field>

      <!--password-strength's main component-->
      <mat-password-strength #passwordComponentWithValidation [password]="passwordWithValidation.value" class="green"
        [min]="8" [max]="15" [enableLowerCaseLetterRule]="false">
      </mat-password-strength>

      <!--Password's strength info-->
      <mat-password-strength-info [passwordComponent]="passwordComponentWithValidation"></mat-password-strength-info>

      <mat-form-field appearance="outline" style="margin-top: 3px;">
        <mat-label>Confirm Password</mat-label>
        <input matInput [type]="hideConfirmPassword ? 'password' : 'text'" type="password"
          formControlName="confirmPassword" name="confirmPassword" [errorStateMatcher]="matcher" required />

        <button type="button" mat-icon-button matSuffix (click)="hideConfirmPassword = !hideConfirmPassword"
          [attr.aria-label]="'Hide confirm password'" [attr.aria-pressed]="hideConfirmPassword">
          <mat-icon>{{
            hideConfirmPassword ? "visibility_off" : "visibility"
            }}</mat-icon>
        </button>
        <mat-error *ngIf="changePasswordForm.get('confirmPassword').invalid">{{getErrorMessage()}}</mat-error>
      </mat-form-field>

      <!--Button-->
      <br>
      <mat-grid-list cols="2" rowHeight="37px">
        <mat-grid-tile>
          <button type="button" mat-flat-button class="cancelBtn" color="warn" (click)="closeModal()">
            Cancel
          </button>
        </mat-grid-tile>
        <mat-grid-tile>
          <button [disabled]="changePasswordForm.invalid" mat-flat-button class="saveBtn">
            Save
            <mat-spinner *ngIf="isLoading" class="custom-spinner" diameter="20"></mat-spinner>
          </button>
        </mat-grid-tile>
      </mat-grid-list>
    </form>
  </div>
</div>
