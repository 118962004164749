import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { startWith, switchMap, throttleTime } from 'rxjs/operators';
import { MapAddressesService } from 'src/app/shared/services/map/map-addresses.service';

@Component({
  selector: 'app-address-search',
  templateUrl: './address-search.component.html',
  styleUrls: ['./address-search.component.scss'],
})
export class AddressSearchComponent implements OnInit {
  searchInput: FormControl = new FormControl('');
  filteredAddresses = this.mapAddressService.getAddressList();

  constructor(private mapAddressService: MapAddressesService) {}

  ngOnInit(): void {
    this.searchInput.valueChanges
      .pipe(
        switchMap((value) => {
          return typeof value === 'string'? this.mapAddressService.searchAddressesFetch('', '', value) : value === null? of([]) : of([value]);
        })
      )
      .subscribe((data) => {
        // console.log(data);
        this.mapAddressService.updateIsPolygonDrawn(false);
        this.mapAddressService.updateAddressObservable(data);
      });
  }

  displayFn(value): string {
    return value && typeof value === 'object'
      ? value.housenumber + ', ' + value.streetname + ' ' + value.areaname
      : '';
  }

  clearAddresses(): void {
    this.searchInput.setValue(null);
    this.mapAddressService.updateRiskBoundary(null);
    this.mapAddressService.updateRiskDetails(null);
    this.mapAddressService.updateCurrentAddress(null);
    this.mapAddressService.updateRiskExpansionPanelOpened(null);
    this.mapAddressService.updateBufferRingsObservable(null);
  }
}
